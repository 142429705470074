import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import type { Products as ProductTypes } from '~/models/products';
import { apiClient } from '~/services/api-client';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { queryKeys } from '../../query-keys';
import useUser from '~/libs/use-user';

const useVariantBatch = (variantIds: string[] = [], config?: UseQueryOptions<ProductTypes.IVariantBatchResult[], Error>) => {
    const { locale } = useRouter();
    const { activeProfile } = useActiveUser();
    const { products, createUrl } = useEndpoints();
    const { user } = useUser();

    const url = createUrl({
        endpoint: products.getVariantBatch,
        localeOption: LocaleOptions.path,
    });

    return useQuery({
        queryKey: queryKeys.products.variantBatch(locale, variantIds, activeProfile?.id),
        queryFn: ({ signal }) =>
            apiClient.auth(user).postJSON(
                url,
                variantIds.map((ftzCode) => ({ ftzCode })),
                {
                    signal,
                },
            ),
        ...config,
    });
};

export default useVariantBatch;
