import clsx from 'clsx';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { IWithClassName } from '~/models/dev';
import styles from './vehicles-fits.module.scss';
import Loader from '~/shared/loader/loader.component';
import useTranslations from '../hooks/use-translations.hook';
import ErrorBox from '../error-box/error-box';
import { Svg } from '~/shared/svg';
import { Modal } from '~/features/primitives/modal';
import VehiclesList from './vehicles-list/vehicles-list.component';
import Text from '~/shared/text/text.component';
import { Vehicles as VehicleTypes } from '~/models/vehicles';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import { useVehiclesFits } from '~/libs/queries/vehicles/hooks/use-vehicles-fits';

interface IVehiclesFitsProps extends IWithClassName {
    variantsIDs: string[];
    addLoader?: boolean;
    listClass?: string;
}

const VehiclesFits: FC<IVehiclesFitsProps> = ({ className, variantsIDs = [], listClass }) => {
    const [selectedManufacturer, setManufacturer] = useState<VehicleTypes.ICarManufacturers | null>(null);
    const translate = useTranslations();
    const { activeProfile } = useActiveUser();

    const { data, isLoading, isError } = useVehiclesFits(variantsIDs);

    const modalMetaHeader = useMemo(() => {
        return <Text>{`${selectedManufacturer?.carModelTypeDetails?.length ?? 0} ${translate('vehicle.models', 'modeler')}`}</Text>;
    }, [selectedManufacturer?.carModelTypeDetails?.length, translate]);

    const handleOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setManufacturer(null);
    }, []);

    if (isLoading) return <Loader padding="40px" />;

    if (isError) {
        return <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>;
    }

    return (
        <div className={clsx(className)}>
            {!data?.carManufacturers?.length ? (
                <div>{translate('product.doesNotFitOtherCars', 'Passer ikke til andre biler')}</div>
            ) : (
                <div className={clsx(styles.manufacturersList, listClass)}>
                    <Modal
                        position="right"
                        open={!!selectedManufacturer}
                        onOpenChange={handleOpenChange}
                        title={selectedManufacturer?.carManufacturerName}
                        meta={modalMetaHeader}
                    >
                        <VehiclesList
                            manufacturer={selectedManufacturer as VehicleTypes.ICarManufacturers}
                            variantsIDs={variantsIDs}
                            productCategory={data?.categoryName as string}
                        />
                    </Modal>
                    {data?.carManufacturers?.map((car) => (
                        <button type="button" className={styles.manufacturer} key={car?.carManufacturerId} onClick={() => setManufacturer(car)}>
                            <span className={styles.manufacturerName}>
                                {car?.carManufacturerName}

                                {!!activeProfile?.employeeId && (
                                    <div className={styles.manufacturerDetails}>
                                        {translate('vehicle.carsInDenmark', 'Biler i DK.')}{' '}
                                        {car?.carModelTypeDetails?.reduce((a, b) => a + (b.carsInDenmark ?? 0), 0)}
                                    </div>
                                )}
                            </span>
                            <span className={styles.vehiclesCount}>
                                {car?.carModelTypeDetails?.length} {translate('vehicle.models', 'modeller')}
                            </span>
                            <Svg className={styles.icon} thick name="chevron-right" />
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default VehiclesFits;
