import { useLayoutState } from '~/context/layout.context';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useRevalidation from '~/shared/hooks/use-revalidation.hook';
import useUser from '~/libs/use-user';
import { Products as ProductType, Products as ProductTypes } from '~/models/products';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import { apiClient } from '~/services/api-client';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { useRouter } from 'next/router';

export interface IPricesList {
    [key: string]: ProductTypes.IVariantPrices;
}
export const getVariantPriceRequest = (variants: ProductType.IVariantLight[] = []) =>
    variants.map(({ colli: quantity = 1, ftzCode }) => {
        return { articleId: ftzCode, quantity };
    });

export function useProductPrices(variants?: ProductTypes.IArticle[], shouldFetch = true) {
    const { expertMode } = useLayoutState();
    const { activeProfile } = useActiveUser();
    const { locale } = useRouter();
    const { products, createUrl } = useEndpoints();
    const url = createUrl({
        endpoint: products.getVariantPrices,
        localeOption: LocaleOptions.path,
        query: {
            includeExpertProducts: !!expertMode,
        },
    });

    const { isLoggedIn, user } = useUser();
    const { fetching } = useRevalidation(isLoggedIn && shouldFetch);

    return useQuery<IPricesList, Error>({
        queryKey: queryKeys.products.productPrices(locale, !!expertMode, variants),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).post(url, {
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(variants),
                signal,
            });
        },
        refetchOnWindowFocus: true,
        refetchInterval: 1000 * 60 * 5,
        enabled: shouldFetch && fetching && activeProfile && Boolean(variants),
    });
}
