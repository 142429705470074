import { useCallback } from 'react';
import clsx from 'clsx';
import { useStaticContent } from '~/libs/queries/bff';
import { useCMSPage } from '~/libs/queries/bff';
import { Bff } from '~/models/bff.d';
import Button from '~/shared/buttons/button/button.component';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Link } from '~/shared/link';
import PageTitle from '~/shared/page-title/page-title.component';
import styles from './styled.module.scss';
import { setCreateInvoice, useInvoiceLayoutDispatch } from '~/widgets/overview/invoices-widget/invoices.context';
import { useRouter } from 'next/router';

interface IProps {
    links: Bff.IWidgetLink[] | undefined;
    hideOnMobile?: boolean;
    addCreditButton?: boolean;
}

export default function SecondaryNavigation({ links, hideOnMobile, addCreditButton = false }: IProps) {
    const translate = useTranslations();
    const dispatch = useInvoiceLayoutDispatch();
    const router = useRouter();
    const { data: pageContent } = useCMSPage();
    const { data: staticContent } = useStaticContent();

    const addCredit = useCallback(async () => {
        if (staticContent?.urlMappings?.invoices.indexOf(router.asPath) === -1) {
            await router.push(staticContent.urlMappings.invoices);
        }

        dispatch(setCreateInvoice(true));
    }, [dispatch, router, staticContent?.urlMappings?.invoices]);

    return (
        <PageTitle
            className={clsx(styles.navigationWrapper, {
                [styles.hideOnMobile]: hideOnMobile,
            })}
        >
            <Heading tagName="h2" displayStyle="h3" className={styles.title}>
                {pageContent?.meta?.title}
            </Heading>
            {links?.length ? (
                <nav
                    className={clsx(styles.navigation, {
                        [styles.addCreditNavWrapper]: addCreditButton,
                    })}
                >
                    <ul className={styles.list}>
                        {links.map((link) => {
                            const selected = link.url && pageContent?.url?.includes(link.url);

                            return (
                                <li className={styles.item} key={link.url}>
                                    <Link
                                        href={selected ? undefined : link.url}
                                        className={clsx(styles.link, {
                                            [styles.selected]: selected,
                                        })}
                                    >
                                        {link.title}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                    {addCreditButton ? (
                        <Button onClick={addCredit} buttonStyle="secondary">
                            {translate('overview.establishCredit', 'Opret Kreditering')}
                        </Button>
                    ) : null}
                </nav>
            ) : null}
        </PageTitle>
    );
}
