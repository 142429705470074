import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';
import styles from './CustomerInfo.module.scss';

export default function CustomerInfo() {
    const translate = useTranslations();
    const { activeProfile } = useActiveUser();
    return (
        <div className={styles.container}>
            <Heading tagName="h4">{translate('userAdministration.userAdministration', 'Brugeradministration')}</Heading>
            <table role="presentation">
                <tbody>
                    <tr>
                        <td>{translate('common.customerId', 'Kundenr.')}</td>
                        <td>{activeProfile?.customer?.customerId}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>{translate('common.name', 'Navn')}</td>
                        <td>{activeProfile?.customer?.name}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>{translate('common.userName', 'Brugernavn')}</td>
                        <td>{activeProfile?.userName}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>{translate('common.email', 'E-mail')}</td>
                        <td>{activeProfile?.email}</td>
                        <td>
                            <ChangeEmail />
                        </td>
                    </tr>
                    <tr>
                        <td>{translate('common.password', 'Adgangskode')}</td>
                        <td>********</td>
                        <td>
                            <ChangePassword />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
