import React from 'react';
import LicensePlate from '~/shared/license-plate/license-plate.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Text from '~/shared/text/text.component';
import { Link } from '~/shared/link';
import { Bff } from '~/models/bff';

import styles from './order-history.module.scss';

export type OrderHistoryRecordProps = {
    car?: Bff.ICarInfo;
    date?: string;
    invoiceUrl?: string;
    invoiceId?: number;
    quantity: number;
    children?: React.ReactNode | React.ReactNode[];
};

export function OrderHistoryRecord({ car, date, quantity, invoiceId, invoiceUrl, children }: OrderHistoryRecordProps) {
    const translate = useTranslations();

    return (
        <div className={styles.orderHistoryRecord}>
            <div className={styles.orderHistoryRecordCaption}>{translate('common.date', 'dato')}</div>
            <div className={[styles.orderHistoryRecordAttribute, styles.date].join(' ')}>
                <Text textStyle="monoMedium">{date}</Text>
            </div>

            <div className={styles.orderHistoryRecordCaption}>{translate('product.quantity', 'antal')}</div>
            <div className={[styles.orderHistoryRecordAttribute, styles.quantity].join(' ')}>
                <Text textStyle="monoMedium">{quantity}</Text>
            </div>

            <div className={styles.orderHistoryRecordCaption}>{translate('overview.invoiceNumber', 'Faktura nr.')}</div>
            <div className={[styles.orderHistoryRecordAttribute, styles.invoice].join(' ')}>
                <Link href={invoiceUrl}>
                    <Text textStyle="monoMedium">{invoiceId}</Text>
                </Link>
            </div>

            <Link href={car?.url}>
                <LicensePlate licensePlate={car?.licensePlate} />
            </Link>

            {children ? <div className={styles.orderHistoryRecordAction}>{children}</div> : null}
        </div>
    );
}
