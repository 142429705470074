import { Users } from '~/models/users';
import { apiClient } from '~/services/api-client';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { DefaultFetchOptions } from '../types';

type Args = {
    body: Users.IChangePasswordRequest;
} & DefaultFetchOptions;

export default function changePassword({ locale, user, signal, body }: Args): Promise<void> {
    const url = createUrl(
        {
            endpoint: endpoints.users.changePassword,
            localeOption: LocaleOptions.path,
        },
        locale,
    );
    return apiClient.auth(user).putJSON(
        url,
        {
            ...body,
        },
        {
            signal,
        },
    );
}
