import styles from '~/shared/create-credit/create-credit.module.scss';
import Heading from '~/shared/heading/heading.component';
import PageTitle from '~/shared/page-title/page-title.component';
import React, { useState } from 'react';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import InvoiceDetails from '~/shared/create-credit/invoice-details/invoice-details.component';
import { DEPOSIT_ONLY_LOCATION } from '~/shared/create-credit/item/item.component';
import Label from '~/shared/form-elements/common/label/label.component';
import Input from '~/shared/form-elements/input/input.component';
import { MAIL_FORMAT } from '~/constants/validation';
import TextArea from '~/shared/form-elements/text-area/text-area.component';
import Checkbox from '~/shared/form-elements/checkbox/checkbox.component';
import useUser from '~/libs/use-user';
import Button from '~/shared/buttons/button/button.component';
import { GA4CreateCreditFromPdp } from '~/libs/ga4';
import { generateUniqueId, getUpdatedEntries } from '~/widgets/overview/invoices-widget/invoice-draft/utils';
import { useCreditDraftStorage } from '~/widgets/overview/invoices-widget/invoice-draft/hooks';
import { useCreateOECredit, usePreviousOrdersByIdentifier } from '~/libs/queries/orders';
import { CreditModalFormFields } from '~/page-elements/credit-modal/types';

import { Bff } from '~/models/bff';
import { CreditEntry } from '~/shared/create-credit/credit-entry';
import { OrderHistoryRecord } from '~/page-elements/order-history/order-history';
import Radio from '~/shared/form-elements/radio/radio.component';
import { useCreditModalState } from '~/page-elements/credit-modal/hooks';
import { addErrorToast, addSuccessToast, useLayoutDispatch } from '~/context/layout.context';

export type CreditModalProps = {
    variantId: string;
};

export function CreditModal({ variantId }: CreditModalProps) {
    const { data: previousOrders } = usePreviousOrdersByIdentifier(variantId);
    const { invoicesList = {}, variantLight } = previousOrders ?? {};
    const { invoices = [] } = invoicesList;

    if (invoices.length > 1) {
        return <CreditModalMultiple invoices={invoices} variant={variantLight} />;
    }

    return <CreditModalForm invoice={invoices[0]} variant={variantLight} details />;
}

export type CreditModalFormProps = {
    invoice: Bff.IVariantInvoiceDetails;
    variant?: Bff.IVariantLight;
    details?: boolean;
};

export function CreditModalForm({ invoice, variant, details }: CreditModalFormProps) {
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();

    const { profile } = useUser();

    const [, setStoredForm] = useCreditDraftStorage();
    const [resetModalState] = useCreditModalState((state) => [state.reset]);

    const form = useForm<CreditModalFormFields>({
        mode: 'onBlur',
        defaultValues: {
            requisition: '',
            entries: [
                {
                    brand: variant?.brand,
                    colli: variant?.colli,
                    colliLocked: variant?.colliLocked,
                    creditLocation: invoice.creditLocation,
                    hasDeposit: invoice.hasDeposit,
                    isDepositProduct: invoice.hasDeposit,
                    itemId: String(variant?.ftzCode),
                    maxQuantity: invoice.possibleToCreditQuantity,
                    quantity: invoice.possibleToCreditQuantity > 1 ? 0 : 1,
                    depositOnly: false,
                    title: String(variant?.title),
                    unitPrice: invoice?.unitPrice,
                    url: String(variant?.url),
                    carInfo: invoice.carInfo,
                    quantityWarn: invoice.possibleToCreditQuantity > 1,
                },
            ],
            cc: profile?.email,
            oe: false,
        },
    });

    const {
        watch,
        formState: { errors },
    } = form;

    const entries = watch('entries');

    const { mutate: createOECredit, isLoading: isCreatingOECredit } = useCreateOECredit();

    const doSubmit = ({ requisition, cc, reason, entries: [submittedEntry] }: CreditModalFormFields) => {
        if (!invoice.invoiceId) {
            return;
        }

        if (errors.entries?.length) {
            // validation failed on items
            return;
        }

        const { itemId, quantity, depositOnly = false, hasDeposit = false, creditLocation } = submittedEntry ?? {};

        if (variant?.oeMatch) {
            createOECredit(
                {
                    invoiceId: invoice.invoiceId,
                    data: {
                        emailInCc: cc,
                        creditReasonDescription: reason,
                        requisition,
                        items: [
                            {
                                itemId,
                                quantity,
                            },
                        ],
                    },
                },
                {
                    onSuccess: ({ errorMessage }) => {
                        if (errorMessage) {
                            dispatch(addErrorToast(errorMessage.length > 0 ? errors : translate('common.somethingWentWrong', 'Der gik noget galt.')));
                            return;
                        }

                        dispatch(addSuccessToast(translate('overview.creditFeedback.successHeadline', 'Din kreditering blev oprettet')));
                        resetModalState();
                    },
                },
            );

            return;
        }

        const uniqueId = generateUniqueId(itemId, invoice.invoiceId, depositOnly ? 'deposit' : 'total', invoice.carInfo?.carId);

        const location = depositOnly ? DEPOSIT_ONLY_LOCATION : creditLocation ?? '-';

        const updatedStoredEntry = {
            itemNo: itemId,
            invoiceId: invoice.invoiceId,
            carId: invoice.carInfo?.carId,
            licensePlate: invoice.carInfo?.licensePlate?.number,
            location,
            uniqueId,
            quantity,
            hasDeposit,
            depositOnly,
        };

        setStoredForm((prev) => {
            return {
                ...prev,
                entries: getUpdatedEntries(prev.entries, updatedStoredEntry, invoice.possibleToCreditQuantity),
            };
        });

        GA4CreateCreditFromPdp([itemId]);
        resetModalState();
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(doSubmit)}>
                <section>
                    {details ? (
                        <>
                            <PageTitle className={styles.pageTitle}>
                                <Heading tagName="h4">{translate('overview.orderDetails', 'Odredetaljer')}</Heading>
                            </PageTitle>

                            <InvoiceDetails
                                showRequisition={variant?.oeMatch}
                                fetching={isCreatingOECredit}
                                itemsCount={invoice.possibleToCreditQuantity}
                                invoiceId={invoice.invoiceId}
                                invoiceDate={invoice.invoiceDate.toString() ?? 'N/A'} // TODO: remove N/A when types are fixed
                            />
                        </>
                    ) : null}

                    {entries.length > 0 ? (
                        <>
                            <PageTitle>
                                <Heading tagName="h4">{translate('overview.itemDetails', 'Varedetaljer')}</Heading>
                            </PageTitle>
                            {entries.map((entry) => (
                                <CreditEntry
                                    key={entry.itemId}
                                    entry={entry}
                                    isDuplicate={
                                        false // TODO: implement isDuplicate check
                                    }
                                />
                            ))}
                        </>
                    ) : null}
                </section>
                {variant?.oeMatch ? <CreditModalOEFormFields /> : null}
                <div className={styles.buttons}>
                    <Button fetching={isCreatingOECredit} disabled={entries.some((item) => item.quantityWarn)} type="submit" buttonStyle="secondary">
                        {translate('overview.addCredit', 'Tilføj til kreditering')}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
}

export type CreditModalInvoicePickerProps = {
    invoices: Bff.IVariantInvoiceDetails[];
    selectedInvoiceId: number;
    onInvoiceSelect: (invoice: Bff.IVariantInvoiceDetails) => void;
};

export function CreditModalInvoicePicker({ invoices, selectedInvoiceId, onInvoiceSelect }: CreditModalInvoicePickerProps) {
    return (
        <div>
            {invoices.map((invoice) => (
                <OrderHistoryRecord
                    key={invoice.invoiceId}
                    quantity={invoice.possibleToCreditQuantity}
                    invoiceId={invoice.invoiceId}
                    car={invoice.carInfo}
                    invoiceUrl={invoice.invoiceDetailsUrl}
                    date={invoice.invoiceDateFormatted}
                >
                    <Radio checked={invoice.invoiceId === selectedInvoiceId} onClick={() => onInvoiceSelect(invoice)} />
                </OrderHistoryRecord>
            ))}
        </div>
    );
}

export type CreditModalMultiple = {
    invoices: Bff.IVariantInvoiceDetails[];
    variant?: Bff.IVariantLight;
};

export function CreditModalMultiple({ invoices, variant }: CreditModalMultiple) {
    const translate = useTranslations();

    const [currentInvoice, setCurrentInvoice] = useState<Bff.IVariantInvoiceDetails>(invoices[0]);

    const handleInvoiceSelect = (invoice: Bff.IVariantInvoiceDetails) => {
        setCurrentInvoice(invoice);
    };

    return (
        <>
            <PageTitle>
                <Heading tagName="h4">{translate('overviewDraft.invoicePickerLabel', 'Vælg faktura')}</Heading>
            </PageTitle>
            <CreditModalInvoicePicker invoices={invoices} selectedInvoiceId={currentInvoice.invoiceId} onInvoiceSelect={handleInvoiceSelect} />
            {currentInvoice ? <CreditModalForm key={currentInvoice.invoiceId} invoice={currentInvoice} variant={variant} /> : null}
        </>
    );
}

export function CreditModalOEFormFields() {
    const translate = useTranslations();

    const {
        register,
        formState: { errors },
    } = useFormContext<CreditModalFormFields>();

    return (
        <section>
            <PageTitle>
                <Heading tagName="h4">{translate('createCredit.additionalInformation', 'Yderligere Information')}</Heading>
            </PageTitle>
            <Label required>{translate('createCredit.replySentTo', 'Svar sendes til')}</Label>
            <Input
                {...register('cc', {
                    required: translate('common.fieldMustBeFilled', 'Feltet skal udfyldes'),
                    pattern: {
                        value: MAIL_FORMAT,
                        message: translate('common.invalidEmail', 'Den indtastede email er ikke gyldig'),
                    },
                })}
                type="email"
                wrapperClassName={styles.input}
                errorMessage={errors?.cc && errors.cc.message}
            />

            <Label required>{translate('createCredit.addReasonForDesiredCredit', 'Tilføj årsag for ønsket kreditering')}</Label>
            <TextArea
                {...register('reason', {
                    required: translate('common.fieldMustBeFilled', 'Feltet skal udfyldes'),
                })}
                errorMessage={errors?.reason && errors.reason.message}
                wrapperClassName={styles.input}
            />

            <Checkbox
                {...register('oe', { required: translate('common.fieldMustBeFilled', 'Feltet skal udfyldes') })}
                errorMessage={errors.oe?.message}
            >
                {translate(
                    'createCredit.oeCreditCheckBox',
                    'Når der klikkes på ”Forespørg OE kreditering” afsendes forespørgslen nu til FTZ OE for tjek om det er muligt, at returnere. Kontroller at e-mail adresse er korrekt, det er denne som vil være afsender og modtage svaret på forespørgslen.',
                )}
            </Checkbox>
        </section>
    );
}
