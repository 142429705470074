import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import useUser, { useIsImpersonated } from '~/libs/use-user';
import changeEmail from '~/services/user/changeEmail';
import { usersKeys } from '../usersKeys';

export default function useChangeEmail() {
    const { locale } = useRouter();
    const { user } = useUser();
    const isImpersonated = useIsImpersonated();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (body: Parameters<typeof changeEmail>['0']['body']) =>
            changeEmail({
                user,
                locale,
                body,
            }),
        onSettled: () =>
            queryClient.invalidateQueries(
                usersKeys.getActiveUser(
                    {
                        user,
                        locale,
                    },
                    Boolean(isImpersonated),
                ),
            ),
    });
}
