import useTranslations from '~/shared/hooks/use-translations.hook';
import styles from './ProductAccessoryPlacement.module.scss';
import Text from '~/shared/text/text.component';
import clsx from 'clsx';

type Props = {
    placements: string[];
};

export default function ProductAccessoryPlacement({ placements }: Props) {
    const translate = useTranslations();
    return (
        <div className={styles.container}>
            <Text fontWeight="semiBold" tagName="span" className={styles.capitalize} textStyle="bodyExtraSmall">
                {translate('product.productAccessoryPlacement', 'Arbejdsområder')}
            </Text>
            <ul className={styles.list}>
                {placements.map((placement) => (
                    <Text
                        key={placement}
                        fontWeight="semiBold"
                        tagName="li"
                        className={clsx(styles.capitalize, styles.item)}
                        textStyle="bodyExtraSmall"
                    >
                        {placement}
                    </Text>
                ))}
            </ul>
        </div>
    );
}
