import React, { FC, InputHTMLAttributes, ChangeEvent, useState, useEffect } from 'react';
import { IFormElementCommonProps } from '../common/common';
import styles from './file.input.module.scss';
import ValidationErrorMessage from '../common/validation-error-message/validation-error-message.component';
import Text from '~/shared/text/text.component';
import { Svg } from '~/shared/svg';
import useTranslations from '~/shared/hooks/use-translations.hook';
import getFileExtension from '~/helpers/get-file-extension';

// https://github.com/mdn/learning-area/blob/master/html/forms/file-examples/file-example.html

interface IFileInputProps extends InputHTMLAttributes<HTMLInputElement>, IFormElementCommonProps {
    value?: string;
    errorMessage?: string | JSX.Element;
    maxFileSize?: number;
    maxFilesNumber?: number;
    onFilesChange?: (files: File[]) => void;
    files: File[];
    acceptedFileExtensions?: string[];
    fileTypes?: string[];
}

const checkForDuplicate = (files: File[], newFile: File) => files.find((file) => file.name === newFile?.name && file.size === newFile?.size);

const FileInput: FC<IFileInputProps> = (props) => {
    const {
        errorMessage,
        name = 'fileUpload',
        label,
        maxFileSize,
        maxFilesNumber,
        onFilesChange,
        fileTypes,
        acceptedFileExtensions = null,
        files,
        ...rest
    } = props;

    const translate = useTranslations();
    const [uploadError, setUploadError] = useState<string | null>(null);

    const handleDeleteFile = (fileToDelete: File) => {
        setUploadError(null);
        const newFiles = files.filter((file) => file.name !== fileToDelete.name && file.size !== fileToDelete.size);
        onFilesChange?.(newFiles);
    };

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        setUploadError(null);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const newFile = target.files[0];

        const fileExtension = getFileExtension(newFile.name);

        const extensionMatch = acceptedFileExtensions?.find((item) => item.toLowerCase() === fileExtension.toLowerCase());

        if ((acceptedFileExtensions?.length as number) > 0 && !extensionMatch) {
            setUploadError(translate('common.fileNotSupported', 'Fil-typen er desværre ikke understøttet, forsøg at gemme den i et andet format'));
            return;
        }

        if (checkForDuplicate(files, newFile)) {
            setUploadError(translate('common.duplicateFile', 'Filen er allerede uploadet'));
            return;
        }

        if (maxFileSize && newFile?.size > maxFileSize) {
            setUploadError(translate('common.fileIsTooLarge', 'Filen er desværre for stor'));
            return;
        }

        if (fileTypes && !fileTypes.includes(newFile?.type)) {
            setUploadError(translate('common.fileNotSupported', 'Fil-typen er desværre ikke understøttet, forsøg at gemme den i et andet format'));
            return;
        }

        const newFilesList = [...files, newFile];
        onFilesChange?.(newFilesList);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        target.value = null;
    };

    const maxSizeReached = maxFilesNumber && files?.length >= maxFilesNumber;

    return (
        <div className={styles.wrapper}>
            {files.length ? (
                <ul className={styles.filesList}>
                    {files
                        .filter((file) => file)
                        .map((file, i) => {
                            const index = `${file?.name}-${file?.size}-${i}`;
                            return (
                                <li key={index} className={styles.file}>
                                    <Text textStyle="bodySmall" fontWeight="semiBold" className={styles.fileName}>
                                        {file?.name}
                                    </Text>
                                    <button aria-label="Delete" className={styles.deleteBtn} type="button" onClick={() => handleDeleteFile(file)}>
                                        <Svg name="bin" className={styles.deleteIcon} />
                                    </button>
                                </li>
                            );
                        })}
                </ul>
            ) : null}
            {uploadError && <ValidationErrorMessage>{uploadError}</ValidationErrorMessage>}

            <label className={maxSizeReached ? styles.disabledLabel : styles.label} htmlFor={name}>
                <Text tagName="span" className={styles.labelText}>
                    {label || translate('common.selectFile', 'Vælg fil')}
                </Text>
                <Text tagName="span" className={styles.labelButton} fontWeight="semiBold">
                    <Svg name="folder" thick className={styles.icon} />
                    {translate('common.browse', 'Browse')}
                </Text>
            </label>
            <input disabled={!!maxSizeReached} onChange={handleChange} {...rest} type="file" className={styles.input} id={name} name={name} />
            {errorMessage && <ValidationErrorMessage>{errorMessage}</ValidationErrorMessage>}
        </div>
    );
};

export default FileInput;
