import Details from '~/shared/stock-status/details/details.component';
import React from 'react';
import { Modal } from '~/features/primitives/modal';

export type StockModalProps = {
    itemNo: string;
    header: React.ReactElement;
    open: boolean;
    onOpenChange: (open: boolean) => void;
};

export function StockModal({ itemNo, header, open, onOpenChange }: StockModalProps) {
    return (
        <Modal position="right" title={header} open={open} onOpenChange={onOpenChange}>
            <Details itemNo={itemNo} />
        </Modal>
    );
}
