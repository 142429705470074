import { useLayoutState } from '~/context/layout.context';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import { apiClient } from '~/services/api-client';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { Products as ProductTypes } from '~/models/products';

export function useStockStatusExternal(variantId: string) {
    const { expertMode } = useLayoutState();
    const { activeProfile } = useActiveUser();
    const { products, createUrl } = useEndpoints();
    const { isLoggedIn, user } = useUser();
    const { locale } = useRouter();

    const key = createUrl({
        endpoint: products.getStockStatusExternal,
        localeOption: LocaleOptions.path,
        query: {
            variantNumber: variantId,
            includeExpertProducts: !!expertMode,
        },
    });

    return useQuery<ProductTypes.IStockInfo[], Error>({
        queryKey: queryKeys.products.stockStatusExternal(locale, activeProfile?.id, variantId, !!expertMode),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(key, { signal });
        },
        enabled: isLoggedIn,
        refetchInterval: 1000 * 30,
        retryDelay: 1000 * 60 * 5,
    });
}
