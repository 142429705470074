import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { Users as UsersTypes } from '~/models/users';
import { usersKeys } from '~/libs/queries/users/usersKeys';
import { apiClient } from '~/services/api-client';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';

export function useDepartmentsByRegions() {
    const { isLoggedIn, user } = useUser();
    const { locale } = useRouter();
    const { activeProfile } = useActiveUser();

    const { users, createUrl } = useEndpoints();
    const endpoint = createUrl({
        endpoint: `${users.departments}/by-regions`,
        localeOption: LocaleOptions.omit,
    });

    return useQuery<UsersTypes.IDepartmentsByRegion[], Error>({
        queryKey: usersKeys.departmentsByRegion(locale, activeProfile?.id),
        queryFn: async () => {
            return apiClient.auth(user).get(endpoint);
        },
        enabled: isLoggedIn,
    });
}

export function useDepartmentsByRegionsName(departmentByRegion?: UsersTypes.IDepartmentsByRegion[]) {
    const { profile } = useUser();

    const userDepartmentId = profile?.delivery_department_id;

    return (
        departmentByRegion?.flatMap((district) => district.departments)?.find((department) => department?.id.toString() === userDepartmentId)
            ?.description || null
    );
}
