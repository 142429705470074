import { Modal } from '~/features/primitives/modal';
import Button from '~/shared/buttons/button/button.component';
import SearchInput from '~/shared/form-elements/search-Input/search-Input.component';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';
import { AddUser } from './AddUser';
import styles from './UserList.module.scss';
import useUserList from './useUserList';

export default function UsersList() {
    const translate = useTranslations();
    const {
        isLoading,
        isModalOpen,
        selectedUser,
        handleDelete,
        closeModal,
        openModal,
        query,
        data,
        handleOpenChange,
        deleting,
        editUser,
        setQuery,
        isCreatingUser,
        createOrUpdateUser,
    } = useUserList();

    if (isLoading) return <Loader padding="100px 20px" />;

    return (
        <>
            <Modal
                position="right"
                open={isModalOpen}
                title={
                    selectedUser
                        ? translate('userAdministration.userDetails', 'Bruger detaljer')
                        : translate('userAdministration.newUser', 'Ny bruger')
                }
                onOpenChange={handleOpenChange}
            >
                <AddUser selectedUser={selectedUser} closeModal={closeModal} createUpdateUser={createOrUpdateUser} isCreatingUser={isCreatingUser} />
            </Modal>
            <div className={styles.header}>
                <Heading tagName="h4">{translate('userAdministration.userAdministration', 'Brugeradministration')}</Heading>
                <div className={styles.controls}>
                    <SearchInput value={query} setInputValue={setQuery} />
                    <Button buttonStyle="secondary" onClick={openModal} icon={<Svg name="plus" thick />}>
                        {translate('userAdministration.newUser', 'Ny bruger')}
                    </Button>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>{translate('common.name', 'Navn')}</th>
                        <th>{translate('common.userName', 'Brugernavn')}</th>
                        <th>{translate('common.email', 'E-mail')}</th>
                        <th>{translate('common.role', 'Rolle')}</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((user) => (
                        <tr key={user.id}>
                            <td>{user.name}</td>
                            <td>{user.userName}</td>
                            <td>{user.email}</td>
                            <td>
                                <div className={styles.buttonsWrapper}>
                                    <Text fontWeight="semiBold">{user.role?.name}</Text>
                                    <div className={styles.buttons}>
                                        <Button
                                            fetching={user.id === deleting}
                                            buttonStyle="clean"
                                            icon={<Svg name="bin" />}
                                            onClick={() => handleDelete(user.id)}
                                        />
                                        <Button
                                            fetching={user.id === deleting}
                                            buttonStyle="clean"
                                            icon={<Svg name="edit" />}
                                            onClick={() => editUser(user)}
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}
