import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import { Users as UsersTypes } from '~/models/users.d';
import { apiClient } from '~/services/api-client';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { useRouter } from 'next/router';

export type UseSubUsersProps = {
    userId?: number;
    query?: string;
};

export function useSubUsers({ userId, query }: UseSubUsersProps) {
    const { isLoggedIn, user } = useUser();
    const { createUrl, users } = useEndpoints();
    const { locale } = useRouter();
    const usersListUrl = createUrl({
        endpoint: users.externalSubUsersList,
        localeOption: LocaleOptions.path,
        query: { query },
    });

    return useQuery<UsersTypes.IExternalSubUserInfo[], Error>({
        queryKey: queryKeys.users.subUsers(locale, userId, query),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(usersListUrl, { signal });
        },
        enabled: isLoggedIn,
        keepPreviousData: true,
    });
}
