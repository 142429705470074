import { useLayoutState } from '~/context/layout.context';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { Products as ProductTypes } from '~/models/products';
import { apiClient } from '~/services/api-client';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import useUser from '~/libs/use-user';

export interface IStockList {
    [key: string]: ProductTypes.IStockStatus;
}
export function useStockStatus(variantIds?: string[]) {
    const { selectedDepartment } = useLayoutState();
    const { user, isLoggedIn } = useUser();
    const { products, createUrl } = useEndpoints();
    const { locale } = useRouter();
    const queryKey = queryKeys.products.stockStatus(locale, selectedDepartment?.id, variantIds);

    const url = createUrl({
        endpoint: products.getStockStatus,
        localeOption: LocaleOptions.path,
        query: {
            departmentId: selectedDepartment?.id,
        },
    });

    return useQuery<IStockList>({
        queryKey: queryKey,
        queryFn: async ({ signal }) => {
            return await apiClient.auth(user).postJSON(
                url,
                { variantIds },
                {
                    signal,
                },
            );
        },
        enabled: isLoggedIn && !!variantIds?.length,
    });
}
