import React from 'react';

import { CreditModalItem } from '~/page-elements/credit-modal/types';
import { Link } from '~/shared/link';

import useTranslations from '~/shared/hooks/use-translations.hook';
import useUser from '~/libs/use-user';
import Text from '~/shared/text/text.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import ValidationErrorMessage from '~/shared/form-elements/common/validation-error-message/validation-error-message.component';

import {
    CreditEntryDepositTypePicker,
    CreditEntryDuplicateWarning,
    CreditEntryLocation,
    CreditEntryPrice,
    CreditEntryQuantityPicker,
    CreditEntryTitle,
} from './components';

import styles from './credit-entry.module.scss';

export type CreditEntryProps = {
    entry: CreditModalItem;
    isDuplicate?: boolean;
};

export function CreditEntry({ entry, isDuplicate }: CreditEntryProps) {
    const translate = useTranslations();

    const { isInternalUser } = useUser();

    return (
        <div className={styles.creditEntry}>
            {isDuplicate ? <CreditEntryDuplicateWarning itemId={entry.itemId} /> : null}

            <div className={styles.creditEntryContent}>
                <CreditEntryTitle title={entry.title} brand={entry.brand} />
                <CreditEntryQuantityPicker quantity={entry.quantity} maxQuantity={entry.maxQuantity} colli={entry.colli} />

                <ValueWithCaption caption={translate('product.itemNo', 'Vare nr')}>
                    <Link href={entry.url}>
                        <Text textStyle="monoMedium">{entry.itemId}</Text>
                    </Link>
                </ValueWithCaption>

                {entry.creditLocation && isInternalUser ? <CreditEntryLocation location={entry.creditLocation} /> : <div />}

                <CreditEntryPrice
                    price={entry.unitPrice?.netPriceFormatted}
                    deposit={entry.unitPrice?.depositFormatted}
                    hasDeposit={entry.hasDeposit}
                    depositOnly={!!entry.depositOnly}
                />
            </div>

            <div className={styles.creditEntryFooter}>
                <div>{entry.hasDeposit && <CreditEntryDepositTypePicker depositOnly={!!entry.depositOnly} disabled={false} />}</div>
            </div>
        </div>
    );
}
