import clsx from 'clsx';
import Head from 'next/head';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CROSS_NUMBER_TAB, FITTINGS_TAB, SPECIFICATIONS_TAB } from '~/constants/tabs';
import { useLayoutState } from '~/context/layout.context';
import { Modal } from '~/features/primitives/modal';
import RelatedProductsList from '~/features/RelatedProductsList';
import { GA4ProductInfoTab, GA4ViewItem } from '~/libs/ga4';
import { useFeatureToggle, useFeatures } from '~/libs/queries/bff';
import { useProductVehicleInfo } from '~/libs/queries/products';
import useDepositProducts from '~/libs/queries/products/hooks/use-deposit-products-service';
import { useOEMatchesVariants } from '~/libs/queries/products/hooks/use-oe-matches-variants';
import { useProductDetails } from '~/libs/queries/products/hooks/use-product-details';
import { useProductPrices } from '~/libs/queries/products/hooks/use-product-prices';
import { getListName, useBreadcrumb } from '~/libs/queries/routing/hooks/use-breadcrumb';
import { usePreviousOrders } from '~/libs/queries/orders';
import { selectVehicleOilInfoByVariant, useVehicleOilInfo } from '~/libs/queries/vehicles';
import useUser from '~/libs/use-user';
import { Bff } from '~/models/bff';
import { Products as ProductTypes } from '~/models/products.d';
import ErrorReportButton from '~/page-elements/error-report/error-report-button.component';
import { Description } from '~/page-elements/product-detail-card/description/description.component';
import Images from '~/page-elements/product-detail-card/images/images.component';
import OtherVariants from '~/page-elements/product-detail-card/other-variants/other-variants.component';
import ProductCrossNumbers from '~/page-elements/product-detail-card/product-cross-numbers/product-cross-numbers.component';
import AddFavorite from '~/shared/add-favorite/add-favorite.component';
import { AddToBasketContainer } from '~/shared/add-to-basket/add-to-basket.container';
import CreateDepositCredit from '~/shared/create-credit/create-deposit-credit/create-deposit-credit.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import PreviousOrders from '~/shared/previous-orders/previous-orders.component';
import ProductDescription from '~/shared/product-description/product-description.component';
import ProductDocuments from '~/shared/product-documents/product-documents.component';
import Skeleton from '~/shared/skeleton';
import Splash, { splashType } from '~/shared/splash/splash.component';
import { Svg } from '~/shared/svg';
import VehiclesFits from '~/shared/vehicles-fits/vehicles-fits.component';
import ProductAttributes from '../../shared/product-attributes/product-attributes.component';
import styles from './styled.module.scss';

enum EventTypeI {
    Standard = 'Standard',
    DirectHit = 'DirectHit',
}

enum Content {
    OtherVariantsComponent = 'OtherVariants',
    ProductDescriptionComponent = 'ProductDescription',
    VehicleFitsComponent = 'VehicleFits',
    ProductDocumentsComponent = 'ProductDocuments',
    ProductAttributesComponent = 'ProductAttributes',
    CrossProductComponent = 'CrossNumbers',
    PreviousOrdersComponent = 'PreviousOrders',
    DepositComponent = 'DepositComponent',
}

interface IAccordionButton {
    id: number;
    shouldRender: boolean;
    label: string;
    content: Content;
    name?: string;
    trackingInfo: string;
}

//Name before: product-detail-card.component.tsx
export default function W122SparePartVariant({
    productId,
    ftzCode: itemNo,
    eventType,
    pictogramId,
    pictogramGroupAlias,
    pictogramGroupType,
}: Bff.ISparePartVariantWidgetViewModel) {
    const { data: breadcrumb } = useBreadcrumb();
    const { profile } = useUser();
    const translate = useTranslations();
    const { vehicle } = useLayoutState();
    const pictogramInfo = {
        pictogramId: pictogramId,
        pictogramGroupAlias: pictogramGroupAlias,
        pictogramGroupType: pictogramGroupType,
    };
    const { data: product, isLoading } = useProductDetails(productId, pictogramInfo);
    const selectedVariant = product?.variants?.find((v) => v.itemNo === itemNo);
    const { data: previousOrders } = usePreviousOrders(selectedVariant?.itemNo);
    const otherProductVariants = product?.variants?.filter((variant) => variant.itemNo !== itemNo);
    const [isOpen, setOpen] = useState(false);
    const suggestedProductsRef = useRef<HTMLDivElement>(null);

    const { data: vehicleInfo } = useProductVehicleInfo({
        productIds: [Number(productId)],
        carId: vehicle?.carId,
    });

    const currentVehicleInfo = vehicleInfo?.productInfos?.find((item) => item.productId === Number(productId));

    const { data: variantVehicleOilInfo } = useVehicleOilInfo({
        ftzCodeList: [product?.itemNo as string],
        shouldFetch: product?.hasOilProducts,
        select: selectVehicleOilInfoByVariant(product?.itemNo ? [product.itemNo] : undefined),
    });

    const { data: oeMatches } = useOEMatchesVariants({
        variantIds: product ? (product?.variants?.map((v) => v.id) as number[]) : [],
        shouldFetch: product ? product?.catalogType === ProductTypes?.CatalogType?.SparePart : false,
    });

    const { data: features } = useFeatures();
    const { shouldShowFeature } = useFeatureToggle();

    const oeMatchReference = oeMatches && selectedVariant ? oeMatches[selectedVariant?.itemNo as string] : null;

    const { data: prices, isLoading: isLoadingPrices } = useProductPrices(
        [
            {
                articleId: itemNo,
                quantity: selectedVariant?.colli || 1,
            },
        ],
        Boolean(selectedVariant),
    );

    const price = prices && itemNo ? prices[itemNo] : null;

    const isPageLoaded = Boolean(selectedVariant) && !isLoadingPrices && price;

    const depositId = price?.actualPrice?.depositArticleId;

    const { data: productsData = [] } = useDepositProducts();

    const depositMatch = productsData?.find((deposit) => deposit.ftzCode === depositId);

    const contentButtons: IAccordionButton[] = [
        {
            id: 2,
            shouldRender: (otherProductVariants?.length as number) > 0,
            label: `${translate('product.otherVariants', 'Andre varianter')} (${otherProductVariants?.length})`,
            content: Content.OtherVariantsComponent,
            trackingInfo: 'andre_variationer',
        },
        {
            id: 3,
            shouldRender: true,
            label: translate('product.description', 'Beskrivelse'),
            content: Content.ProductDescriptionComponent,
            name: '',
            trackingInfo: 'beskrivelse',
        },
        {
            id: 4,
            shouldRender: product?.catalogType === ProductTypes.CatalogType.SparePart || !!product?.isSparePart,
            label: translate('product.alsoFits', 'Passer til'),
            content: Content.VehicleFitsComponent,
            name: FITTINGS_TAB,
            trackingInfo: 'passer_også_til',
        },
        {
            id: 6,
            shouldRender: !!selectedVariant?.hasDocuments,
            label: translate('product.furtherInformation', 'Yderligere information'),
            content: Content.ProductDocumentsComponent,
            name: '',
            trackingInfo: 'yderligere_information',
        },
        {
            id: 7,
            shouldRender: (selectedVariant?.crossNumbers?.length as number) > 0,
            label: translate('product.crossNumber', 'Kryds nr.'),
            content: Content.CrossProductComponent,
            name: CROSS_NUMBER_TAB,
            trackingInfo: 'kryds_nr.',
        },
        {
            id: 1,
            shouldRender: (previousOrders?.invoices?.length as number) > 0,
            label: translate('product.previousOrders', 'Tidligere køb'),
            content: Content.PreviousOrdersComponent,
            name: '',
            trackingInfo: 'tidligere_køb',
        },
        {
            id: 5,
            shouldRender: !!selectedVariant?.attributes,
            label: translate('product.specifications', 'Specifikationer'),
            content: Content.ProductAttributesComponent,
            name: SPECIFICATIONS_TAB,
            trackingInfo: 'specifikationer',
        },
        {
            id: 8,
            shouldRender: Boolean(depositMatch),
            label: translate('createCredit.returnDeposit', 'Returner depositum'),
            content: Content.DepositComponent,
            name: '',
            trackingInfo: 'returner_depositum',
        },
    ];

    const [selectedComponent, setSelectedComponent] = useState<Content | null>();

    const renderComponent = () => {
        switch (selectedComponent) {
            case Content.ProductDescriptionComponent:
                return (
                    <ProductDescription
                        importantText={product?.variants?.[0]?.importantText}
                        description={selectedVariant?.description}
                        vehicleInfoOilText={product?.itemNo ? variantVehicleOilInfo?.[product.itemNo as string] : undefined}
                        vehicleInfoText={currentVehicleInfo}
                        loading={isLoading}
                        className={styles.tabContent}
                    />
                );
            case Content.VehicleFitsComponent:
                return (
                    <>
                        <div className={styles.tabContent}>
                            <VehiclesFits variantsIDs={[selectedVariant?.itemNo as string]} addLoader listClass={styles.listClass} />
                        </div>
                    </>
                );
            case Content.ProductDocumentsComponent:
                return <ProductDocuments className={styles.tabContent} variantId={itemNo} />;
            case Content.ProductAttributesComponent:
                return (
                    <ProductAttributes
                        loading={isLoading}
                        attributes={[
                            ...(product?.attributes as ProductTypes.IProductAttribute[]),
                            ...(selectedVariant?.attributes as ProductTypes.IVariant[]),
                        ]}
                        className={styles.tabContent}
                    />
                );
            case Content.CrossProductComponent:
                return <ProductCrossNumbers values={selectedVariant?.crossNumbers} visible={false} oeReference={oeMatchReference as string} />;
            case Content.PreviousOrdersComponent:
                return (
                    <PreviousOrders
                        invoices={previousOrders?.invoices}
                        productItem={{ ...selectedVariant } as ProductTypes.IVariant}
                        className={styles.tabContent}
                    />
                );
            case Content.OtherVariantsComponent:
                return (
                    <div className={styles.otherVariantsContent}>
                        <OtherVariants key={selectedVariant?.itemNo} otherProductVariants={otherProductVariants} onAddToBasket={handleAddToBasket} />
                    </div>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        if (isPageLoaded) {
            const listName = getListName(breadcrumb, selectedVariant?.title);
            GA4ViewItem(selectedVariant as ProductTypes.IVariant, {
                currency: profile?.currency as string,
                price: price?.actualPrice?.customerPrice ?? 0,
                listName,
                productId: product?.itemNo,
                productType: product?.catalogType,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPageLoaded]);

    const selectedButton = contentButtons.find((btn) => btn.content === selectedComponent);

    useEffect(() => {
        if ((previousOrders?.invoices?.length as number) > 0) {
            return setSelectedComponent(() => Content.PreviousOrdersComponent);
        }
        if ((eventType !== EventTypeI.Standard && (otherProductVariants?.length as number) > 0) || (otherProductVariants?.length as number) > 0) {
            return setSelectedComponent(() => Content.OtherVariantsComponent);
        }
        return setSelectedComponent(() => Content.ProductDescriptionComponent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousOrders, otherProductVariants?.length]);

    const toggleAccordion = ({ content, trackingInfo }: IAccordionButton, selected: boolean) => {
        if (content === Content.DepositComponent) {
            setOpen(true);
        } else {
            setSelectedComponent(selected ? null : content);
        }

        GA4ProductInfoTab(trackingInfo);
    };

    // Extends the default behaviour when adding to basket.
    const handleAddToBasket = () => {
        const isAnimationOnAddFeatureEnabled = shouldShowFeature(features?.productList?.useAccessoryProducts);
        const isAnimatingOnAdd = selectedVariant?.localizedRelatedVariants?.some((item) =>
            [ProductTypes.NecessityLevel.MustBeUsed, ProductTypes.NecessityLevel.RecommendedNotIncluded].includes(item.necessityLevel),
        );

        if (!isAnimationOnAddFeatureEnabled || !isAnimatingOnAdd) {
            return;
        }

        suggestedProductsRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const related = useMemo(() => {
        const relatedVariants = selectedVariant?.localizedRelatedVariants?.length
            ? selectedVariant.localizedRelatedVariants
            : product?.localizedRelatedVariants;

        const relatedVariantIds = relatedVariants?.map((item) => item.variantId);

        return {
            relatedVariants,
            relatedVariantIds,
        };
    }, [selectedVariant, product]);

    const handleDepositCreditModalOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setOpen(false);
    }, []);

    return (
        <>
            <Modal
                position="right"
                open={isOpen}
                onOpenChange={handleDepositCreditModalOpenChange}
                title={translate('createCredit.returnDeposit', 'Returner depositum')}
            >
                <CreateDepositCredit onClose={() => setOpen(false)} depositMatchId={depositMatch?.id.toString()} />
            </Modal>
            <div className={styles.cardWrapper}>
                <main className={styles.mainContainer}>
                    <div className={styles.imageBox}>
                        <div className={styles.splashes}>
                            {oeMatchReference != null && (
                                <div>
                                    <Splash type="OE" />
                                </div>
                            )}
                            {product?.campaign && (
                                <div>
                                    <Splash type={product.campaign.code as splashType} />
                                </div>
                            )}
                            {selectedVariant?.campaign && selectedVariant?.campaign?.code !== product?.campaign?.code && (
                                <div>
                                    <Splash type={selectedVariant?.campaign.code as splashType} />
                                </div>
                            )}
                        </div>
                        <Images loading={isLoading} images={selectedVariant?.images} />
                    </div>

                    <div className={styles.cardRight}>
                        <Description
                            variant={selectedVariant as ProductTypes.IVariant}
                            subGroup={selectedVariant?.productGroups?.subGroup as string}
                            brand={selectedVariant?.brand}
                            title={selectedVariant?.title as string}
                            colli={selectedVariant?.colli as number}
                            colliLocked={selectedVariant?.colliLocked}
                            unit={selectedVariant?.unit as string}
                            name={selectedVariant?.title as string}
                            itemNo={itemNo}
                            prices={prices}
                            isLoadingPrices={isLoadingPrices}
                        >
                            <AddFavorite
                                product={selectedVariant}
                                price={price?.actualPrice}
                                itemId={itemNo}
                                className={styles.favoriteBtn}
                                placement="detail"
                                itemNoList={itemNo ? [itemNo] : []}
                            />
                            <AddToBasketContainer
                                className={styles.basketBtn}
                                label={translate('product.buy', 'Køb')}
                                syncWithBasket
                                noFullWidth
                                url={selectedVariant?.url as string}
                                onAddToBasket={handleAddToBasket}
                                productDetails={{
                                    itemNo: itemNo,
                                    customerPrice: price?.actualPrice?.customerPrice,
                                    colli: selectedVariant?.colli,
                                    colliLocked: selectedVariant?.colliLocked,
                                }}
                            />
                        </Description>
                        {isLoading ? (
                            <Skeleton
                                style={{
                                    width: '100%',
                                    height: '200px',
                                }}
                            />
                        ) : (
                            <div className={styles.accordion}>
                                {contentButtons
                                    .filter((btn) => btn.shouldRender)
                                    .map((btn) => {
                                        const selected = btn.content === selectedComponent;

                                        return (
                                            <button
                                                style={{ order: btn.id }}
                                                className={clsx(styles.accordionButton, {
                                                    [styles.isOpen]: selected,
                                                })}
                                                key={btn.id}
                                                type="button"
                                                onClick={() => toggleAccordion(btn, selected)}
                                            >
                                                <span>{btn.label}</span>
                                                <Svg name="chevron-right" thick className={styles.accordionIcon} />
                                            </button>
                                        );
                                    })}

                                {selectedComponent && (
                                    <div className={styles.bottomSection} style={{ order: selectedButton?.id || 1 }}>
                                        {renderComponent()}
                                    </div>
                                )}
                            </div>
                        )}
                        <div className={styles.errorButtonWrapper}>
                            <ErrorReportButton
                                data={{
                                    itemId: selectedVariant?.itemNo,
                                    licensePlateNumber: vehicle?.licensePlate?.number,
                                    carId: vehicle?.carId,
                                    productVariants: product?.variants,
                                    product,
                                }}
                            />
                        </div>
                    </div>
                </main>
            </div>
            {related.relatedVariantIds && related.relatedVariantIds.length > 0 ? (
                <div ref={suggestedProductsRef} className={styles.containerFooter}>
                    <RelatedProductsList
                        key={selectedVariant?.itemNo}
                        showTitle
                        relatedVariantIds={related.relatedVariantIds}
                        loadingItems={related.relatedVariantIds?.length}
                        relatedVariantsExtraInfo={related.relatedVariants}
                    />
                </div>
            ) : null}
            {selectedVariant && (
                <Head>
                    <title>{selectedVariant?.title}</title>
                </Head>
            )}
        </>
    );
}
