import { getVariantPriceRequest, useProductPrices } from '~/libs/queries/products/hooks/use-product-prices';
import { useRelatedProducts } from '~/libs/queries/products/hooks/use-related-products';
import { useStockStatus } from '~/libs/queries/products/hooks/use-stock-status';
import useProductInfosVariant from '~/libs/queries/products/hooks/useProductInfosVariant';
import { Products } from '~/models/products.d';
import ProductCardSmallContainer from '~/page-elements/product-card/product-card-small/product-card-small.component';
import Heading from '~/shared/heading/heading.component';
import Carousel, { CarouselItem } from '../../shared/carousel/carousel.component';
import ErrorBox from '../../shared/error-box/error-box';
import useTranslations from '../../shared/hooks/use-translations.hook';
import Text from '../../shared/text/text.component';
import styles from './RelatedProductsList.module.scss';
import TrackRelatedProductsList from './TrackRelatedProductsList';
import { useIntersectionObserver } from 'usehooks-ts';

type Props = {
    gutter?: number;
    relatedVariantIds?: number[];
    relatedVariantsExtraInfo?: Products.IRelatedVariant[];
    loadingItems?: number;
    showTitle?: boolean;
};

export default function RelatedProductsList({ relatedVariantIds, gutter, loadingItems, showTitle, relatedVariantsExtraInfo }: Props) {
    const translate = useTranslations();
    const [ref, isVisible] = useIntersectionObserver({
        threshold: 0.1,
        rootMargin: '1000px',
    });
    const { data: relatedProducts, isError, isLoading } = useRelatedProducts(relatedVariantIds);
    const { data: prices } = useProductPrices(getVariantPriceRequest(relatedProducts), isVisible);
    const ftzCodes = relatedProducts?.map((product) => product.ftzCode).filter((item): item is string => typeof item === 'string') ?? [];
    const { data: stockStatus } = useStockStatus(ftzCodes);
    const { data } = useProductInfosVariant({ ftzCodes });

    if (!relatedVariantIds?.length) return null;

    if (isError) {
        return (
            <div
                className={styles.error}
                style={{
                    ['--related-products-gutter' as string]: gutter && `${gutter}px`,
                }}
            >
                <ErrorBox>
                    {translate('product.relatedProductsError', 'Vi kan desværre ikke finde nogle tilbehørsprodukter lige nu. Prøv evt. igen senere.')}
                </ErrorBox>
            </div>
        );
    }

    return (
        <>
            <div ref={ref}>
                {showTitle && (
                    <Heading tagName="h4" className={styles.sectionTitle}>
                        {translate('product.relatedProducts', 'Tilbehørsprodukter')}
                    </Heading>
                )}
                {relatedProducts ? (
                    <Text tagName="p" textStyle="body" fontWeight="semiBold" textAlign="left" className={styles.numberOfProducts}>
                        {translate('product.relatedProductsAmount', 'Antal produkter')} ({relatedProducts.length})
                    </Text>
                ) : null}{' '}
                <Carousel gutter={gutter} arrowsInMiddle>
                    {isLoading
                        ? Array.from({ length: loadingItems ?? 0 })?.map((_, index) => (
                              <CarouselItem key={`skeleton-${index}`}>
                                  <ProductCardSmallContainer isLoading />
                              </CarouselItem>
                          ))
                        : relatedProducts?.map((product, index) => (
                              <CarouselItem key={`product-${product?.ftzCode}-${index}`}>
                                  <ProductCardSmallContainer
                                      product={product}
                                      relatedVariantsNecessityInfo={relatedVariantsExtraInfo}
                                      listName="accessory_products"
                                      price={prices?.[product.ftzCode as string]}
                                      stockStatus={stockStatus?.[product.ftzCode as string]}
                                      extraInfo={data?.find((item) => item.ftzCode === product.ftzCode)}
                                  />
                              </CarouselItem>
                          ))}
                </Carousel>
            </div>
            <TrackRelatedProductsList prices={prices} products={relatedProducts} />
        </>
    );
}
