import { useCallback, useState } from 'react';
import { addErrorToast, useLayoutDispatch } from '~/context/layout.context';
import { useDeleteUser } from '~/libs/queries/users/hooks/use-delete-sub-user';
import { useSubUsers } from '~/libs/queries/users/hooks/use-sub-users';
import { useAddOrUpdateSubUser } from '~/libs/queries/users/hooks/use-update-or-add-sub-user';
import { Users } from '~/models/users.d';
import useTranslations from '~/shared/hooks/use-translations.hook';

export default function useUserList() {
    const [query, setQuery] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<Users.IExternalSubUserInfo | null>(null);
    const [deleting, setDeleting] = useState<number | null>(null);
    const dispatch = useLayoutDispatch();
    const translate = useTranslations();
    const { data, isLoading } = useSubUsers({ userId: selectedUser?.id, query });
    const { mutateAsync: createUpdateUser, isLoading: isCreatingUser } = useAddOrUpdateSubUser({
        userId: selectedUser?.id,
        query,
    });

    const { mutateAsync: deleteUserMutation } = useDeleteUser({ userId: selectedUser?.id, query });

    const editUser = useCallback((user: Users.IExternalSubUserInfo) => {
        setSelectedUser(user);
        setIsModalOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsModalOpen(false);
        setSelectedUser(null);
    }, []);

    const openModal = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    const deleteUser = useCallback(
        async (deletedUserId: number) => {
            await deleteUserMutation(deletedUserId);
            setSelectedUser(null);
        },
        [deleteUserMutation],
    );

    const createOrUpdateUser = useCallback(
        async (user: Users.ICreateSubUserInfoRequest) => {
            await createUpdateUser(user);
            setSelectedUser(null);
        },
        [createUpdateUser],
    );

    const handleOpenChange = useCallback((open: boolean) => {
        if (open) return;
        setIsModalOpen(false);
        setSelectedUser(null);
    }, []);

    const handleDelete = useCallback(
        async (userId: number) => {
            setDeleting(userId);
            try {
                await deleteUser(userId);
            } catch (error) {
                dispatch(addErrorToast(translate('common.somethingWentWrong', 'Der gik noget galt.')));
            } finally {
                setDeleting(null);
            }
        },
        [deleteUser, dispatch, translate],
    );

    return {
        isCreatingUser,
        closeModal,
        createOrUpdateUser,
        data,
        deleteUser,
        deleteUserMutation,
        deleting,
        dispatch,
        editUser,
        handleDelete,
        handleOpenChange,
        isLoading,
        isModalOpen,
        openModal,
        query,
        selectedUser,
        setQuery,
        translate,
    };
}
