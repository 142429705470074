import { StockStatusWithStockQuery } from '~/shared/stock-status/stock-status.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';

export type ProductItemIDProps = {
    itemNo?: string;
    caption: string;
    captionClassName?: string;
};

export function ProductItemStockStatus({ itemNo, caption, captionClassName }: ProductItemIDProps) {
    return (
        <ValueWithCaption caption={caption} captionClassName={captionClassName}>
            <StockStatusWithStockQuery itemNo={itemNo} />
        </ValueWithCaption>
    );
}
