import { InvoiceDraftEntry } from '~/widgets/overview/invoices-widget/invoice-draft/invoice-draft';
import { useEffect, useState } from 'react';
import { InvoiceDraftLineOptions } from '~/widgets/overview/invoices-widget/invoice-draft/invoice-draft-line/invoice-draft-line.component';
import { useRouter } from 'next/router';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { generateUniqueIdFromInvoice } from '~/widgets/overview/invoices-widget/invoice-draft/utils';
import useUser from '~/libs/use-user';
import { getPreviousOrdersByIdentifier } from '~/libs/queries/orders';
import { useLayoutState } from '~/context/layout.context';
import { Bff } from '~/models/bff';

export function useInvoiceDraftLineOptions(entries: InvoiceDraftEntry[] = []) {
    const [options, setOptions] = useState<InvoiceDraftLineOptions>();

    const { user } = useUser();
    const { selectedDepartment } = useLayoutState();

    const router = useRouter();
    const queryClient = useQueryClient();

    useEffect(() => {
        async function getAllInvoices(entry: InvoiceDraftEntry) {
            await queryClient.cancelQueries(queryKeys.orders.previousOrdersByIdentifier(router.locale, selectedDepartment?.id, entry.itemNo));
            const data = await queryClient.fetchQuery<Bff.IVariantSimpleWithInvoices | undefined>(
                queryKeys.orders.previousOrdersByIdentifier(router.locale, selectedDepartment?.id, entry.itemNo),
                () => getPreviousOrdersByIdentifier({ identifier: entry.itemNo, locale: router.locale, user }),
                {
                    staleTime: 999999, // TODO: determine stale time for invoices
                },
            );

            const { invoicesList: { invoices = [] } = {} } = data ?? {};

            return invoices;
        }

        async function getFieldOptions(entry: InvoiceDraftEntry, chosen: string[]) {
            const invoices = await getAllInvoices(entry);

            const invoiceIds = new Set();
            const licensePlates = new Set();

            for (const invoice of invoices) {
                if (
                    chosen.includes(generateUniqueIdFromInvoice(entry.itemNo, invoice, entry.depositOnly ? 'deposit' : 'total')) &&
                    invoice.invoiceId !== entry.invoiceId
                ) {
                    // Unique combination of itemNo, invoiceId and carId is already in entries
                    continue;
                }

                if (invoice.possibleToCreditQuantity === 0) {
                    // The variant invoice does not have any items to credit
                    continue;
                }

                if (!invoiceIds.has(invoice.invoiceId)) {
                    // Invoice id not in options for entry
                    invoiceIds.add(invoice.invoiceId);
                }

                if (!invoice.carInfo?.licensePlate?.number) {
                    // Car id not on invoice
                    continue;
                }

                if (licensePlates.has(invoice.carInfo.licensePlate.number)) {
                    // Car id already present in options
                    continue;
                }

                licensePlates.add(invoice.carInfo.licensePlate.number);
            }

            return [
                entry.uniqueId,
                {
                    invoiceIds: Array.from(invoiceIds),
                    licensePlates: Array.from(licensePlates),
                },
            ] as const;
        }

        async function populate() {
            const chosen = entries.map((item) => item.uniqueId);
            const options = await Promise.all(entries.map((entry) => getFieldOptions(entry, chosen)));

            setOptions(
                options.reduce((acc, curr) => {
                    return { ...acc, [curr[0]]: curr[1] };
                }, {}),
            );
        }

        populate();
    }, [entries]);

    return options;
}
