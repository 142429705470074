import { Products, Products as ProductTypes } from '~/models/products.d';
import React, { useEffect, useMemo } from 'react';
import ProductCardRow from '~/page-elements/product-card/product-card-row/product-card-row.component';
import { useOEMatchesProducts } from '~/libs/queries/products/hooks/use-oe-matches-products';
import { GA4ViewListItems } from '~/libs/ga4';
import useUser from '~/libs/use-user';
import { useBreadcrumb } from '~/libs/queries/routing';
import { getListName } from '~/libs/queries/routing/hooks/use-breadcrumb';
import { useFavoriteStatusList } from '~/libs/queries/favorites';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import { useVehicleOilInfo, VehicleOilInfo } from '~/libs/queries/vehicles';
import { selectVehicleOilInfoByVariant } from '~/libs/queries/vehicles/selectors/select-vehicle-oil-info-group';
import { useStockStatus } from '~/libs/queries/products/hooks/use-stock-status';
import { useProductsVehicleInfoByIds } from '~/libs/queries/vehicles/hooks/use-product-vehicle-info-by-ids';
import { useProductPrices } from '~/libs/queries/products/hooks/use-product-prices';
import IProductInfoText = Products.IProductInfoText;
import { useProductVehicleInfo } from '~/libs/queries/products';

export interface IProductWithVehicleInfo extends ProductTypes.IProduct {
    vehicleInfoText: string;
    vehicleOilInfoTexts: {
        [key: string]: Array<VehicleOilInfo>;
    };
}

interface IProps {
    items?: ProductTypes.IProduct[];
    setModalContent?: (content: JSX.Element) => void;
    carId?: string;
    isLoadingMore: boolean;
    pageIndex: number;
}

const ProductListRows: React.FC<IProps> = ({ items = [], setModalContent, carId, isLoadingMore, pageIndex }) => {
    const variantList = useMemo(() => {
        return items?.flatMap((item) => item.variants);
    }, [items]);

    const articleList = useMemo(() => {
        return variantList?.map((v) => ({
            articleId: v?.itemNo?.toString(),
            quantity: v?.colliLocked ? v.colli : 1,
        }));
    }, [variantList]);

    // we collect a list of id
    const idList = useMemo(() => {
        return variantList?.map((v) => v?.id as number);
    }, [variantList]);

    const itemNoList = useMemo(() => {
        return variantList?.map((product) => product?.itemNo);
    }, [variantList]);

    const hasSparePartProduct = useMemo(() => {
        return items?.some((i) => i.catalogType === ProductTypes?.CatalogType?.SparePart);
    }, [items]);

    const hasOilProducts = useMemo(() => {
        return items && items.filter((item) => item.hasOilProducts).length > 0;
    }, [items]);

    const { data: prices, isLoading: isLoadingPrices, isError: isErrorPrices } = useProductPrices(articleList, true);
    const { data: stockList, isLoading: isLoadingStockList, isError: isErrorStockList } = useStockStatus(itemNoList as string[]);

    const { data: oeMatchesData } = useOEMatchesProducts({
        variantIds: idList,
    });
    const { data: breadcrumb } = useBreadcrumb();

    const { activeProfile } = useActiveUser();
    const { data: favoriteStatusList } = useFavoriteStatusList({
        userId: activeProfile?.id,
        itemNoList: itemNoList as string[],
        pageIndex,
        placement: 'list',
    });

    const ftzCodes = useMemo(() => items?.map((i) => i.itemNo), [items]);
    const { data: variantVehicleOilInfo } = useVehicleOilInfo({
        ftzCodeList: ftzCodes as string[],
        shouldFetch: hasOilProducts,
        select: selectVehicleOilInfoByVariant(ftzCodes as string[]),
    });

    const productIdList = useMemo(() => {
        return items.map((item) => item.id);
    }, [items]);

    const { data: productsVehicleInfo } = useProductsVehicleInfoByIds({
        carModelTypeId: carId,
        productIds: productIdList,
        shouldFetch: !!carId && !!items && items.length > 0 && !isLoadingMore,
    });

    const productsList = items?.map((product: ProductTypes.IProduct) => {
        const vehicleInfoText = productsVehicleInfo?.productInfos?.length
            ? productsVehicleInfo?.productInfos?.find((p: IProductInfoText) => p?.productId === product?.id)?.info?.join(', ')
            : undefined;
        const vehicleOilInfoTexts = variantVehicleOilInfo ? variantVehicleOilInfo[product?.itemNo as string] : undefined;
        const relatedVariantIds = product.relatedVariantIds;

        return {
            ...product,
            relatedVariantIds,
            vehicleInfoText,
            vehicleOilInfoTexts,
        };
    });

    const { profile } = useUser();

    useEffect(() => {
        if (isLoadingPrices || !prices || (hasSparePartProduct && !oeMatchesData)) {
            return;
        }

        // get oeMatches
        const oeMatches: Record<number, boolean> | undefined =
            oeMatchesData &&
            productsList?.reduce((productMatches, item) => {
                const baseProductIsOeMatch = oeMatchesData.some(
                    (oeItem: ProductTypes.IProductOeMatches) => oeItem.isProductOeMatch && oeItem.productId === item.id,
                );

                const variantOeMatches = item?.variants?.reduce((res, variant) => {
                    return { ...res, [variant.id]: baseProductIsOeMatch };
                }, {});

                return {
                    ...productMatches,
                    ...variantOeMatches,
                };
            }, {});

        const variantsWithPrices = variantList?.map((v) => {
            const price = prices.hasOwnProperty(v?.itemNo as string) && prices[v?.itemNo as string].actualPrice;
            return {
                ...v,
                price,
            };
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        GA4ViewListItems(variantsWithPrices, {
            currency: profile?.currency,
            listName: getListName(breadcrumb),
            oeMatches,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingPrices, prices, oeMatchesData]);

    const { data: infoTexts } = useProductVehicleInfo({
        productIds: productIdList,
        carId,
    });

    return (
        <>
            {productsList?.map((item, index) => {
                oeMatchesData?.find((oeItem: ProductTypes.IProductOeMatches) => oeItem.productId === item.id);
                return (
                    <ProductCardRow
                        productOEMatches={oeMatchesData}
                        productInfoTexts={infoTexts?.productInfos}
                        key={item.id}
                        cardData={item as IProductWithVehicleInfo}
                        position={index + 1}
                        setModalContent={setModalContent as (content: JSX.Element) => void}
                        loadingPrices={isLoadingPrices}
                        errorPrices={isErrorPrices}
                        prices={prices}
                        stockList={stockList}
                        loadingStockList={isLoadingStockList}
                        errorStockList={isErrorStockList}
                        favoriteStatusList={favoriteStatusList}
                        pageIndex={pageIndex}
                        itemNoList={itemNoList as string[]}
                    />
                );
            })}
        </>
    );
};

interface ProductListRowPagesProps {
    pages?: ProductTypes.IPagedResultOfProduct[];
    setModalContent?: (content: JSX.Element) => void;
    carId?: string;
    isLoadingMore: boolean;
}

export function ProductListRowPages({ pages, ...props }: ProductListRowPagesProps) {
    return <>{pages?.map((page, i) => <ProductListRows key={i} pageIndex={i} items={page.items} {...props} />)}</>;
}
