import { Bff } from '~/models/bff';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import PageTitle from '~/shared/page-title/page-title.component';
import { UserList } from './UserList';
import { CustomerInfo } from './CustomerInfo';

export default function W141UsersAdministration(_: Bff.IStandardModuleViewModel) {
    const translate = useTranslations();
    return (
        <>
            <PageTitle>
                <Heading tagName="h3">{translate('userAdministration.userAdministration', 'Brugeradministration')}</Heading>
            </PageTitle>
            <CustomerInfo />
            <UserList />
        </>
    );
}
