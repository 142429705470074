import useUser from '~/libs/use-user';
import { useEndpoints } from '~/services/service-endpoint';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import { useLayoutState } from '~/context/layout.context';
import { useQuery } from '@tanstack/react-query';
import { Basket as BasketTypes } from '~/models/basket.d';
import { queryKeys } from '~/libs/queries/query-keys';
import { apiClient } from '~/services/api-client';
import { useCurrentBasket } from '~/libs/queries/basket';

const useNextDelivery = () => {
    const { data: basket } = useCurrentBasket();
    const { activeProfile } = useActiveUser();
    const { isLoggedIn, user } = useUser();
    const { selectedDepartment } = useLayoutState();
    const { baskets, createUrl } = useEndpoints();

    return useQuery<BasketTypes.INextDeliveryDto, Error>({
        queryKey: queryKeys.basket.nextDelivery(selectedDepartment?.id, basket?.deliveryDetails?.deliveryAddressId),
        enabled: Boolean(isLoggedIn && activeProfile),
        refetchOnWindowFocus: true,
        queryFn: () =>
            apiClient.auth(user).get(
                createUrl({
                    endpoint: baskets.getNextDelivery,
                    query: {
                        departmentId: selectedDepartment?.id,
                        addressId: basket?.deliveryDetails?.deliveryAddressId,
                    },
                }),
            ),
    });
};

export default useNextDelivery;
