export function getSelectedQuantity(quantity: number, colli: number, colliLocked: boolean) {
    if (quantity === 1) {
        return 1;
    }

    if (colliLocked && colli >= quantity) {
        return quantity;
    }

    return 0;
}
