import clsx from 'clsx';
import React, { FC } from 'react';
import { IWithClassName } from '~/models/dev';
import { Products as ProductTypes } from '~/models/products';
import styles from './status-dot.module.scss';

interface IProps extends IWithClassName {
    statusCode?: ProductTypes.StockStatusCode;
}

const StatusDot: FC<IProps> = ({ statusCode, className }) => {
    if (typeof statusCode === 'undefined') {
        return null;
    }

    return <span className={clsx(styles[`dot-${statusCode}`], className)} />;
};

export default StatusDot;
