import React from 'react';
import useTranslations from '~/shared/hooks/use-translations.hook';

import styles from './credit-entry-duplicate-warning.module.scss';

export type CreditEntryDuplicateWarningProps = {
    itemId: string;
};

export function CreditEntryDuplicateWarning({ itemId }: CreditEntryDuplicateWarningProps) {
    const translate = useTranslations();

    return (
        <p className={styles.creditEntryWarning}>
            {translate('createCredit.alreadyInDraft', '[id] er allerede en del af din kladde. Ændringer vil opdatere kladden.').replace(itemId)}
        </p>
    );
}
