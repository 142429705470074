import React, { FC } from 'react';
import useTranslations from '~/shared/hooks/use-translations.hook';
import styles from './item.module.scss';
import Radio from '~/shared/form-elements/radio/radio.component';

import Text from '~/shared/text/text.component';
import { Link } from '~/shared/link';
import Select from '../../form-elements/select/select.component';
import ValueWithCaption from '../../value-with-caption/value-with-caption.component';
import Price from '../../price/price.component';
import Button from '~/shared/buttons/button/button.component';
import { Svg } from '~/shared/svg';
import useUser from '~/libs/use-user';
import Tooltip, { TooltipContentWrapper } from '~/shared/tooltip/tooltip.component';
import { ICreditItem } from '../create-credit';
import { getQuantityOptions } from '~/widgets/overview/invoices-widget/invoice-draft/utils';
import { CreditEntryTitle } from '~/shared/create-credit/credit-entry/components';

export const DEPOSIT_ONLY_LOCATION = '9DEP';

interface IPropsOld {
    item: ICreditItem;
    onChangeQuantity: (itemId: string | number, quantity: number) => void;
    onChangeDeposit: (ItemId: string | number, val: boolean) => void;
    onDeleteItem: (ItemId: string | number) => void;
    fetching: boolean;
    added?: boolean;
}

const ItemOld: FC<IPropsOld> = ({ item, onChangeQuantity, onChangeDeposit, fetching, onDeleteItem, added }) => {
    const translate = useTranslations();
    const { isInternalUser } = useUser();

    const creditLocation = item.depositOnly ? DEPOSIT_ONLY_LOCATION : item.creditLocation;

    const colliValues =
        item?.hasDeposit && item.depositOnly
            ? getQuantityOptions(false, item.possibleToCreditQuantity, 0)
            : getQuantityOptions(
                  !!item?.colliLocked,
                  // some items might be returned
                  item?.possibleToCreditQuantity,
                  item.colli,
              );

    return (
        <div className={styles.itemWrapper}>
            {added ? (
                <p className={styles.warning}>
                    {translate('createCredit.alreadyInDraft', '[id] er allerede en del af din kladde. Ændringer vil opdatere kladden.').replace(
                        item.itemId,
                    )}
                </p>
            ) : null}
            <div className={styles.item}>
                {item.title ? <CreditEntryTitle title={item.title} brand={item.brand} /> : null}
                <div className={styles.quantity}>
                    {item?.quantity !== item?.possibleToCreditQuantity && (
                        <Tooltip
                            content={
                                <TooltipContentWrapper>
                                    {translate(
                                        'createCredit.numberOfItemsLeftAfterReturns',
                                        'Antal varer du har tilbage efter tidligere retur [quantity]',
                                    ).replace('[quantity]', item?.possibleToCreditQuantity)}
                                </TooltipContentWrapper>
                            }
                        />
                    )}
                    <Select
                        wrapperClassName={styles.dropdown}
                        isValid={!item.quantityWarn}
                        disabled={fetching}
                        value={item.selectedQuantity}
                        onChange={(e) => onChangeQuantity(item?.itemId as string, Number(e.target.value))}
                        label={translate('overview.number', 'antal')}
                        errorMessage={item.quantityWarn ? translate('overview.selectQuantity', 'Vælg Antal') : undefined}
                    >
                        {(item.selectedQuantity === 0 || (item.depositOnly && item.isDepositProduct)) && <option value={0}>-</option>}
                        {colliValues.map((v, i) => (
                            <option key={`${v}-${i}`}>{v}</option>
                        ))}
                    </Select>
                </div>
                <ValueWithCaption caption={translate('product.itemNo', 'Vare nr')}>
                    <Link href={item.url}>
                        <Text textStyle="monoMedium">{item.itemId}</Text>
                    </Link>
                </ValueWithCaption>
                {isInternalUser ? (
                    <ValueWithCaption caption={translate('overview.location', 'Lokation')}>
                        <Text textStyle="monoMedium">{creditLocation ?? '-'}</Text>
                    </ValueWithCaption>
                ) : (
                    <div />
                )}
                <div className={styles.price}>
                    {!item?.depositOnly && (
                        <Price textAlign="right">
                            {item?.unitPrice?.netPriceFormatted} / {translate('product.piece', 'stk')}
                        </Price>
                    )}
                    {item?.hasDeposit && (
                        <Price className={styles.depositPrice} textAlign="right" size="medium">
                            {translate('overview.deposit', 'Depositum')} {item?.unitPrice?.depositFormatted} / {translate('product.piece', 'stk')}
                        </Price>
                    )}
                </div>
            </div>
            <div className={styles.deleteButtonWrapper}>
                <div>
                    {item.hasDeposit && (
                        <section className={styles.depositSection}>
                            <div className={styles.radioWrapper}>
                                <Radio checked={!item.depositOnly} onChange={() => onChangeDeposit(item.itemId as string, false)} disabled={fetching}>
                                    {translate('overview.unusedItemsInclDeposit', 'Ubrugte varer (inkl. depositum)')}
                                </Radio>
                            </div>
                            <div className={styles.radioWrapper}>
                                <Radio checked={item.depositOnly} onChange={() => onChangeDeposit(item.itemId as string, true)} disabled={fetching}>
                                    {translate('createCredit.returnOnlyDeposit', 'Returner kun depositum')}
                                </Radio>
                            </div>
                        </section>
                    )}
                </div>
                <Button icon={<Svg name="bin" />} onClick={() => onDeleteItem(item.itemId as string)} buttonStyle="clean" />
            </div>

            {fetching && (
                //  Prevents click while fetching (submitting)
                <div className={styles.fetchingLayer} />
            )}
        </div>
    );
};

export { ItemOld };
