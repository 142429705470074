import { Products as ProductTypes } from '~/models/products.d';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import { useLayoutState } from '~/context/layout.context';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import { apiClient } from '~/services/api-client';
import { useRouter } from 'next/router';
import { queryKeys } from '~/libs/queries/query-keys';
import { useQuery } from '@tanstack/react-query';

interface IUniversalPartCategoriesProps {
    categoryId: number;
    levels?: number;
}

export function useUniversalPartCategoriesById({ categoryId = 0, levels = 1 }: IUniversalPartCategoriesProps) {
    const { isLoggedIn, user } = useUser();
    const { activeProfile } = useActiveUser();
    const { expertMode } = useLayoutState();
    const { products, createUrl } = useEndpoints();
    const { locale } = useRouter();

    const endpoint = createUrl({
        localeOption: LocaleOptions.path,
        endpoint: `${products.universalPartCategory}/${categoryId}`,
        query: {
            includeExpertProducts: expertMode,
            levels,
        },
    });

    return useQuery<ProductTypes.ICategory, Error>({
        queryKey: queryKeys.products.universalPartCategoriesById(locale, activeProfile?.id, categoryId, expertMode, levels),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(endpoint, { signal });
        },
        enabled: isLoggedIn && !!activeProfile?.id,
    });
}
