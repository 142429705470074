import { useCallback, useMemo } from 'react';
import { Products } from '~/models/products';
import { useProductPrices } from '~/libs/queries/products/hooks/use-product-prices';
import { useStockStatus } from '~/libs/queries/products/hooks/use-stock-status';
import { useOESearch } from '~/libs/queries/products';
import { CarInfoBannerVehicle, useCarInfo } from '~/widgets/car-info-banner/use-car-info-banner.hook';
import StandardCard from '~/page-elements/product-card/product-card-row/standard-card/standard-card.component';
import { StandardCardProvider } from '~/page-elements/product-card/product-card-row/standard-card/context';
import { useFavoriteStatusList } from '~/libs/queries/favorites';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import { Modal } from '~/features/primitives/modal';
import { useTopmotiveModalState } from '~/shared/topmotive/hooks';

export type TopmotiveProductCardProps = {
    product: Products.IProduct;
    vehicle?: CarInfoBannerVehicle;
};

export function TopmotiveProductCard({ product, vehicle }: TopmotiveProductCardProps) {
    const variantIds = useMemo(
        () => product?.variants?.map((item) => item.itemNo).filter((variantId): variantId is string => typeof variantId === 'string'),
        [product?.variants],
    );

    const { activeProfile } = useActiveUser();
    const {
        data: prices,
        isLoading: isLoadingPrices,
        isError: isErrorPrices,
    } = useProductPrices(
        variantIds?.map((variantId) => ({
            articleId: variantId,
            quantity: 1,
        })),
    );

    const { data: stockStatus, isLoading: isLoadingStockStatus, isError: isStockStatusError } = useStockStatus(variantIds);
    const { data: favoriteStatusList } = useFavoriteStatusList({
        userId: activeProfile?.id,
        itemNoList: variantIds ?? [],
        placement: 'list',
    });

    const card = {
        ...product,
        vehicleInfoText: '',
        vehicleOilInfoTexts: {},
    };

    return (
        <StandardCardProvider>
            <StandardCard
                cardData={card}
                position={1}
                loadingPrices={isLoadingPrices}
                prices={prices}
                stockList={stockStatus}
                errorPrices={isErrorPrices}
                loadingStockList={isLoadingStockStatus}
                errorStockList={isStockStatusError}
                itemNoList={variantIds ?? []}
                vehicle={vehicle}
                favoriteStatusList={favoriteStatusList}
            />
        </StandardCardProvider>
    );
}

export function TopmotiveProductModal() {
    const { open, details, reset } = useTopmotiveModalState((state) => ({
        open: state.open,
        details: state.details,
        reset: state.reset,
    }));

    const { data: vehicleInfo } = useCarInfo();
    const { data: products } = useOESearch({
        oeReference: details?.productId,
        manufacturerId: details?.manufacturerId,
        options: {
            retry: (failureCount, error) => {
                if (error.status === 404) {
                    return false;
                }

                return failureCount < 1;
            },
            keepPreviousData: false,
            enabled: !!details,
        },
    });

    const handleOnOpenChange = useCallback(
        (open: boolean) => {
            if (!open) {
                reset();
            }
        },
        [reset],
    );

    return (
        <Modal position="center" open={open === 'product'} onOpenChange={handleOnOpenChange}>
            {products?.map((product) => <TopmotiveProductCard key={product.id} product={product} vehicle={vehicleInfo} />)}
        </Modal>
    );
}
