import React, { useMemo } from 'react';
import clsx from 'clsx';
import { CdnImage } from '~/shared/image/cdn-image';
import styles from './standard-card.module.scss';
import ProductAttributes from '~/shared/product-attributes/product-attributes.component';
import VariantsList from './variants-list/variants-list.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg';
import ErrorReportButton from '~/page-elements/error-report/error-report-button.component';
import { ProductDocumentList } from '~/shared/product-documents/product-documents.component';
import RelatedProductsList from '~/features/RelatedProductsList';
import VehiclesFits from '~/shared/vehicles-fits/vehicles-fits.component';
import { FITTINGS_TAB } from '~/constants/tabs';
import Text from '~/shared/text/text.component';
import { IProductWithVehicleInfo } from '~/shared/product-list/product-list-rows/product-list-rows.component';
import Splash, { splashType } from '~/shared/splash/splash.component';
import ProductDescription from '~/shared/product-description/product-description.component';
import CopyToClipboard from '~/shared/copy-to-clipboard/copy-to-clipboard.component';
import useUser from '~/libs/use-user';
import { GA4ProductInfoPlp } from '~/libs/ga4';
import { GA4 } from '~/libs/ga4/types/ga4';
import { Products as ProductTypes } from '~/models/products.d';
import Tooltip, { TooltipContentWrapper } from '~/shared/tooltip/tooltip.component';
import { IPricesList } from '~/libs/queries/products/hooks/use-product-prices';
import { IStockList } from '~/libs/queries/products/hooks/use-stock-status';
import { FavoriteStatusListResponse } from '~/libs/queries/favorites';
import { StandardCardPane, useStandardCardContext } from '~/page-elements/product-card/product-card-row/standard-card/context';
import { CarInfoBannerVehicle } from '~/widgets/car-info-banner/use-car-info-banner.hook';

export type StandardCardProps = {
    cardData: IProductWithVehicleInfo;
    position: number;
    setModalContent?: (content: JSX.Element) => void;
    productOEMatches?: ProductTypes.IProductOeMatches;
    infoTexts?: ProductTypes.IProductInfoArticle;

    loadingPrices: boolean;
    prices: IPricesList | undefined;
    errorPrices: boolean;
    stockList?: IStockList;
    loadingStockList: boolean;
    errorStockList: boolean;
    favoriteStatusList?: FavoriteStatusListResponse;
    pageIndex?: number;
    itemNoList: string[];
    vehicle?: CarInfoBannerVehicle;
};

export type StandardCardContentButton = {
    id: number;
    shouldRender: boolean;
    label: string;
    name: string;
    content: StandardCardPane;
    trackingCode: GA4.ProductInfoPlpType;
};

const StandardCard = ({
    cardData,
    position,
    setModalContent,
    productOEMatches,
    infoTexts,
    prices,
    loadingPrices,
    stockList,
    loadingStockList,
    errorPrices,
    errorStockList,
    favoriteStatusList,
    pageIndex,
    itemNoList,
    vehicle,
}: StandardCardProps) => {
    const translate = useTranslations();

    const { isInternalUser } = useUser();
    const { activePane, setActivePane } = useStandardCardContext();

    const contentButtons: StandardCardContentButton[] = [
        {
            id: 2,
            shouldRender: true,
            label: translate('product.variants', 'Varianter'),
            content: 'VariantsList',
            name: '',
            trackingCode: 'Varianter',
        },
        {
            id: 4,
            shouldRender: !!cardData?.documentGroups && cardData.documentGroups.length > 0,
            label: translate('product.furtherInformation', 'Yderligere information'),
            content: 'ProductDocuments',
            name: '',
            trackingCode: 'Yderligere Information',
        },
        {
            id: 6,
            shouldRender: cardData.catalogType === ProductTypes.CatalogType.SparePart || cardData.isSparePart,
            label: translate('product.alsoFits', 'Passer til'),
            content: 'VehicleFits',
            name: FITTINGS_TAB,
            trackingCode: 'Passer også til',
        },
        {
            id: 8,
            shouldRender: !!cardData?.relatedVariantIds && cardData.relatedVariantIds.length > 0,
            label: translate('product.relatedProducts', 'Tilbehørsprodukter'),
            content: 'RelatedProducts',
            name: '',
            trackingCode: 'Tilbehørsprodukter',
        },
    ];

    const productVariants = cardData?.productType === ProductTypes.ProductType.DummyProduct ? [] : cardData?.variants;

    const paneComponent = useMemo(() => {
        switch (activePane) {
            case 'VariantsList':
                return (
                    <VariantsList
                        productOEMatches={productOEMatches}
                        title={cardData?.title}
                        position={position}
                        variants={productVariants}
                        catalogType={cardData.catalogType}
                        relatedVariantIds={cardData.relatedVariantIds}
                        relatedVariantsExtraInfo={cardData.localizedRelatedVariants}
                        loadingItems={cardData.relatedVariantIds?.length}
                        prices={prices}
                        loadingPrices={loadingPrices}
                        stockList={stockList}
                        loadingStockList={loadingStockList}
                        errorStockList={errorStockList}
                        errorPrices={errorPrices}
                        favoriteStatusList={favoriteStatusList}
                        pageIndex={pageIndex}
                        itemNoList={itemNoList}
                    />
                );

            case 'VehicleFits':
                return <VehiclesFits variantsIDs={cardData?.variants?.map((variant) => variant?.itemNo?.toString()) as string[]} />;

            case 'ProductDocuments':
                if (!cardData?.documentGroups) {
                    return null;
                }

                return <ProductDocumentList documents={cardData.documentGroups} />;

            case 'RelatedProducts':
                return (
                    <RelatedProductsList
                        relatedVariantIds={cardData?.relatedVariantIds}
                        relatedVariantsExtraInfo={cardData?.localizedRelatedVariants}
                        loadingItems={cardData?.relatedVariantIds?.length}
                    />
                );

            default:
                return null;
        }
    }, [
        cardData,
        errorPrices,
        errorStockList,
        favoriteStatusList,
        loadingPrices,
        loadingStockList,
        pageIndex,
        activePane,
        position,
        prices,
        productOEMatches,
        productVariants,
        stockList,
    ]);

    const enlargeImage = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        setModalContent(
            <CdnImage loading="eager" src={cardData?.image?.url as string} alt={cardData?.image?.altText} layout="fill" objectFit="contain" />,
        );
    };

    const selectedButton = contentButtons.find((btn) => btn.content === activePane);

    return (
        <div
            className={clsx(styles.root, {
                [styles.rootExpert]: cardData?.isExpertProduct,
            })}
        >
            <div className={styles.splashes}>
                {productOEMatches?.isProductOeMatch && (
                    <Tooltip
                        content={
                            <TooltipContentWrapper>
                                {translate('product.oeTooltip', 'Produktet er ud fra tilgængelig OE data, korrekt for den valgte bil')}
                            </TooltipContentWrapper>
                        }
                        placement="right-end"
                    >
                        <Splash type="OE" />
                    </Tooltip>
                )}
                {cardData?.campaign && (
                    <div>
                        <Splash type={cardData.campaign.code as splashType} />
                    </div>
                )}
            </div>

            <div className={styles.topSection}>
                <div className={styles.imageSection}>
                    {cardData?.image?.url ? (
                        <button aria-label={`Image of ${cardData?.image?.altText}`} type="button" className={styles.nextImage} onClick={enlargeImage}>
                            <CdnImage
                                sizes="300px"
                                width="250"
                                height="140"
                                src={cardData?.image?.url}
                                alt={cardData?.image?.altText}
                                layout="responsive"
                                objectFit="contain"
                            />
                        </button>
                    ) : (
                        <Svg name="placeholder-image" className={styles.svgImage} />
                    )}
                </div>
                <div className={styles.detailsSection}>
                    <div className={styles.titleSection}>
                        <Text tagName="h3" className={styles.title}>
                            {cardData?.title}
                        </Text>
                        {isInternalUser && (
                            <CopyToClipboard textToCopy={cardData?.itemNo} tooltipText={translate('product.copyNNR', 'Kopiér NNR.')}>
                                <Text textStyle="monoSmall">
                                    {translate('product.nnNumber', 'NNR.')} {cardData?.itemNo}
                                </Text>
                            </CopyToClipboard>
                        )}
                    </div>
                    <div className={styles.productDetails}>
                        {cardData?.attributes?.length ? (
                            <div className={styles.detailsGrid}>
                                <ProductAttributes isDark attributes={cardData?.attributes} />
                                <ProductDescription
                                    importantText={cardData?.variants?.[0]?.importantText}
                                    backGroundColor="Primary"
                                    description={cardData?.description}
                                    vehicleInfoText={infoTexts}
                                    vehicleInfoOilText={cardData?.vehicleOilInfoTexts}
                                />
                            </div>
                        ) : (
                            <ProductDescription
                                importantText={cardData?.variants?.[0]?.importantText}
                                backGroundColor="Primary"
                                description={cardData?.description}
                                vehicleInfoText={infoTexts}
                                vehicleInfoOilText={cardData?.vehicleOilInfoTexts}
                            />
                        )}
                    </div>
                    <div className={styles.controls}>
                        <div className={styles.tabsButtons}>
                            {/* <TabsButtons> */}
                            {contentButtons
                                .filter((btn) => btn.shouldRender)
                                .map((btn) => (
                                    <button
                                        className={clsx(styles.tabButton, {
                                            [styles.tabButtonActive]: btn.content === activePane,
                                        })}
                                        id={String(cardData.id)}
                                        key={btn.id}
                                        type="button"
                                        onClick={() => {
                                            setActivePane(btn.content);
                                            GA4ProductInfoPlp(btn.trackingCode);
                                        }}
                                    >
                                        <Text color="primaryColor">{btn.label}</Text>
                                    </button>
                                ))}
                            {/* </TabsButtons> */}
                        </div>
                        <ErrorReportButton
                            data={{
                                itemId: cardData?.itemNo,
                                licensePlateNumber: vehicle?.licensePlate?.number,
                                carId: vehicle?.carId,
                                productVariants: productVariants,
                                product: cardData,
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.accordion}>
                {contentButtons
                    .filter((btn) => btn.shouldRender)
                    .map((btn) => {
                        const selected = btn.content === activePane;

                        return (
                            <button
                                style={{ order: btn.id }}
                                className={clsx(styles.accordionButton, {
                                    [styles.isOpen]: selected,
                                })}
                                key={btn.id}
                                type="button"
                                onClick={() => {
                                    setActivePane(selected ? null : btn.content);
                                }}
                            >
                                <span>
                                    {btn.label}
                                    {productVariants?.length && btn.content === 'VariantsList' && ` (${productVariants.length})`}
                                </span>
                                <Svg name="chevron-right" thick className={styles.accordionIcon} />
                            </button>
                        );
                    })}
                {paneComponent && (
                    <div className={styles.bottomSection} style={{ order: selectedButton?.id || 1 }}>
                        {paneComponent}
                    </div>
                )}
                <ErrorReportButton
                    className={styles.errorReportButtonAccordion}
                    data={{
                        itemId: cardData?.itemNo,
                        licensePlateNumber: vehicle?.licensePlate?.number,
                        carId: vehicle?.carId,
                        productVariants: productVariants,
                        product: cardData,
                    }}
                >
                    <Svg name="chevron-right" thick className={styles.accordionIcon} />
                </ErrorReportButton>
            </div>
        </div>
    );
};

export default StandardCard;
