import React from 'react';

import useTranslations from '~/shared/hooks/use-translations.hook';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import Text from '~/shared/text/text.component';

export type CreditEntryLocationProps = {
    location: string;
};

export function CreditEntryLocation({ location }: CreditEntryLocationProps) {
    const translate = useTranslations();

    return (
        <ValueWithCaption caption={translate('overview.location', 'Lokation')}>
            <Text textStyle="monoMedium">{location}</Text>
        </ValueWithCaption>
    );
}
