import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from '~/features/primitives/modal';
import { addErrorToast, addSuccessToast, useLayoutDispatch } from '~/context/layout.context';
import useChangeEmail from '~/libs/queries/users/hooks/useChangeEmail';
import Button from '~/shared/buttons/button/button.component';
import Input from '~/shared/form-elements/input/input.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Text from '~/shared/text/text.component';
import styles from './ChangeEmail.module.scss';

type FormData = {
    email: string;
    confirmEmail: string;
};

export default function ChangeEmail() {
    const {
        register,
        handleSubmit,
        clearErrors,
        formState: { errors },
        setError: setInputError,
        watch: watchInput,
    } = useForm<FormData>({ mode: 'onBlur' });
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const { mutateAsync: changeEmail, isLoading } = useChangeEmail();

    const emailField = watchInput('email');
    const emailConfirm = watchInput('confirmEmail');

    useEffect(() => {
        if (emailField === emailConfirm) {
            clearErrors('confirmEmail');
            return;
        }

        setInputError('confirmEmail', {
            type: 'manual',
            message: translate('common.fieldMustMatch', 'Felterne skal matche'),
        });
    }, [clearErrors, emailConfirm, emailField, setInputError, translate]);

    const onSubmit = async (formData: FormData) => {
        await changeEmail(
            { newEmail: formData.email },
            {
                onError: () => {
                    dispatch(addErrorToast(translate('common.somethingWentWrong', 'Noget gik galt. Prøv igen.')));
                },
                onSuccess: () => {
                    dispatch(addSuccessToast(translate('userAdministration.changeEmailSuccess', 'Din email blev opdateret.')));
                    setIsModalOpen(false);
                },
            },
        );
    };

    const requiredText = translate('common.fieldMustBeFilled', 'Feltet skal udfyldes');

    return (
        <>
            <Modal
                position="right"
                open={isModalOpen}
                title={translate('userAdministration.changeEmail', 'Skift e-mail')}
                onOpenChange={(open) => setIsModalOpen(open)}
            >
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        type="email"
                        required
                        wrapperClassName={styles.formElement}
                        label={translate('userAdministration.changeEmailLabel', 'Ny e-mail')}
                        placeholder={translate('userAdministration.changeEmailPlaceholder', 'Indtast ny e-mail')}
                        errorMessage={errors?.email?.message}
                        {...register('email', { required: requiredText })}
                    />
                    <Input
                        type="email"
                        required
                        wrapperClassName={styles.formElement}
                        label={translate('userAdministration.changeEmailConfirmLabel', 'Bekræft ny e-mail')}
                        placeholder={translate('userAdministration.changeEmailConfirmPlaceholder', 'Bekræft ny e-mail')}
                        errorMessage={errors?.confirmEmail?.message}
                        {...register('confirmEmail', { required: requiredText })}
                    />
                    <Button className={styles.submitButton} buttonStyle="secondary" fetching={isLoading} type="submit">
                        {translate('common.approve', 'Godkend')}
                    </Button>
                </form>
            </Modal>
            <div className={styles.container}>
                <button className={styles.button} onClick={() => setIsModalOpen(true)}>
                    <Text className={styles.text} fontWeight="bold" color="primaryColor" tagName="span">
                        {translate('userAdministration.changeEmail', 'Skift e-mail')}
                    </Text>
                </button>
            </div>
        </>
    );
}
