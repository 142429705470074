import { create } from 'zustand';

export type CreditModalState = {
    variantId: string | null;
    setVariantId: (variantId: string) => void;
    reset: () => void;
};

export const useCreditModalState = create<CreditModalState>((setState) => ({
    variantId: null,
    setVariantId: (id) =>
        setState((prev) => ({
            ...prev,
            variantId: id,
        })),
    reset: () =>
        setState((prev) => ({
            ...prev,
            variantId: null,
        })),
}));
