import React from 'react';
import { Controller, FieldName, useFormContext } from 'react-hook-form';
import { CreditModalFormFields } from '~/page-elements/credit-modal/types';
import { DEPOSIT_ITEMS_COUNT } from '~/constants/deposit-items-count';
import { getQuantityOptions } from '~/widgets/overview/invoices-widget/invoice-draft/utils';

import useTranslations from '~/shared/hooks/use-translations.hook';
import Tooltip, { TooltipContentWrapper } from '~/shared/tooltip/tooltip.component';
import Select from '~/shared/form-elements/select/select.component';

import styles from './credit-entry-quantity-picker.module.scss';

export type CreditEntryQuantityPickerProps = {
    quantity: number;
    maxQuantity: number;
    disabled?: boolean;
    name?: FieldName<CreditModalFormFields>;
    hasDeposit?: boolean;
    depositOnly?: boolean;
    colliLocked?: boolean;
    colli: number;
};

export function CreditEntryQuantityPicker({
    quantity,
    maxQuantity,
    hasDeposit,
    depositOnly,
    disabled,
    colli,
    colliLocked,
}: CreditEntryQuantityPickerProps) {
    const { setValue, control } = useFormContext<CreditModalFormFields>();
    const translate = useTranslations();

    const options =
        hasDeposit && depositOnly
            ? getQuantityOptions(false, DEPOSIT_ITEMS_COUNT, 0)
            : getQuantityOptions(
                  !!colliLocked,
                  // some items might be returned
                  maxQuantity,
                  colli,
              );

    const handleChangeQuantity = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setValue('entries.0.quantity', Number(e.target.value));
        setValue('entries.0.quantityWarn', false);
    };

    const isInvalid = quantity === 0;

    return (
        <>
            <div className={styles.creditEntryDepositQuantityPicker}>
                {quantity !== maxQuantity && (
                    <Tooltip
                        content={
                            <TooltipContentWrapper>
                                {translate(
                                    'createCredit.numberOfItemsLeftAfterReturns',
                                    'Antal varer du har tilbage efter tidligere retur [quantity]',
                                ).replace('[quantity]', maxQuantity.toString())}
                            </TooltipContentWrapper>
                        }
                    />
                )}
                <Controller
                    name="entries.0.quantity"
                    control={control}
                    rules={{
                        required: true,
                        validate: {
                            isNotZero: (value) => value > 0,
                        },
                    }}
                    render={({ field: { onChange, ...field } }) => (
                        <Select
                            {...field}
                            onChange={handleChangeQuantity}
                            disabled={disabled}
                            wrapperClassName={styles.creditEntryDepositQuantityPickerSelect}
                            label={translate('overview.number', 'antal')}
                            errorMessage={isInvalid ? translate('overview.selectQuantity', 'Vælg Antal') : undefined}
                        >
                            {(isInvalid || (depositOnly && hasDeposit)) && <option value={0}>-</option>}
                            {options.map((v) => (
                                <option value={v} key={v}>
                                    {v}
                                </option>
                            ))}
                        </Select>
                    )}
                />
            </div>
        </>
    );
}
