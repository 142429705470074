import { useVehicleDetails } from '~/libs/queries/vehicles/hooks/use-vehicle-details';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import { Bff } from '~/models/bff';
import { Users } from '~/models/users';
import { Vehicles } from '~/models/vehicles';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import PageTitle from '~/shared/page-title/page-title.component';
import EngineReplaceForm from './car-replace-engine-form/car-replace-engine-form.component';

// Old component: car-replace-engine.component.tsx
export default function W081CarReplaceEngine({ licensePlate, carId, vin }: Bff.IBaseCarDetailsWidget) {
    const translate = useTranslations();

    const { activeProfile, isLoading: isLoadingUser, error: userError } = useActiveUser();

    const { data: vehicleDetails, isLoading: isLoadingVehicle, error: vehicleError } = useVehicleDetails(licensePlate, carId, vin);

    const isLoading = isLoadingUser || isLoadingVehicle;
    const error = vehicleError || userError;

    return (
        <>
            <PageTitle>
                <Heading tagName="h1" displayStyle="h3">
                    {' '}
                    {translate('replaceEngine.engineExchange', 'Ombytning af motor')}
                </Heading>
            </PageTitle>
            {isLoading && <Loader padding="50px" />}
            {!isLoading && !error && (
                <EngineReplaceForm
                    activeProfile={activeProfile as Users.IBasicUserInfo}
                    vehicleDetails={vehicleDetails as Vehicles.IVehicleDetails}
                />
            )}
        </>
    );
}
