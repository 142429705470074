import React from 'react';

import { Products } from '~/models/products';

import BrandLogo from '~/shared/brand-logo/brand-logo.component';
import Text from '~/shared/text/text.component';

import styles from './credit-entry-title.module.scss';

export type CreditEntryTitleProps = {
    title: string;
    brand?: Products.IBrand;
};

export function CreditEntryTitle({ title, brand }: CreditEntryTitleProps) {
    return (
        <div className={styles.creditEntryTitle}>
            <BrandLogo brand={brand} />
            <Text textStyle="body" fontWeight="regular">
                {title}
            </Text>
        </div>
    );
}
