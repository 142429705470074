import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useCallback, useState } from 'react';
import { EasingFramer, TimingFramer } from '~/constants/animation';
import { IWithClassName } from '~/models/dev';
import { Orders as OrderTypes } from '~/models/orders.d';
import { Products as ProductTypes } from '~/models/products.d';
import CreateCredit from '~/shared/create-credit/create-credit.component';
import { MediaQuery, useMediaQueryDetect } from '~/shared/media-query-detect';
import { Modal } from '~/features/primitives/modal';
import { Svg } from '~/shared/svg';
import Button from '../buttons/button/button.component';
import useTranslations from '../hooks/use-translations.hook';
import styles from './previous-orders.module.scss';
import { useProductPrices } from '~/libs/queries/products/hooks/use-product-prices';
import { ICreateCreditProps } from '~/shared/create-credit/create-credit';
import { OrderHistoryRecord } from '~/page-elements/order-history/order-history';

interface IPreviousOrdersProps extends IWithClassName {
    invoices?: OrderTypes.IVariantInvoiceDetails[];
    productItem?: ProductTypes.IVariant;
}

const PreviousOrders: FC<IPreviousOrdersProps> = ({ className, productItem, invoices = [] }) => {
    const translate = useTranslations();
    const invoicesLength = invoices?.length;
    const [selectedItem, setSelectedItem] = useState<ICreateCreditProps | null>(null);
    const { data: prices } = useProductPrices([
        {
            articleId: productItem?.itemNo,
            quantity: productItem?.colli || 1,
        },
    ]);
    const price = prices ? prices[productItem?.itemNo as string] : undefined;

    const isPortable = useMediaQueryDetect(MediaQuery.LG);
    const containerHeight = isPortable ? 350 : 200;

    const itemsVisible = isPortable ? 4 : 3;

    const isCollapsible = invoicesLength > itemsVisible + 1;
    const [isCollapsed, setCollapsed] = useState(isCollapsible);

    const handleModalOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setSelectedItem(null);
    }, []);

    return (
        <div className={className}>
            <Modal
                position="right"
                open={!!selectedItem}
                onOpenChange={handleModalOpenChange}
                title={translate('overview.createCredit', 'Opret kreditering')}
            >
                {selectedItem?.items ? (
                    <CreateCredit
                        items={selectedItem.items}
                        invoiceDate={selectedItem.invoiceDate}
                        invoiceId={selectedItem.invoiceId}
                        onClose={() => setSelectedItem(null)}
                        showPdpButton
                    />
                ) : null}
            </Modal>
            <motion.div
                initial={{ height: isCollapsed ? containerHeight : 'auto' }}
                animate={{ height: isCollapsed ? containerHeight : 'auto' }}
                transition={{
                    duration: TimingFramer.STANDARD,
                    ease: EasingFramer.BOUNCE_IN,
                }}
                className={clsx(styles.tableWrapper, {
                    [styles.tableWrapperCollapsed]: isCollapsed,
                })}
            >
                <div>
                    {invoices?.map((row, i) => {
                        const alreadyReturned = row.possibleToCreditQuantity === 0 && row.itemQuantity !== 0;

                        return (
                            <OrderHistoryRecord
                                key={row.invoiceId}
                                car={row.carInfo}
                                date={row.invoiceDateFormatted}
                                quantity={row.itemQuantity}
                                invoiceUrl={row.invoiceDetailsUrl}
                                invoiceId={row.invoiceId}
                            >
                                <Button
                                    disabled={alreadyReturned}
                                    icon={alreadyReturned ? null : <Svg name="return-alt" />}
                                    buttonStyle="clean"
                                    buttonSize={alreadyReturned ? 'default' : 'small'}
                                    type="button"
                                    onClick={() => {
                                        setSelectedItem({
                                            carInfo: row.carInfo,
                                            invoiceDate: row.invoiceDateFormatted as string,
                                            invoiceId: row.invoiceId,
                                            items: [
                                                {
                                                    ...row,
                                                    // overwrites the inconsistent types
                                                    quantity: row.itemQuantity,
                                                    creditedQuantity: row.itemCreditedQuantity,

                                                    brand: productItem?.brand,
                                                    description: productItem?.description,
                                                    itemId: productItem?.itemNo,
                                                    image: productItem?.images?.[0] as OrderTypes.IImage | undefined,
                                                    title: productItem?.title,
                                                    colli: productItem?.colli as number,
                                                    colliLocked: !!productItem?.colliLocked,
                                                    grossFormatted: price?.actualPrice?.displayPrice,
                                                    grossUnitFormatted: productItem?.unit,
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    //@ts-ignore
                                                    hasDeposit: price.actualPrice.deposit > 0,
                                                    url: productItem?.url,
                                                    isDepositProduct: false,
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    {alreadyReturned ? translate('overview.credited', 'Krediteret') : translate('overview.return', 'Returnér')}
                                </Button>
                            </OrderHistoryRecord>
                        );
                    })}
                </div>
            </motion.div>
            <AnimatePresence initial={false}>
                {isCollapsed && (
                    <motion.button
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                            duration: TimingFramer.SLOW,
                            ease: EasingFramer.BOUNCE_IN,
                        }}
                        type="button"
                        onClick={() => setCollapsed(false)}
                        className={styles.collapseButton}
                    >
                        {translate('common.showAll', 'Vis alle')} ({invoicesLength})
                    </motion.button>
                )}
            </AnimatePresence>
        </div>
    );
};

export default PreviousOrders;
