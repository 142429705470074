import { Orders } from '~/models/orders';
import { InvoiceDraftEntry } from '~/widgets/overview/invoices-widget/invoice-draft/invoice-draft';
import { updateOrInsertEntries } from '~/page-elements/credit-modal/utils';

// Deposit means deposit only. Total includes all other products.
// We want to differentiate to be able to have separate entries of the same product,
// depending on if it's only the deposit that is credited, or if it is the total amount.
export type UniqueIdDepositType = 'deposit' | 'total';

export function generateUniqueId(itemNo: string, invoiceId: number, depositType: UniqueIdDepositType, carId?: string) {
    return JSON.stringify({ itemNo, invoiceId, depositType, carId });
}

export type ParsedUniqueId = { itemNo: string; invoiceId: number; depositType: UniqueIdDepositType; carId?: string };

export function parseUniqueId(uniqueId: string): ParsedUniqueId {
    return JSON.parse(uniqueId);
}

export function generateUniqueIdFromInvoice(itemNo: string, invoice: Orders.IVariantInvoiceDetails, depositType: UniqueIdDepositType) {
    return generateUniqueId(itemNo, invoice.invoiceId, depositType, invoice.carInfo?.carId);
}

export function findActiveInvoiceByInvoiceId(invoiceId: number, invoices: Orders.IVariantInvoiceDetails[]) {
    return invoices.find((invoice) => invoice.invoiceId === invoiceId);
}

export type FindEntryByUniqueIdFilter = false | 'exclude_deposit_type';

export function getEntryByUniqueId(uniqueId: string, filter: FindEntryByUniqueIdFilter = false) {
    return function (item: InvoiceDraftEntry) {
        if (!filter) {
            return item.uniqueId === uniqueId;
        }

        // We parse the unique id so we can compare the individual values, omitting depositType.
        const parsedUniqueId = parseUniqueId(uniqueId);

        return parsedUniqueId.itemNo === item.itemNo && parsedUniqueId.carId === item.carId && parsedUniqueId.invoiceId === item.invoiceId;
    };
}

export function findEntryByUniqueId(entries: InvoiceDraftEntry[], uniqueId: string, filter: FindEntryByUniqueIdFilter = false) {
    return entries.find(getEntryByUniqueId(uniqueId, filter));
}

export function findEntriesByPartialUniqueId(entries: InvoiceDraftEntry[], uniqueId: string) {
    return entries.filter(getEntryByUniqueId(uniqueId, 'exclude_deposit_type'));
}

export const getQuantityOptions = (isColliLocked: boolean, quantity = 1, colli = 1) => {
    if (isColliLocked) {
        if (colli >= quantity) return [quantity];
        if (colli === 0) return Array.from(Array(quantity).keys()).map((i) => i + 1);

        return Array.from(Array(quantity / colli).keys())
            .map((i) => i + 1)
            .map((q) => q * colli);
    }

    if (colli === 0) {
        return Array.from(Array(quantity).keys()).map((i) => i + 1);
    }

    return Array.from(Array(quantity).keys()).map((i) => i + 1);
};

export function getUpdatedEntries(existingEntries: InvoiceDraftEntry[], updatedStoredEntry: InvoiceDraftEntry, possibleToCreditQuantity: number) {
    // Find entries in the previous state that match a partial unique ID
    const entriesByPartialUniqueId = findEntriesByPartialUniqueId(existingEntries, updatedStoredEntry.uniqueId);

    // Find an entry among the matched entries whose unique ID doesn't match the updated entry's unique ID
    let otherEntryByPartialUniqueId = entriesByPartialUniqueId.find((item) => item.uniqueId !== updatedStoredEntry.uniqueId);

    // Extract necessary properties from the invoice and the updated stored entry
    const { quantity: updatedStoredEntryQuantity } = updatedStoredEntry;

    // Calculate the total quantity of all matched entries
    const totalCreditQuantity = [updatedStoredEntry, ...entriesByPartialUniqueId].reduce((prev, curr) => {
        return prev + curr.quantity;
    }, 0);

    // If the total credit quantity exceeds or equals the possible credit quantity and there's another entry found, adjust its quantity
    if (totalCreditQuantity >= possibleToCreditQuantity && otherEntryByPartialUniqueId) {
        otherEntryByPartialUniqueId = {
            ...otherEntryByPartialUniqueId,
            quantity: possibleToCreditQuantity - updatedStoredEntryQuantity,
        };
    }

    if (!otherEntryByPartialUniqueId) {
        // If no other entry is found, update or insert the updated stored entry
        return updateOrInsertEntries(existingEntries, [updatedStoredEntry]);
    } else if (updatedStoredEntryQuantity === possibleToCreditQuantity) {
        // If the updated stored entry quantity equals the possible credit quantity,
        // replace the other entry with the updated stored entry
        return updateOrInsertEntries(
            existingEntries.filter((item) => item.uniqueId !== otherEntryByPartialUniqueId?.uniqueId),
            [updatedStoredEntry],
        );
    } else {
        // Otherwise, update or insert both the updated stored entry and the other entry
        return updateOrInsertEntries(existingEntries, [updatedStoredEntry, otherEntryByPartialUniqueId]);
    }
}
