import Link from 'next/link';
import { useCallback } from 'react';
import { GA4CampaignClicked } from '~/libs/ga4';
import { Bff } from '~/models/bff';
import { CdnImage } from '~/shared/image/cdn-image';
import Text from '~/shared/text/text.component';
import styles from './styled.module.scss';

export type USPListProps = {
    list: Bff.IUspViewModel[];
    onItemClick?: (url?: string) => void;
};

export function USPList({ list, onItemClick }: USPListProps) {
    return (
        <ul className={styles.uspList}>
            {list.map((item, index) => (
                <div key={`${item.text}-${index}`}>
                    {item?.callToAction?.url ? (
                        <li className={styles.itemWrapper}>
                            <Link href={item.callToAction.url} passHref>
                                <a
                                    className={styles.itemContent}
                                    target={item.callToAction?.target}
                                    onClick={() => onItemClick?.(item.callToAction?.url)}
                                >
                                    {item.image?.src ? (
                                        <CdnImage
                                            layout="fixed"
                                            objectFit="contain"
                                            src={item?.image?.src}
                                            alt={item?.image?.alt}
                                            width={24}
                                            height={24}
                                            draggable={false}
                                        />
                                    ) : null}
                                </a>
                            </Link>
                            <Text color="primaryColor" textAlign="center">
                                {item.text}
                            </Text>
                        </li>
                    ) : (
                        <li className={styles.itemWrapper}>
                            {item?.image?.src ? (
                                <CdnImage
                                    layout="fixed"
                                    objectFit="contain"
                                    src={item?.image?.src}
                                    alt={item?.image?.alt}
                                    width={24}
                                    height={24}
                                    draggable={false}
                                />
                            ) : null}
                            <Text color="primaryColor" textAlign="center">
                                {item.text}
                            </Text>
                        </li>
                    )}
                </div>
            ))}
        </ul>
    );
}

export default function F010USPModule({ uspList = [] }: Bff.IF010UspModuleViewModel) {
    return <USPList list={uspList} />;
}

export function M010USPModule({ type, id, campaignSpot, uspList = [] }: Bff.IM060UspModuleViewModel) {
    const handleClick = useCallback(
        (to = '') => {
            GA4CampaignClicked({
                to,
                campaignSpot,
                campaignType: type,
                campaignId: id,
            });
        },
        [campaignSpot, id, type],
    );

    return <USPList onItemClick={handleClick} list={uspList} />;
}
