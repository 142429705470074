import { useEffect, useState } from 'react';
import { addErrorToast, addSuccessToast, useLayoutDispatch } from '~/context/layout.context';
import { useForm } from 'react-hook-form';
import { Modal } from '~/features/primitives/modal';
import useChangePassword from '~/libs/queries/users/hooks/useChangePassword';
import Button from '~/shared/buttons/button/button.component';
import Input from '~/shared/form-elements/input/input.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Text from '~/shared/text/text.component';
import styles from './ChangePassword.module.scss';

type FormData = {
    password: string;
    newPassword: string;
    confirmNewPassword: string;
};

export default function ChangePassword() {
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();

    const {
        register,
        handleSubmit,
        clearErrors,
        formState: { errors },
        setError: setInputError,
        watch: watchInput,
    } = useForm<FormData>({ mode: 'onSubmit' });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const { mutateAsync: changePassword, isLoading } = useChangePassword();

    const newPasswordField = watchInput('newPassword');
    const confirmNewPasswordField = watchInput('confirmNewPassword');

    useEffect(() => {
        if (newPasswordField === confirmNewPasswordField) {
            clearErrors('confirmNewPassword');
            return;
        }

        setInputError('confirmNewPassword', {
            type: 'manual',
            message: translate('common.fieldMustMatch', 'Felterne skal matche'),
        });
    }, [clearErrors, confirmNewPasswordField, newPasswordField, setInputError, translate]);

    const onSubmit = async (formData: FormData) => {
        await changePassword(
            { newPassword: formData.newPassword },
            {
                onError: () => {
                    dispatch(addErrorToast(translate('common.somethingWentWrong', 'Noget gik galt. Prøv igen.')));
                },
                onSuccess: () => {
                    dispatch(addSuccessToast(translate('userAdministration.changePasswordSuccess', 'Dit password blev opdateret.')));
                    setIsModalOpen(false);
                },
            },
        );
    };

    const requiredText = translate('common.fieldMustBeFilled', 'Feltet skal udfyldes');

    return (
        <>
            <Modal
                position="right"
                open={isModalOpen}
                title={translate('userAdministration.changePassword', 'Skift adgangskode')}
                onOpenChange={(open) => setIsModalOpen(open)}
            >
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        type="password"
                        required
                        wrapperClassName={styles.formElement}
                        label={translate('userAdministration.changePasswordLabel', 'Ny adgangskode (MIN. 6 tegn)')}
                        errorMessage={errors?.newPassword?.message}
                        {...register('newPassword', { required: requiredText })}
                    />
                    <Input
                        type="password"
                        required
                        wrapperClassName={styles.formElement}
                        label={translate('userAdministration.changePasswordConfirmLabel', 'Bekræft ny adgangskode')}
                        errorMessage={errors?.confirmNewPassword?.message}
                        {...register('confirmNewPassword', { required: requiredText })}
                    />
                    <Button className={styles.submitButton} buttonStyle="secondary" fetching={isLoading} type="submit">
                        {translate('common.approve', 'Godkend')}
                    </Button>
                </form>
            </Modal>
            <div className={styles.container}>
                <button className={styles.button} onClick={() => setIsModalOpen(true)}>
                    <Text className={styles.text} fontWeight="bold" color="primaryColor" tagName="span">
                        {translate('userAdministration.changePassword', 'Skift adgangskode')}
                    </Text>
                </button>
            </div>
        </>
    );
}
