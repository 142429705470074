import React, { FC } from 'react';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import ErrorBox from '~/shared/error-box/error-box';
import { Products as ProductTypes } from '~/models/products.d';
import useUser from '~/libs/use-user';
import styles from '../details.module.scss';
import Text from '~/shared/text/text.component';
import StatusDot from '../../status-dot/status-dot.component';
import { StockBadge } from '~/shared/stock-status/stock-status.component';
import { useStockDeliveryTime } from '~/shared/stock-status/hooks';

export type StockDetailsTableRowProps = {
    details: ProductTypes.IStockInfo;
    // Display row as internal user
    withDepartmentId?: boolean;
    // Display location for row
    withLocation?: boolean;
    withAltStock?: boolean;
};

export function StockDetailsTableRow({ details, withDepartmentId = false, withLocation = false, withAltStock = false }: StockDetailsTableRowProps) {
    const stockDeliveryTimeLabel = useStockDeliveryTime({
        time: details?.deliveryTime ? String(details.deliveryTime) : undefined,
        fallback: false,
    });

    return (
        <tr>
            <td colSpan={withAltStock && !stockDeliveryTimeLabel ? 2 : undefined}>
                <Text textStyle="monoSmall" className={styles.department}>
                    <StatusDot statusCode={details?.stockStatusCode} className={styles.statusDot} />
                    {withDepartmentId && <span className={styles.departmentId}>{details.departmentId}</span>}
                    {details.department}
                </Text>
            </td>
            {stockDeliveryTimeLabel ? (
                <td>
                    <StockBadge className={styles.deliveryTime} status={details.stockStatusCode} disabled={true}>
                        {stockDeliveryTimeLabel}
                    </StockBadge>
                </td>
            ) : null}
            {withLocation ? (
                <td>
                    <Text textStyle="monoSmall">{details?.articleLocations?.length ? details.articleLocations.join(', ') : null}</Text>
                </td>
            ) : null}
            <td>
                <Text textStyle="monoSmall">{details.quantity}</Text>
            </td>
        </tr>
    );
}

interface IDetailsTableProps {
    details?: ProductTypes.IStockInfo[];
    fetching?: boolean;
    isError?: Error | null;
    isExternal?: boolean;
}

const DetailsTable: FC<IDetailsTableProps> = ({ details, fetching, isError, isExternal }) => {
    const translate = useTranslations();
    const { isInternalUser } = useUser();

    const withLocation = details?.some((d) => d.articleLocations);
    const withAltStockBadge = details?.some((item) => item.deliveryTime);

    if (fetching) return <Loader padding="100px 20px" />;

    if (isError) {
        return <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>;
    }

    const noResultsText = isExternal
        ? translate('stockStatus.noResultsExternal', 'Ingen varer på eksternt lager i øjeblikket. Kontakt FTZ for leveringstid.')
        : translate('stockStatus.noResultsInternal', 'Ingen FTZ afdelinger har varen på lager i øjeblikket. Kontakt FTZ for leveringstid.');

    if (!details?.length) {
        return <Text className={styles.noResults}>{noResultsText}</Text>;
    }

    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th colSpan={withAltStockBadge ? 2 : undefined}>{translate('stockStatus.department', 'department')}</th>
                    {withLocation && <th>{translate('stockStatus.location', 'lokation')}</th>}
                    <th>{translate('stockStatus.quantity', 'quantity')}</th>
                </tr>
            </thead>
            <tbody>
                {details?.map((row) => (
                    <StockDetailsTableRow
                        key={row.departmentId}
                        details={row}
                        withDepartmentId={isInternalUser}
                        withLocation={withLocation}
                        withAltStock={withAltStockBadge}
                    />
                ))}
            </tbody>
        </table>
    );
};

export default DetailsTable;
