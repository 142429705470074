import { useEffect } from 'react';
import { GA4ViewListItems } from '~/libs/ga4';
import { useProductPrices } from '~/libs/queries/products/hooks/use-product-prices';
import useUser from '~/libs/use-user';
import { Products } from '~/models/products.d';

type Props = {
    products: Products.IVariantLight[] | undefined;
    prices: ReturnType<typeof useProductPrices>['data'];
};

export default function TrackRelatedProductsList({ products, prices }: Props) {
    const { profile } = useUser();
    useEffect(() => {
        if (!products || !prices) return;
        GA4ViewListItems(
            products.map((v) => {
                return {
                    ...v,
                    price: prices?.[v.ftzCode as string]?.actualPrice,
                };
            }),
            { currency: profile?.currency as string, listName: 'accessory_products' },
        );
    }, [products, prices]);

    return null;
}
