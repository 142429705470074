import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { Products as ProductTypes } from '~/models/products.d';
import useUser from '~/libs/use-user';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import { apiClient } from '~/services/api-client';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';

const useDepositProducts = () => {
    const { activeProfile } = useActiveUser();
    const { products, createUrl } = useEndpoints();

    const endpoint = createUrl({
        endpoint: products.deposits,
        localeOption: LocaleOptions.path,
    });

    const { isLoggedIn, user } = useUser();
    const { locale } = useRouter();

    return useQuery<ProductTypes.IVariantLightPrice[], Error>({
        queryKey: queryKeys.products.depositProductsService(locale, activeProfile?.id),
        queryFn: async () => {
            return apiClient.auth(user).get(endpoint);
        },
        enabled: isLoggedIn,
    });
};

export default useDepositProducts;
