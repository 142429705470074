import { useRouter } from 'next/router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { addErrorToast, addSuccessToast, useLayoutDispatch } from '~/context/layout.context';
import { getFormData } from '~/helpers';
import { useDeliveryAddresses } from '~/libs/queries/basket/hooks/use-delivery-addresses';
import useUser from '~/libs/use-user';
import { CarReplaceEngineRequest } from '~/services/product/car-replace-engine-request.service';
import Button from '~/shared/buttons/button/button.component';
import Checkbox from '~/shared/form-elements/checkbox/checkbox.component';
import Label from '~/shared/form-elements/common/label/label.component';
import ValidationErrorMessage from '~/shared/form-elements/common/validation-error-message/validation-error-message.component';
import FileInput from '~/shared/form-elements/file-input/file-input.component';
import Input from '~/shared/form-elements/input/input.component';
import Select from '~/shared/form-elements/select/select.component';
import TextArea from '~/shared/form-elements/text-area/text-area.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Text from '~/shared/text/text.component';
import { IEngineReplaceFormData, IEngineReplaceProps } from '../types';
import styles from './styled.module.scss';

const EngineReplaceForm = ({ vehicleDetails, activeProfile }: IEngineReplaceProps) => {
    const [fetching, setFetching] = useState(false);

    const { locale } = useRouter();
    const dispatch = useLayoutDispatch();
    const { user } = useUser();

    const { data: deliveryAddresses = [] } = useDeliveryAddresses();

    const {
        userName,
        email,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        customer: { customerId, address = '', name: companyName = '', zipCodeAndCity = '', belongsToDepartmentId: department = '', phoneNumber = '' },
    } = activeProfile || {};

    const addresses = [`${address}, ${zipCodeAndCity}`, ...deliveryAddresses?.map((da) => da.displayAddress)];

    const { licensePlate, vin, engineCode, manufacturerName, modelName, modelTypeId, modelTypeName, typeYearTo, typeYearFrom } = vehicleDetails || {};
    const translate = useTranslations();
    const acceptedFiles = ['.jpg', '.png', '.bmp', '.pdf', '.xls', '.xlsx', '.doc', '.docx', '.gif', '.zip', '.rar'];
    const [files, setFiles] = useState<File[]>([]);

    const maxFilesSizeForEmail = files.map((file) => file.size).reduce((acc, cur) => acc + cur, 0);
    const emailSizeWarning = maxFilesSizeForEmail >= 25 * 1024 * 1024; /* 25mb limit*/

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm<IEngineReplaceFormData>();

    const onSubmit = async (postData: IEngineReplaceFormData) => {
        if (emailSizeWarning) return;

        if (!postData.RegNr && !postData.ChassisNumber) {
            setError('regNrOrVin', {
                type: 'manual',
                message: translate('sharedFormTranslations.regNumberOrVin', 'Udfyld enten Stelnr. eller Reg. Nr'),
            });
            return;
        }
        setFetching(true);

        const formData = getFormData(postData);
        files.forEach((file) => formData.append('Files', file));

        try {
            const { errorMessage } = await CarReplaceEngineRequest(formData, locale as string, user);
            if (!errorMessage) {
                dispatch(addSuccessToast(translate('common.successfullyCreated', 'Oprettet med succes')));
                setFetching(false);
                window.setTimeout(() => {
                    window.close();
                }, 3000);
            } else {
                dispatch(addErrorToast(errorMessage));
            }
        } catch (error) {
            dispatch(addErrorToast(translate('common.somethingWentWrong', 'Der gik noget galt.')));
        } finally {
            setFetching(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.formGrid}>
                <fieldset className={styles.descriptionWrapper}>
                    <legend>{translate('common.descriptionCapital', 'Beskrivelse')}</legend>
                    <TextArea
                        className={styles.description}
                        label={translate('common.description', 'beskrivelse')}
                        {...register('Description', {
                            maxLength: {
                                value: 500,
                                message: translate('sharedFormTranslations.maxCharacters', 'Max 500'),
                            },
                        })}
                    />
                    {errors?.Description?.message && <ValidationErrorMessage>{errors?.Description?.message}</ValidationErrorMessage>}
                </fieldset>

                <fieldset className={styles.uploadCol}>
                    <legend>{translate('sharedFormTranslations.uploadFiles', 'Upload filer')}</legend>
                    <Text textStyle="bodySmall">
                        {translate(
                            'sharedFormTranslations.uploadFileLimitation',
                            'Max 3 stk. i format .jpg, .png, .bmp, .pdf, .xls, .xlsx, .doc, .docx eller .gif. Må ikke fylde mere end 25 MB hver.',
                        )}
                    </Text>
                    <FileInput
                        files={files}
                        errorMessage={
                            emailSizeWarning
                                ? translate(
                                      'sharedFormTranslations.emailSizeWaring',
                                      'Max samlede filstørrelse for upload er 25 mb. Vælg mindre filstørrelse og prøv igen.',
                                  )
                                : null
                        }
                        accept={acceptedFiles.join(', ')}
                        acceptedFileExtensions={acceptedFiles}
                        maxFileSize={25 * 1024 * 1024 /* 25mb limit per file*/}
                        onFilesChange={setFiles}
                    />
                </fieldset>
                <fieldset className={styles.inputGroup}>
                    <legend> {translate('common.myInformation', 'Mine oplysninger')}</legend>
                    <div className={styles.formGrid}>
                        <div className={styles.infoCol}>
                            <Input
                                {...register('Contact')}
                                wrapperClassName={styles.textInput}
                                defaultValue={userName}
                                label={translate('customer.contactPerson', 'Kontaktperson')}
                            />
                            <Label required>{translate('customer.email', 'E-mail')}</Label>
                            <Input
                                required
                                aria-label="email"
                                {...register('Email', { required: true })}
                                type="email"
                                wrapperClassName={styles.textInput}
                                defaultValue={email}
                                errorMessage={errors?.Email && errors?.Email?.message}
                            />
                            <Input
                                {...register('CustomerNumber')}
                                wrapperClassName={styles.textInput}
                                readOnly
                                label={translate('customer.customerId', 'Kunde nr')}
                                defaultValue={customerId}
                            />
                        </div>
                        <div className={styles.infoCol}>
                            <Input
                                {...register('CustomerName')}
                                wrapperClassName={styles.textInput}
                                defaultValue={companyName}
                                readOnly
                                label={translate('customer.companyName', 'Firmanavn')}
                            />
                            <div className={styles.textInput}>
                                <Select {...register('Address')} label={translate('customer.address', 'Adresse')}>
                                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                                    {/*@ts-ignore*/}
                                    {addresses?.map((addr: string) => (
                                        <option value={addr} key={addr}>
                                            {addr}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                            <Input
                                {...register('ZipCodeAndCity')}
                                wrapperClassName={styles.textInput}
                                defaultValue={zipCodeAndCity}
                                readOnly
                                label={translate('customer.postNumberAndCity', 'Post.nr&by')}
                            />
                        </div>
                        <div className={styles.infoCol}>
                            <Input
                                {...register('DepartmentId')}
                                wrapperClassName={styles.textInput}
                                defaultValue={department}
                                readOnly
                                label={translate('common.department', 'afdeling')}
                            />
                            <Input
                                {...register('PhoneNumber')}
                                wrapperClassName={styles.textInput}
                                defaultValue={phoneNumber}
                                label={translate('customer.phone', 'Telefon')}
                            />
                        </div>
                    </div>
                </fieldset>
                <fieldset className={styles.inputGroup}>
                    <legend>{translate('common.caseInformation', 'Sagsoplysninger')}</legend>
                    <div className={styles.formGrid}>
                        <div className={styles.infoCol}>
                            <Label required>{translate('sharedFormTranslations.carInformation', 'Bilinformation')}</Label>
                            <div className={styles.inputBox}>
                                <Input
                                    {...register('RegNr')}
                                    wrapperClassName={styles.textInput}
                                    defaultValue={licensePlate?.number}
                                    label={translate('vehicle.regNr', 'Reg nr.')}
                                    onChange={() => clearErrors('regNrOrVin')}
                                />
                                <Input
                                    {...register('ChassisNumber')}
                                    wrapperClassName={styles.textInput}
                                    defaultValue={vin}
                                    label={translate('vehicle.chassisNumber', 'Stelnummer')}
                                    onChange={() => clearErrors('regNrOrVin')}
                                />
                                {errors?.regNrOrVin && <ValidationErrorMessage>{errors?.regNrOrVin.message}</ValidationErrorMessage>}
                            </div>
                            <Input
                                {...register('EngineType')}
                                defaultValue={engineCode}
                                wrapperClassName={styles.textInput}
                                label={translate('vehicle.engineCode', 'Motorkode').concat(' ', translate('common.shouldBeChecked', '(Bør Tjekkes)'))}
                            />
                        </div>
                        <div className={styles.infoCol}>
                            <Input
                                {...register('ModelName')}
                                defaultValue={modelName}
                                readOnly
                                wrapperClassName={styles.textInput}
                                label={translate('vehicle.modelName', 'Model')}
                            />
                            <div className={styles.dateRangeGrid}>
                                <Input
                                    {...register('TypeYearFrom')}
                                    defaultValue={typeYearFrom}
                                    readOnly
                                    wrapperClassName={styles.textInput}
                                    label={translate('vehicle.yearFrom', 'Fra årgang')}
                                />
                                <Input
                                    {...register('TypeYearTo')}
                                    defaultValue={typeYearTo}
                                    readOnly
                                    wrapperClassName={styles.textInput}
                                    label={translate('vehicle.yearTo', 'Til årgang')}
                                />
                            </div>
                            <Input
                                {...register('ModelTypeName')}
                                defaultValue={modelTypeName}
                                readOnly
                                wrapperClassName={styles.textInput}
                                label={translate('vehicle.type', 'Type')}
                            />
                        </div>
                        <div className={styles.infoCol}>
                            <Input
                                {...register('ModelTypeId')}
                                readOnly
                                defaultValue={modelTypeId}
                                wrapperClassName={styles.textInput}
                                label={translate('vehicle.modelTypeId', 'FTZ Biltype nr')}
                            />
                            <Input
                                {...register('ManufacturerName')}
                                defaultValue={manufacturerName}
                                readOnly
                                wrapperClassName={styles.textInput}
                                label={translate('vehicle.manufacturerName', 'Mærke')}
                            />
                        </div>
                    </div>
                    <Checkbox {...register('IncludeUserInCc')} className={styles.checkbox}>
                        {translate(
                            'sharedFormTranslations.includeUserInCcCheckBox',
                            'Jeg ønsker selv at modtage en kopi af mailen (Mailen bliver sendt til den mail som du har udfyldt i "E-MAIL" filtet)',
                        )}
                    </Checkbox>
                    {errors?.Email && <ValidationErrorMessage>{errors.Email?.message}</ValidationErrorMessage>}

                    {errors?.regNrOrVin && <ValidationErrorMessage>{errors?.regNrOrVin.message}</ValidationErrorMessage>}

                    {emailSizeWarning && (
                        <ValidationErrorMessage>
                            {translate(
                                'sharedFormTranslations.emailSizeWaring',
                                'Max samlede filstørrelse for upload er 25 mb. Vælg mindre filstørrelse og prøv igen.',
                            )}
                        </ValidationErrorMessage>
                    )}

                    <div className={styles.infoCol}>
                        <Button type="submit" className={styles.submitButton} fetching={fetching}>
                            {translate('common.sendRequest', 'Send forespørgsel')}
                        </Button>
                    </div>
                </fieldset>
            </div>
        </form>
    );
};

export default EngineReplaceForm;
