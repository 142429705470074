import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import useUser from '~/libs/use-user';
import { Bff } from '~/models/bff';
import getProductsInfosVariant from '~/services/product/getProductsInfosVariant';
import useCarInfoBanner from '~/widgets/car-info-banner/use-car-info-banner.hook';
import { useCMSPage } from '../../bff';
import { queryKeys } from '../../query-keys';

type Body = Parameters<typeof getProductsInfosVariant>[0]['body'];
type Args = Pick<Body, 'ftzCodes'>;

export default function useProductInfosVariant(args: Args) {
    const { user } = useUser();
    const { locale } = useRouter();
    const { data: pageContent } = useCMSPage();
    const { carInfoBannerData } = useCarInfoBanner(pageContent as Bff.IPageContentViewModel);

    const body: Body = {
        carModelTypeId: carInfoBannerData?.vehicle.carId,
        ftzCodes: args.ftzCodes,
    };

    return useQuery({
        queryKey: queryKeys.products.infosVariant({
            locale,
            user,
            body,
        }),
        enabled: !!carInfoBannerData?.vehicle.carId,
        queryFn: ({ signal }) => getProductsInfosVariant({ locale, user, body, signal }),
    });
}
