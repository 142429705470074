import { InvoiceDraftEntry } from '~/widgets/overview/invoices-widget/invoice-draft/invoice-draft';

export type ExistingEntriesMapEntry = Map<string, InvoiceDraftEntry>;

// Finds all map entries by filtering on the keys,
// finding all starting with provided search string.
function findExistingEntriesMapEntriesByPartialKey(map: ExistingEntriesMapEntry, search: string) {
    const matchingEntries = [];

    for (const [key, value] of map.entries()) {
        if (key.startsWith(search)) {
            matchingEntries.push([key, value]);
        }
    }

    return matchingEntries;
}

export function updateOrInsertEntries(existing: InvoiceDraftEntry[], updateOrInsert: InvoiceDraftEntry[]): InvoiceDraftEntry[] {
    // Create a result array, initially a copy of the existing array
    const updatedArray: InvoiceDraftEntry[] = JSON.parse(JSON.stringify(existing));

    // Create a map of uniqueId to existing entries for quick lookup
    const existingMap: ExistingEntriesMapEntry = new Map(updatedArray.map((entry) => [entry.uniqueId, entry]));

    // Iterate through updateOrInsert entries
    for (const entry of updateOrInsert) {
        if (!existingMap.has(entry.uniqueId)) {
            // If the entry's uniqueId is found in existingMap,
            // append the new entry to the end of the updatedArray
            updatedArray.unshift({ ...entry });
            continue;
        }

        const existingEntry = existingMap.get(entry.uniqueId);

        if (!existingEntry) {
            continue;
        }

        const existingIndex = updatedArray.findIndex((item) => item.uniqueId === existingEntry.uniqueId);
        updatedArray[existingIndex] = { ...existingEntry, ...entry };
    }

    return updatedArray;
}
