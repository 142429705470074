import { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { InvoiceDraftForm } from '~/widgets/overview/invoices-widget/invoice-draft/invoice-draft';
import { CREDIT_DRAFT_FORM_KEY } from '~/constants/local.storage';
import useUser from '~/libs/use-user';

export const CREDIT_DRAFT_STORAGE_DEFAULT = {
    entries: [],
    requisition: '',
};

export function useCreditDraftStorage() {
    return useLocalStorage<InvoiceDraftForm>(CREDIT_DRAFT_FORM_KEY, CREDIT_DRAFT_STORAGE_DEFAULT);
}

/**
 * Responsible for cleaning up the stored state, when accounts are being switched.
 */
export function useCreditDraftStorageCleanup() {
    const [storedState, setStoredState] = useCreditDraftStorage();

    const { user } = useUser();

    useEffect(() => {
        if (!user?.profile?.user_id) {
            // We wait until information about the current users session is available
            return;
        }

        if (user?.profile?.user_id === storedState.userId) {
            // Current users id was identical to stored user id, so we do nothing.
            return;
        }

        // We reset the stored state.
        // We do this because the current users id did not match the stored when,
        // so we can assume that the user has switched accounts.
        setStoredState({
            userId: user?.profile?.user_id,
            entries: [],
            requisition: '',
        });
    }, [user?.profile?.user_id]);
}
