import { useMemo } from 'react';
import useTranslations from '~/shared/hooks/use-translations.hook';

function isSameDay(date: Date) {
    const now = new Date(Date.now());

    return now.getFullYear() === date.getFullYear() && now.getMonth() === date.getMonth() && now.getDate() === date.getDate();
}

/**
 * Gets the hours and minutes from a Date object
 */
function getShortTime(date: Date) {
    let hours: number | string = date.getHours();
    let minutes: number | string = date.getMinutes();

    // Pad hours and minutes with leading zeros if they are less than 10
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return `${hours}:${minutes}`;
}

export type UseStockDeliveryTimeProps = {
    // Will return undefined if `fallback: false` is provided, and the next delivery time is not the same day.
    fallback?: string | false;
    time?: string;
};

export function useStockDeliveryTime({ fallback, time }: UseStockDeliveryTimeProps) {
    const translate = useTranslations();

    return useMemo(() => {
        const nextDeliveryTime = time && new Date(time);

        if (nextDeliveryTime && isSameDay(nextDeliveryTime)) {
            return translate('stock.nextDeliveryTime', 'Kl. {{time}}').replace('{{time}}', getShortTime(nextDeliveryTime));
        }

        if (fallback === false) {
            return;
        }

        return fallback ?? translate('common.callUs', 'Kontakt FTZ');
    }, [fallback, time, translate]);
}
