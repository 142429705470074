import React, { useMemo } from 'react';
import Image from 'next/image';
import cx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import styles from './invoice-draft-line.module.scss';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import { Link } from '~/shared/link';
import Text from '~/shared/text/text.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Select from '~/shared/form-elements/select/select.component';
import { Svg } from '~/shared/svg';
import Button from '~/shared/buttons/button/button.component';
import ErrorBox from '~/shared/error-box/error-box';
import { InvoiceDraftEntryChangeUniqueIdHandler, InvoiceDraftForm } from '~/widgets/overview/invoices-widget/invoice-draft/invoice-draft';
import BrandLogo from '~/shared/brand-logo/brand-logo.component';
import { CdnImage } from '~/shared/image/cdn-image';
import { usePreviousOrdersByIdentifier } from '~/libs/queries/orders';
import { findActiveInvoiceByInvoiceId, getQuantityOptions } from '~/widgets/overview/invoices-widget/invoice-draft/utils';

export type InvoiceDraftLineOption = { invoiceIds: number[]; licensePlates: string[] };
export type InvoiceDraftLineOptions = {
    [key: string]: InvoiceDraftLineOption;
};

export type InvoiceDraftLineProps = {
    index: number;
    onRemove: (index: number) => void;
    options?: InvoiceDraftLineOption;
    onChangeUniqueId: InvoiceDraftEntryChangeUniqueIdHandler;
    onQuantityChange: (index: number, quantity: number) => void;
};

//86151510-0010
export function InvoiceDraftLine({ onRemove, index, options, onChangeUniqueId, onQuantityChange }: InvoiceDraftLineProps) {
    const translate = useTranslations();

    const { licensePlates = [], invoiceIds = [] } = options ?? {};
    const { watch, formState, control } = useFormContext<InvoiceDraftForm>();

    const itemNoField = watch(`entries.${index}.itemNo`);
    const invoiceIdField = watch(`entries.${index}.invoiceId`);
    const depositOnlyField = watch(`entries.${index}.depositOnly`);
    const hasDepositField = watch(`entries.${index}.hasDeposit`);
    const locationField = watch(`entries.${index}.location`);

    const errors = formState.errors.entries?.[index] ?? {};

    const { data: { variantLight: productVariant, invoicesList: { invoices = [] } = {} } = {}, isLoading } =
        usePreviousOrdersByIdentifier(itemNoField);

    const { brand, title, url, image } = productVariant ?? {};

    const activeInvoice = useMemo(() => {
        return findActiveInvoiceByInvoiceId(invoiceIdField, invoices);
    }, [invoiceIdField, invoices]);

    const quantityOptions = useMemo(() => {
        return getQuantityOptions(productVariant?.colliLocked ?? false, activeInvoice?.possibleToCreditQuantity, productVariant?.colli);
    }, [activeInvoice?.possibleToCreditQuantity, productVariant?.colli, productVariant?.colliLocked]);

    const isLicensePlateNullable = !invoices
        .filter((item) => invoiceIds.includes(item.invoiceId))
        .every((item) => item.carInfo?.licensePlate?.number);

    const handleChangeInvoice = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        const newActiveInvoice = findActiveInvoiceByInvoiceId(value, invoices);

        if (!newActiveInvoice) {
            console.error(`Could not change '${e.target.name}'`);
            return;
        }

        onChangeUniqueId(index, newActiveInvoice.invoiceId, {
            carId: newActiveInvoice.carInfo?.carId,
            licensePlate: newActiveInvoice.carInfo?.licensePlate?.number,
        });
    };

    const handleChangeLicensePlate = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { value: fieldValue, name } = e.target;
        const value = fieldValue !== '-' ? fieldValue : undefined;
        const licensePlate = value !== '-' ? value : undefined;

        const newActiveInvoice = invoices.find((item) => {
            if (licensePlate !== item.carInfo?.licensePlate?.number) {
                // Invoice did not match newly chosen registration number
                return;
            }

            if (!invoiceIds.includes(item.invoiceId)) {
                // Invoice id is not part of options for field,
                // and must therefor already be present in the form.
                return;
            }

            return item;
        });

        if (!newActiveInvoice) {
            console.error(`Could not change '${name}'`);
            return;
        }

        onChangeUniqueId(index, newActiveInvoice.invoiceId, {
            carId: newActiveInvoice.carInfo?.carId,
            licensePlate: newActiveInvoice.carInfo?.licensePlate?.number,
        });
    };

    if (isLoading) {
        return <p>Loading</p>;
    }

    return (
        <div
            className={cx({
                [styles.hasError]: Object.keys(errors).length > 0,
            })}
        >
            <fieldset className={styles.component}>
                {image?.url ? (
                    <CdnImage src={image.url} layout="fixed" objectFit="contain" width={75} height={75} />
                ) : (
                    <Image
                        src="/svg/placeholder-image.svg"
                        layout="fixed"
                        objectFit="contain"
                        width={75}
                        height={75}
                        alt={translate('common.missingImageAlt', 'Placeholder image')}
                    />
                )}

                <div>
                    <div className={styles.brandWrapper}>{brand ? <BrandLogo brand={brand} /> : null}</div>

                    <div className={styles.itemName}>
                        <Text title={itemNoField} textStyle="monoMedium">
                            {title}
                        </Text>
                    </div>
                </div>

                <div className={styles.itemNumber}>
                    <ValueWithCaption caption={translate('product.itemNo', 'Vare nr.')} captionClassName={styles.caption} />
                    <Link href={url}>
                        <Text title={itemNoField} textStyle="monoMedium" overflowEllipsis>
                            {itemNoField}
                        </Text>
                    </Link>
                </div>

                <div className={styles.selectWrapper}>
                    <Controller
                        control={control}
                        name={`entries.${index}.licensePlate`}
                        render={({ field: { onChange, ...field } }) => (
                            <Select
                                {...field}
                                label={translate('vehicle.licensePlate', 'Reg nr.')}
                                disabled={hasDepositField}
                                onChange={handleChangeLicensePlate}
                            >
                                {isLicensePlateNullable ? <option value={undefined}>-</option> : null}

                                {licensePlates.map((value) => (
                                    <option value={value} key={value}>
                                        {value}
                                    </option>
                                ))}
                            </Select>
                        )}
                    />
                </div>

                <div className={styles.selectWrapper}>
                    <Controller
                        control={control}
                        name={`entries.${index}.invoiceId`}
                        rules={{
                            required: { value: true, message: translate('overview.error.noInvoice', 'Ingen faktura fundet for dette produkt.') },
                        }}
                        render={({ field: { onChange, ...field } }) => (
                            <Select
                                {...field}
                                label={translate('overview.invoiceNumber', 'Faktura nr.')}
                                disabled={hasDepositField}
                                onChange={handleChangeInvoice}
                            >
                                {invoiceIds.map((value) => (
                                    <option value={value} key={value}>
                                        {value}
                                    </option>
                                ))}
                            </Select>
                        )}
                    />
                </div>

                <div className={styles.selectWrapper}>
                    <Controller
                        control={control}
                        name={`entries.${index}.quantity`}
                        rules={{
                            validate: (val) => val > 0,
                        }}
                        render={({ field: { onChange, ...field } }) => (
                            <Select
                                {...field}
                                disabled={hasDepositField}
                                label={translate('product.quantity', 'Antal')}
                                onChange={(e) => {
                                    onQuantityChange(index, parseInt(e.target.value));
                                    onChange(e);
                                }}
                                errorMessage={
                                    // TODO: implement react-hook-form validation.
                                    //  For some reason validation errors are not reflected in the render props.
                                    //  The work around is to listen to the actual value (not optimal)
                                    field.value === 0 ? translate('overviewDraft.entryQuantityError', 'Vælg antal') : undefined
                                }
                            >
                                <option value={0}>-</option>

                                {quantityOptions?.map((value) => (
                                    <option value={value} key={value}>
                                        {value}
                                    </option>
                                ))}
                            </Select>
                        )}
                    />
                </div>

                <div className={styles.textRight}>
                    <ValueWithCaption caption={translate('product.price', 'Pris.')} captionClassName={styles.srOnly} />
                    {activeInvoice?.unitPrice && !depositOnlyField ? (
                        <Text textStyle="body">
                            {activeInvoice.unitPrice.priceFormatted} / {translate('product.piece', 'stk')}
                        </Text>
                    ) : null}

                    {hasDepositField ? (
                        <Text textStyle="body">
                            {translate('overview.deposit', 'Depositum')} {activeInvoice?.unitPrice?.depositFormatted} /
                            {translate('product.piece', 'stk')}
                        </Text>
                    ) : null}
                </div>

                <div className={styles.textRight}>
                    <ValueWithCaption caption={translate('overview.remove', 'Fjerne produkter')} captionClassName={styles.srOnly} />
                    <Button
                        type="button"
                        icon={<Svg name="bin" />}
                        buttonStyle="secondary"
                        className={styles.deleteButton}
                        onClick={() => onRemove(index)}
                    />
                </div>
                <p className={styles.location}>
                    {translate('overviewDraft.creditLocation', 'Lokation: [location]').replace('[location]', locationField)}
                </p>
            </fieldset>

            {typeof errors.message !== 'undefined' ? (
                <ErrorBox className={styles.errorBox}>
                    {errors.message.length > 0
                        ? errors.message
                        : translate('overviewDraft.commonError', 'Der skete en fejl. Prøv igen, eller kontakt vores support.')}
                </ErrorBox>
            ) : null}
        </div>
    );
}
