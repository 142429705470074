import { useEffect } from 'react';
import { addErrorToast, useLayoutDispatch } from '~/context/layout.context';
import { GA4ModifyWishlist, GA4ViewListItems } from '~/libs/ga4';
import { useAddFavorite, useFavoriteList, useFavoriteStatusList, useRemoveFavorite } from '~/libs/queries/favorites';
import { useProductPrices } from '~/libs/queries/products/hooks/use-product-prices';
import { useStockStatus } from '~/libs/queries/products/hooks/use-stock-status';
import { useBreadcrumb } from '~/libs/queries/routing';
import { getListName } from '~/libs/queries/routing/hooks/use-breadcrumb';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import useUser from '~/libs/use-user';
import type { Products as ProductTypes } from '~/models/products';
import ErrorBox from '~/shared/error-box/error-box';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import NoResults from '~/shared/no-results/no-results.component';
import { FavoriteType } from '../W041Favorites';
import FavoriteProduct from './favorite-product/favorite-product.component';
import styles from './styled.module.scss';

interface IProps {
    favoriteType?: FavoriteType;
}

export default function FavoriteProductsList({ favoriteType }: IProps) {
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();

    const { profile } = useUser();
    const { activeProfile } = useActiveUser();

    const { data: { favoriteProducts = [] } = {}, isLoading, isError } = useFavoriteList({ userId: activeProfile?.id, type: favoriteType });
    const articleIds = favoriteProducts.map((fav) => fav.itemId);

    const { data: stockOverview } = useStockStatus(articleIds);
    const { data: statusList } = useFavoriteStatusList({
        itemNoList: articleIds,
        userId: activeProfile?.id,
        enabled: !!articleIds?.length,
        placement: 'favorites',
    });

    const { data: prices, isLoading: isLoadingPrices } = useProductPrices(
        articleIds.map((articleId) => ({ articleId, quantity: 1 })),
        !isLoading,
    );

    useEffect(() => {
        if (!isLoadingPrices && prices) {
            const variantsWithPrices = favoriteProducts.map((v) => {
                const price = prices[v.itemId as string]?.actualPrice;
                return {
                    ...v,
                    price,
                };
            });
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            GA4ViewListItems(variantsWithPrices, {
                currency: profile?.currency ?? '',
                listName: 'Mine favoritter',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, prices]);

    const handleError = () => {
        dispatch(addErrorToast(translate('common.somethingWentWrong', 'Der gik noget galt.')));
    };

    const { mutate: addFavorite } = useAddFavorite(
        {
            userId: activeProfile?.id,
            placement: 'favorites',
            itemNoList: articleIds,
        },
        {
            onError: handleError,
        },
    );

    const { mutate: removeFavorite } = useRemoveFavorite(
        {
            userId: activeProfile?.id,
            placement: 'favorites',
            itemNoList: articleIds,
        },
        {
            onError: handleError,
        },
    );

    const { data: breadcrumb } = useBreadcrumb();
    const handleClick = async (product: ProductTypes.IFavoriteProduct, status?: ProductTypes.FavoriteStatus, price: number = 0) => {
        const isMyFavorite = status === 1; // MyFavorite

        if (isMyFavorite) {
            removeFavorite(product.itemId);
        } else {
            addFavorite(product.itemId);
        }

        if (product) {
            GA4ModifyWishlist(product, {
                type: isMyFavorite ? 'remove' : 'add',
                currency: profile?.currency ?? '',
                price: price,
                listName: getListName(breadcrumb, product.title),
                content: favoriteProducts?.map((fav) => fav.itemId).join(),
            });
        }
    };

    if (isLoading) return <Loader padding="50px 0" />;

    if (isError) {
        return <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>;
    }

    return (
        <>
            {favoriteProducts.length ? (
                <ul className={styles.productsList}>
                    {favoriteProducts.map((product) => (
                        <FavoriteProduct
                            key={product.itemId}
                            favoriteType={favoriteType}
                            product={product}
                            stock={stockOverview?.[product.itemId]}
                            price={prices?.[product.itemId]}
                            status={statusList?.[product.itemId]}
                            onClick={handleClick}
                        />
                    ))}
                </ul>
            ) : (
                <NoResults />
            )}
        </>
    );
}
