import React, { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import styles from './create-credit.module.scss';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { useInvoiceDetails } from '~/libs/queries/users/hooks/use-invoice-details';
import createCredit from '~/services/orders/create-credit.service';
import createOECredit from '~/services/orders/create-oe-credit.service';
import Button from '~/shared/buttons/button/button.component';
import useUser from '~/libs/use-user';
import { Orders as OrderTypes } from '~/models/orders.d';
import { useStaticContent } from '~/libs/queries/bff';
import PageTitle from '../page-title/page-title.component';
import Heading from '../heading/heading.component';
import { InvoiceDetailsOld } from './invoice-details/invoice-details.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import Text from '~/shared/text/text.component';
import { DEPOSIT_ONLY_LOCATION, ItemOld } from './item/item.component';
import ErrorBox from '~/shared/error-box/error-box';
import Input from '../form-elements/input/input.component';
import TextArea from '../form-elements/text-area/text-area.component';
import { MAIL_FORMAT } from '~/constants/validation';
import Checkbox from '../form-elements/checkbox/checkbox.component';
import Label from '../form-elements/common/label/label.component';
import createCreditMultiple from '~/services/orders/create-credit-multiple.service';
import { SubmitFeedbackOld } from './submit-feedback/submit-feedback.component';
import { ICreditItem, ICreateCreditProps } from './create-credit.d';
import { GA4CreateCreditFromPdp } from '~/libs/ga4';
import { useCreditDraftStorage } from '~/widgets/overview/invoices-widget/invoice-draft/hooks';
import { InvoiceDraftEntry } from '~/widgets/overview/invoices-widget/invoice-draft/invoice-draft';
import { generateUniqueId, getUpdatedEntries } from '~/widgets/overview/invoices-widget/invoice-draft/utils';
import { getSelectedQuantity } from '~/page-elements/credit-modal/utils';
import { CreateCreditStatus } from '~/page-elements/credit-modal/types';
import { useFeatures, useFeatureToggle } from '~/libs/queries/bff';

interface IFormData {
    emailInCc: string;
    creditReasonDescription: string;
}

interface IProps extends ICreateCreditProps {
    onClose: (creditId?: number) => void;
    creditDraftDetails?: OrderTypes.ICreditDraftDetails;
    isOECredit?: boolean;
    showPdpButton?: boolean;
}

const CreateCredit: FC<IProps> = ({ items, invoiceDate, invoiceId, onClose, carInfo, creditDraftDetails, isOECredit, showPdpButton = false }) => {
    const { profile, user } = useUser();
    const { data: features } = useFeatures();
    const { shouldShowFeature } = useFeatureToggle();
    const showSaveButton = shouldShowFeature(features?.credits?.multipleCredits);
    const { data: staticContent } = useStaticContent();
    const [fetching, setFetching] = useState(false);
    const [status, setStatus] = useState<CreateCreditStatus>('initial');
    const [creditId, setCreditId] = useState<null | number>(null);
    const [storedForm, setStoredForm] = useCreditDraftStorage();
    const [stateItems, setStateItems] = useState<ICreditItem[]>([]);
    const [oeRequestCheck, setOeRequestCheck] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormData>();
    const router = useRouter();
    const translate = useTranslations();
    const { refetch } = useInvoiceDetails(invoiceId as number);
    const [requisition, setRequisition] = useState('');
    const [messages, setMessages] = useState<string[]>([]);

    const isExternalCredit = !!creditDraftDetails;

    useEffect(() => {
        setStateItems(
            items.map((item) => {
                const selectedQuantity = getSelectedQuantity(
                    // some items might have been returned
                    item?.possibleToCreditQuantity,
                    item?.colli,
                    item?.colliLocked,
                );

                return {
                    ...item,
                    selectedQuantity,
                    quantityWarn: selectedQuantity === 0,
                    depositOnly: false,
                };
            }),
        );
    }, [items]);

    const handleChangeDeposit = (itemId: string | number, depositOnly: boolean) => {
        const updatedStateItems = [...stateItems];
        const updatedItemIndex = updatedStateItems.findIndex((obj) => obj.itemId === itemId);

        updatedStateItems[updatedItemIndex] = {
            ...updatedStateItems[updatedItemIndex],
            depositOnly,
        };

        setStateItems(updatedStateItems);
    };

    const handleChangeQuantity = (itemId: string | number, quantity: number) => {
        const newItems = [...stateItems];
        const objIndex = newItems.findIndex((obj) => obj.itemId === itemId);
        newItems[objIndex].selectedQuantity = quantity;
        newItems[objIndex].quantityWarn = false;
        setStateItems(newItems);
    };

    const handleDeleteItem = (itemId: string | number) => {
        setStateItems((prev) => prev.filter((item) => item.itemId !== itemId));
    };

    const validateQuantity = () => {
        let isValid = true;

        const newItems = stateItems.map((item) => {
            if (item.selectedQuantity === 0) {
                isValid = false;
                return { ...item, quantityWarn: true };
            }

            return { ...item, quantityWarn: false };
        });

        setStateItems(newItems);

        return isValid;
    };

    const handleCreateOECredit = async (formData: IFormData) => {
        const postData: OrderTypes.ICreditOeInvoiceRequest = {
            emailInCc: formData.emailInCc,
            creditReasonDescription: formData.creditReasonDescription,
            requisition,
            items: stateItems.map((item) => ({
                itemId: item?.itemId,
                quantity: item?.selectedQuantity,
            })),
        };

        try {
            const response = await createOECredit(invoiceId, postData, user);
            if (response.errorMessage) {
                setMessages([response.errorMessage]);
                setStatus('incomplete');
            } else {
                setStatus('created');
            }
        } catch (error) {
            setStatus('error');
        } finally {
            await refetch();
            setFetching(false);
        }
    };

    const handleCreateCredit = async () => {
        const postData: OrderTypes.ICreditInvoiceRequest = {
            requisition,
            items: stateItems.map((item) => ({
                quantity: item?.selectedQuantity,
                itemNo: item?.itemId,
                hasDeposit: item?.hasDeposit,
                depositOnly: item?.depositOnly,
                licensePlate: carInfo?.licensePlate?.number,
                carId: carInfo?.carId,
                invoiceId: Number(item?.invoiceId || invoiceId),
            })),
        };

        try {
            const response = isExternalCredit ? await createCreditMultiple(postData, user) : await createCredit(invoiceId, postData, user);

            setMessages(response.messages as string[]);
            if (isExternalCredit && response?.status === OrderTypes.CreateCreditStatus.Created) {
                onClose(response?.creditId);
            }

            if (response?.statusName === OrderTypes.CreateCreditStatus.Created) {
                setStatus('created');
                setCreditId(response?.creditId as number);
            } else if (response?.statusName === OrderTypes.CreateCreditStatus.NotCreated) {
                setStatus('initial');
            } else {
                setStatus('error');
            }
        } catch (error) {
            setStatus('error');
        } finally {
            !isExternalCredit && (await refetch());
            setFetching(false);
        }
    };

    const handleSubmitForm = (formData: IFormData) => {
        // disable submit credit for regular credits with the new requirements
        if (!isExternalCredit && !isOECredit) return;

        const isQuantityValid = validateQuantity();

        if (!isQuantityValid) return;

        setStatus('initial');
        setFetching(true);

        if (isOECredit) {
            handleCreateOECredit(formData);
        } else {
            handleCreateCredit();
        }
    };

    const handleSaveDraft = () => {
        const isQuantityValid = validateQuantity();
        if (!isQuantityValid) return;

        setStoredForm((prev) => {
            const creditItems: InvoiceDraftEntry[] = [];
            const possibleToCreditMap: Record<string, number> = {};

            for (const stateItem of stateItems) {
                const entry = {
                    itemNo: stateItem.itemId,
                    licensePlate: stateItem.carInfo?.licensePlate?.number,
                    carId: stateItem.carInfo?.carId,
                    uniqueId: generateUniqueId(
                        stateItem.itemId as string,
                        stateItem.invoiceId as number,
                        stateItem.depositOnly ? 'deposit' : 'total',
                        stateItem.carInfo?.carId,
                    ),
                    location: stateItem.depositOnly ? DEPOSIT_ONLY_LOCATION : stateItem.creditLocation ?? '-',
                    quantity: stateItem.selectedQuantity,
                    invoiceId,
                    hasDeposit: stateItem.hasDeposit,
                    depositOnly: stateItem.depositOnly,
                };

                possibleToCreditMap[entry.uniqueId] = stateItem.possibleToCreditQuantity;

                creditItems.push(entry as InvoiceDraftEntry);
            }

            let update: InvoiceDraftEntry[] = [...prev.entries];

            for (const entry of creditItems) {
                update = getUpdatedEntries(update, entry, possibleToCreditMap[entry.uniqueId]);
            }

            return {
                ...prev,
                entries: update,
            };
        });

        GA4CreateCreditFromPdp(stateItems.map((item) => item.ftzCode).filter((item): item is string => typeof item === 'string'));

        onClose();
    };

    const handleSaveDraftWithRedirect = () => {
        handleSaveDraft();

        if (!staticContent?.urlMappings?.invoices) {
            console.error('Could not redirect. Url mapping for invoices are missing.');
            return;
        }

        router.push(staticContent?.urlMappings.invoices);
    };

    if (status === 'created' || status === 'incomplete') {
        return (
            <SubmitFeedbackOld
                messages={messages}
                status={status}
                setStatus={setStatus}
                isOECredit={isOECredit}
                creditId={creditId as number}
                onClose={onClose}
                creditDraftDetails={creditDraftDetails}
                isExternalCredit={isExternalCredit}
            />
        );
    }

    const isDisabled = stateItems.some((item) => item.quantityWarn);

    return (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <PageTitle className={styles.pageTitle}>
                <Heading tagName="h4">{translate('overview.orderDetails', 'Odredetaljer')}</Heading>
            </PageTitle>

            <InvoiceDetailsOld
                fetching={fetching}
                itemsCount={stateItems?.reduce((acc, cur) => acc + cur.quantity, 0)}
                invoiceId={invoiceId}
                invoiceDate={String(invoiceDate)}
                showRequisition={!!(isExternalCredit || isOECredit)}
                requisition={requisition}
                setRequisition={setRequisition}
            />

            <section>
                <PageTitle>
                    <Heading tagName="h4">{translate('overview.itemDetails', 'Varedetaljer')}</Heading>
                </PageTitle>
                {isOECredit && carInfo?.manufacturer && (
                    <ValueWithCaption caption={translate('vehicle.manufacturerName', 'Mærke')}>
                        <Text>{carInfo?.manufacturer}</Text>
                    </ValueWithCaption>
                )}
                {stateItems?.map((item, i) => (
                    <ItemOld
                        fetching={fetching}
                        key={`${item.itemId}-${i}`}
                        item={item}
                        onDeleteItem={handleDeleteItem}
                        onChangeQuantity={handleChangeQuantity}
                        onChangeDeposit={handleChangeDeposit}
                        added={
                            !!storedForm.entries.find(
                                (item) =>
                                    item.uniqueId ===
                                    generateUniqueId(item.itemNo, Number(invoiceId), item.depositOnly ? 'deposit' : 'total', item.carId),
                            )
                        }
                    />
                ))}
            </section>

            {isOECredit && (
                <section>
                    <PageTitle>
                        <Heading tagName="h4">{translate('createCredit.additionalInformation', 'Yderligere Information')}</Heading>
                    </PageTitle>
                    <Label required>{translate('createCredit.replySentTo', 'Svar sendes til')}</Label>
                    <Input
                        type="email"
                        wrapperClassName={styles.input}
                        defaultValue={profile?.email}
                        errorMessage={errors?.emailInCc && errors.emailInCc.message}
                        {...register('emailInCc', {
                            required: translate('common.fieldMustBeFilled', 'Feltet skal udfyldes'),
                            pattern: {
                                value: MAIL_FORMAT,
                                message: translate('common.invalidEmail', 'Den indtastede email er ikke gyldig'),
                            },
                        })}
                    />

                    <Label required>{translate('createCredit.addReasonForDesiredCredit', 'Tilføj årsag for ønsket kreditering')}</Label>
                    <TextArea
                        errorMessage={errors?.creditReasonDescription && errors.creditReasonDescription.message}
                        wrapperClassName={styles.input}
                        {...register('creditReasonDescription', {
                            required: translate('common.fieldMustBeFilled', 'Feltet skal udfyldes'),
                        })}
                    />

                    <Checkbox checked={oeRequestCheck} onChange={(e) => setOeRequestCheck(e.target.checked)}>
                        {translate(
                            'createCredit.oeCreditCheckBox',
                            'Når der klikkes på ”Forespørg OE kreditering” afsendes forespørgslen nu til FTZ OE for tjek om det er muligt, at returnere. Kontroller at e-mail adresse er korrekt, det er denne som vil være afsender og modtage svaret på forespørgslen.',
                        )}
                    </Checkbox>
                </section>
            )}

            {status === 'error' && <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>}

            <div className={styles.buttons}>
                {showPdpButton && !isOECredit && !isExternalCredit && (
                    <Button disabled={isDisabled || fetching || !stateItems.length} buttonStyle="secondaryLight" onClick={handleSaveDraft}>
                        {translate('overview.addCreditAndStay', 'Tilføj (bliv på produkt)')}
                    </Button>
                )}

                {showSaveButton && !isOECredit && !isExternalCredit && (
                    <Button disabled={isDisabled || fetching || !stateItems.length} buttonStyle="secondary" onClick={handleSaveDraftWithRedirect}>
                        {translate('overview.addCredit', 'Tilføj til kreditering')}
                    </Button>
                )}
                {(isExternalCredit || isOECredit) && (
                    <Button
                        type="submit"
                        className={styles.submitButton}
                        fetching={fetching}
                        disabled={isDisabled || fetching || !stateItems.length || (isOECredit && !oeRequestCheck)}
                    >
                        {isOECredit
                            ? translate('createCredit.requestOECreditSubmitButton', 'Forespørg OE kreditering')
                            : translate('createCredit.submitButton', 'Indsend kreditering')}
                    </Button>
                )}
            </div>
            {creditDraftDetails?.cancelRedirectUrl && (
                <Button buttonStyle="clean" buttonSize="link" onClick={() => onClose()}>
                    {translate('createCredit.cancelRedirectLink', 'Annuller kreditering og gå tilbage til [clientDisplayName]').replace(
                        '[clientDisplayName]',
                        creditDraftDetails?.clientDisplayName,
                    )}
                </Button>
            )}
        </form>
    );
};

export default CreateCredit;
