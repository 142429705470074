import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { LayoutActionTypes, useLayoutDispatch } from '~/context/layout.context';
import { useStaticContent } from '~/libs/queries/bff';
import { isBrowser } from '~/helpers';
import { GA4BeginCheckout } from '~/libs/ga4';
import { queryKeys } from '~/libs/queries/query-keys';
import useUser from '~/libs/use-user';
import { Basket as BasketTypes } from '~/models/basket.d';
import { Bff } from '~/models/bff';
import { getTrackingProducts } from '~/services/basket/basket-tracking.service';
import Loader from '~/shared/loader/loader.component';
import { BasketEmptyContainer } from '~/page-elements/basket/basket-empty/basket-empty.container';
import { BasketContentContainer } from '~/page-elements/basket/basket-content/basket-content.container';
import { useCheckoutBasket } from '~/libs/queries/basket';

//Old component: basket.component.tsx
export default function W05Basket(props: Bff.IModuleViewModel) {
    const queryClient = useQueryClient();
    const router = useRouter();
    const { data: { loggedInPageUrl } = {} } = useStaticContent();
    const dispatch = useLayoutDispatch();
    const { profile, isLoggedIn, isLoadingUser, user } = useUser();
    const { data: basket, isLoading: isBasketLoading, isStale: isBasketStale } = useCheckoutBasket();

    const isEmptyBasket = !basket || basket.totalItemsCount === 0;

    useEffect(() => {
        if (isBasketStale) {
            // The begin_checkout event shouldn't be fired if the basket query is stale.
            // Instead it should wait for an updated basket query.
            return;
        }

        if (!basket || !profile) {
            return;
        }

        const staticContent = queryClient.getQueryData<Bff.IStaticContentV2>(queryKeys.bff.staticContent(router.locale, user));
        const currency = profile?.currency;

        const sortedGroups = (basket.groups || []).sort((a, b) => {
            return +(a.basketCarInfo === null) - +(b.basketCarInfo === null);
        });
        const trackingProducts = getTrackingProducts(sortedGroups, currency, staticContent);

        if (trackingProducts.length === 0) {
            return;
        }

        GA4BeginCheckout(trackingProducts, {
            basketId: basket.id,
            currency: currency,
            value: basket.total?.customerPrice || 0,
        });
    }, [basket?.id, profile, isBasketStale]);

    useEffect(() => {
        dispatch({
            type: LayoutActionTypes.SetIsCheckoutPage,
            payload: true,
        });
        return () =>
            dispatch({
                type: LayoutActionTypes.SetIsCheckoutPage,
                payload: false,
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isLoggedIn && !isLoadingUser && isBrowser) {
        router.push(loggedInPageUrl || '/');
        return null;
    }

    if (isBasketLoading) {
        return <Loader padding="60px" />;
    }

    return isEmptyBasket ? <BasketEmptyContainer /> : <BasketContentContainer />;
}
