import { Products as ProductTypes } from '~/models/products.d';
import BrandLogo from '~/shared/brand-logo/brand-logo.component';
import { IColliPrice } from '~/shared/colli-prices/colli-prices.component';
import FtzPricesTooltip from '~/shared/ftz-prices-tooltip/ftz-prices-tooltip.component';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Price from '~/shared/price/price.component';
import { StockStatusWithStockQuery } from '~/shared/stock-status/stock-status.component';
import Text from '~/shared/text/text.component';
import styles from './description.module.scss';
import { IPricesList } from '~/libs/queries/products/hooks/use-product-prices';
import Image from 'next/image';
import React from 'react';
import Tooltip, { TooltipContentWrapper } from '~/shared/tooltip/tooltip.component';
import useUser from '~/libs/use-user';

interface IPDPDescription {
    itemNo?: string;
    colli: number;
    colliLocked?: boolean;
    unit: string;
    name: string;
    brand?: ProductTypes.IBrand;
    title: string;
    prices?: IPricesList;
    isLoadingPrices: boolean;
    subGroup: string;
    variant?: ProductTypes.IVariant;
    children?: React.ReactNode[] | React.ReactNode | null;
}

export const Description = ({
    brand,
    title,
    unit,
    colli,
    colliLocked,
    itemNo,
    prices,
    isLoadingPrices,
    subGroup,
    children,
    variant,
}: IPDPDescription) => {
    const { isInternalUser } = useUser();
    const translate = useTranslations();

    const price = prices && itemNo ? prices[itemNo] : undefined;

    return (
        <div className={styles.root}>
            <div className={styles.brandCell}>
                <BrandLogo brand={brand as ProductTypes.IBrand} height={20} width={64} />
            </div>
            <Heading tagName="h1" displayStyle="h4" className={styles.titleCell}>
                {title}
            </Heading>
            <Price weight="semiBold" size="large" fetching={isLoadingPrices} className={styles.priceCell}>
                {price?.actualPrice?.displayPrice || 'N/A'}
            </Price>
            {price?.actualPrice?.displayDeposit ? (
                <Price color="grey" className={styles.depositCell}>
                    {translate('product.deposit', 'Depositum')} {price?.actualPrice?.displayDeposit}
                </Price>
            ) : null}
            {price?.actualPrice?.displayFee ? (
                <Price color="grey" className={price?.actualPrice?.displayDeposit ? styles.feeCell : styles.depositCell}>
                    {translate('product.fee', 'Afgift')} {price?.actualPrice?.displayFee}
                </Price>
            ) : null}
            <div className={styles.productInfoWrapper}>
                <div className="caption">{translate('product.itemNo', 'Vare nr.')}</div>
                <div className="caption">{translate('product.ftzNo', 'FTZ nummer.')}</div>
                <div className="caption">{translate('product.stockStatus', 'Lagerstatus')}</div>
                <Text title={itemNo} textStyle="monoMedium" overflowEllipsis>
                    {itemNo}
                </Text>
                <FtzPricesTooltip unit={unit} isLoading={isLoadingPrices} prices={price as IColliPrice} colli={colli} colliLocked={colliLocked} />
                <StockStatusWithStockQuery itemNo={itemNo} group={isInternalUser ? subGroup : undefined} />
            </div>
            {children ? (
                <div className={styles.basketWrapper}>
                    {variant?.svhc?.isSvhc ? (
                        <Tooltip
                            className={styles.svhcTooltip}
                            content={
                                <TooltipContentWrapper>
                                    {translate('product.svhc', 'Produkt indeholder farlige stoffer. Kontakt FTZ for yderligere information')}
                                </TooltipContentWrapper>
                            }
                        >
                            <Image
                                src="/svg/svhc.svg"
                                alt={translate('product.svhc', 'Produkt indeholder farlige stoffer. Kontakt FTZ for yderligere information')}
                                layout="fill"
                                width={30}
                                height={25}
                            />
                        </Tooltip>
                    ) : null}
                    {children}
                </div>
            ) : null}
        </div>
    );
};
