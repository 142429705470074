import React from 'react';

import useTranslations from '~/shared/hooks/use-translations.hook';
import Price from '~/shared/price/price.component';

import styles from './credit-entry-price.module.scss';

export type CreditEntryPriceProps = {
    depositOnly: boolean;
    hasDeposit: boolean;
    price?: string;
    deposit?: string;
};

export function CreditEntryPrice({ price, deposit, depositOnly, hasDeposit }: CreditEntryPriceProps) {
    const translate = useTranslations();

    return (
        <div className={styles.creditEntryPrice}>
            {!depositOnly && (
                <Price textAlign="right">
                    {price} / {translate('product.piece', 'stk')}
                </Price>
            )}
            {hasDeposit && (
                <Price className={styles.creditEntryDepositPrice} textAlign="right" size="medium">
                    {translate('overview.deposit', 'Depositum')} {deposit} / {translate('product.piece', 'stk')}
                </Price>
            )}
        </div>
    );
}
