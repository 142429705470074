import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import styles from './invoice-draft.module.scss';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Button from '~/shared/buttons/button/button.component';
import Input from '~/shared/form-elements/input/input.component';
import TextComponent from '~/shared/text/text.component';
import { InvoiceDraftLine } from './invoice-draft-line/invoice-draft-line.component';
import { InvoiceDraftEntry, InvoiceDraftEntryChangeUniqueIdHandler, InvoiceDraftForm } from './invoice-draft';
import Skeleton from '~/shared/skeleton';
import { Orders as OrderTypes } from '~/models/orders.d';
import { addErrorToast, addSuccessToast, useLayoutDispatch } from '~/context/layout.context';
import { GA4CreateCreditFinalize, GA4CreateCreditQuickScan } from '~/libs/ga4';
import { useBatchCreateCredit, useBatchCreateCreditValidationQuery, usePreviousOrdersByIdentifier } from '~/libs/queries/orders';
import { useCreditDraftStorage, useInvoiceDraftLineOptions } from '~/widgets/overview/invoices-widget/invoice-draft/hooks';
import { findEntryByUniqueId, generateUniqueId, generateUniqueIdFromInvoice } from '~/widgets/overview/invoices-widget/invoice-draft/utils';
import { CreditModal } from '~/page-elements/credit-modal/credit-modal';
import { Modal } from '~/features/primitives/modal';
import { useCreditModalState } from '~/page-elements/credit-modal/hooks';
import { Bff } from '~/models/bff';
import { CREDIT_DRAFT_STORAGE_DEFAULT } from '~/widgets/overview/invoices-widget/invoice-draft/hooks/use-credit-draft-storage/use-credit-draft-storage';
import Tooltip, { TooltipContentWrapper } from '~/shared/tooltip/tooltip.component';
import Label from '~/shared/form-elements/common/label/label.component';

export function InvoiceDraftCreditModal() {
    const translate = useTranslations();
    const [variantId, resetModalState] = useCreditModalState((state) => [state.variantId, state.reset]);

    const handleOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        resetModalState();
    }, []);

    return (
        <Modal position="right" onOpenChange={handleOpenChange} open={!!variantId} title={translate('overview.createCredit', 'Opret kreditering')}>
            {variantId ? <CreditModal variantId={variantId} /> : null}
        </Modal>
    );
}

export type InvoiceDraftProps = {
    onComplete: () => void;
};

export function InvoiceDraft({ onComplete }: InvoiceDraftProps) {
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();
    const setVariantId = useCreditModalState((state) => state.setVariantId);

    const [searchTerm, setSearchTerm] = useState('');

    const [storedForm, setStoredForm] = useCreditDraftStorage();

    const form = useForm<InvoiceDraftForm>({
        mode: 'onBlur',
        values: {
            ...storedForm,
        },
    });

    const { clearErrors, formState, register, watch, setError, getValues, handleSubmit, control } = form;

    const quickScanRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        focusOnQuickScan();
    }, []);

    const { errors } = formState;
    const { fields, remove, update } = useFieldArray({ control, name: 'entries' });

    const entries = watch('entries');

    useBatchCreateCreditValidationQuery({
        items: entries,
        options: {
            keepPreviousData: true,
            onSuccess: (data) => {
                const { failedCreditLinesByInvoiceAndVehicle } = data;

                // Clear all errors for entries before checking dry run response.
                // We do not want to clear errors for requisition and scan field
                clearErrors(
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    entries.map((item, i) => `entries.${i}`),
                );

                if (!failedCreditLinesByInvoiceAndVehicle) {
                    // No errors were identified during the dry run, so we do nothing else
                    return;
                }

                for (const [invoiceId, invoiceDetails] of Object.entries(failedCreditLinesByInvoiceAndVehicle)) {
                    for (const carDetails of Object.values(invoiceDetails)) {
                        for (const [itemNo, error] of Object.entries(carDetails)) {
                            const index = entries.findIndex((item) => item.invoiceId === Number(invoiceId) && item.itemNo === itemNo);

                            if (index === -1) {
                                continue;
                            }

                            setError(
                                `entries.${index}`,
                                {
                                    message: error,
                                },
                                {
                                    shouldFocus: false,
                                },
                            );
                        }
                    }
                }
            },
        },
    });

    const options = useInvoiceDraftLineOptions(entries);

    const { mutate: batchCreateCredit } = useBatchCreateCredit({
        onSuccess: (res, req) => {
            if (req.isDryRun) {
                return;
            }

            if (res.status !== OrderTypes.CreateCreditStatus.Created && res.messages) {
                const combinedErrorMessage = res.messages.filter(Boolean).join(', ');

                dispatch(
                    addErrorToast(
                        combinedErrorMessage.length > 0 ? combinedErrorMessage : translate('common.somethingWentWrong', 'Der gik noget galt.'),
                    ),
                );

                return;
            }

            const { items = [] } = req;

            dispatch(addSuccessToast(translate('overview.creditFeedback.successHeadline', 'Din kreditering blev oprettet')));

            GA4CreateCreditFinalize({
                requisition: req.requisition || '',
                entries: items.map((entry) => ({
                    invoiceId: Number(entry.invoiceId),
                    itemNo: entry.itemNo || '',
                    quantity: entry?.quantity ? entry.quantity : 0,
                    licensePlate: entry.licensePlate,
                })),
            });

            setStoredForm(CREDIT_DRAFT_STORAGE_DEFAULT);
            onComplete();
        },
        onError: () => {
            dispatch(addErrorToast(translate('common.somethingWentWrong', 'Der gik noget galt.')));
        },
    });

    const { refetch: refetchPreviousOrdersByIdentifier, isFetching: isFetchingPreviousOrdersByIdentifier } = usePreviousOrdersByIdentifier(
        searchTerm,
        {
            enabled: false,
        },
    );

    const doProductScan = (identifier: string) => {
        // Determines if the credit modal should be opened (OE or deposit)
        function isSpecialInvoice(invoice: Bff.IVariantInvoiceDetails) {
            return invoice.orderType === 'OE' || invoice.hasDeposit;
        }

        // Finds an invoice that either a. hasn't been added yet
        // or b. has not reached max-creditable quantity yet.
        function findSuitableInvoice(invoices: Bff.IVariantInvoiceDetails[], variant: Bff.IVariantLight) {
            let suitableInvoice;
            let uniqueId;

            for (const currentInvoice of invoices) {
                const currentUniqueId = generateUniqueIdFromInvoice(variant.ftzCode ?? '', currentInvoice, 'total');
                const currentEntry = findEntryByUniqueId(
                    entries,
                    currentUniqueId,
                    // We exclude the deposit type from the unique id search.
                    // It is not possible to use it as a criteria for finding invoices,
                    // since it is determined by user input.
                    'exclude_deposit_type',
                );

                if (currentInvoice.possibleToCreditQuantity === 0) {
                    // We want to skip invoices where all items already
                    // has been credited.
                    continue;
                }

                const isColliRespected = variant && currentInvoice.possibleToCreditQuantity % variant.colli === 0;

                if (variant?.colliLocked && !isColliRespected) {
                    // We want to skip invoices were the product is locked by colli, and the colli has been broken.
                    // This can happen if the invoice has been partially credited by an external system.
                    continue;
                }

                if (currentEntry && currentInvoice.possibleToCreditQuantity > currentEntry.quantity) {
                    // The invoice is already in the draft,
                    // but has not reached max credit quantity.
                    // Therefor we want to update the draft entry.
                    suitableInvoice = currentInvoice;
                    uniqueId = currentUniqueId;
                    break;
                }

                if (!currentEntry) {
                    // The invoice was not found in the draft entries,
                    // so it is safe to insert it.
                    suitableInvoice = currentInvoice;
                    uniqueId = currentUniqueId;
                    break;
                }
            }

            return { invoice: suitableInvoice, uniqueId };
        }

        function createNewEntry(invoice: Bff.IVariantInvoiceDetails, variant: Bff.IVariantLight): InvoiceDraftEntry {
            return {
                itemNo: variant.ftzCode ?? '',
                invoiceId: invoice.invoiceId,
                licensePlate: invoice.carInfo?.licensePlate?.number || undefined,
                carId: invoice.carInfo?.carId || undefined,
                uniqueId: generateUniqueIdFromInvoice(variant.ftzCode ?? '#', invoice, 'total'),
                quantity: variant?.colliLocked ? variant?.colli : 1,
                location: invoice.creditLocation ?? '-',

                // This function should never be reached for deposit credits
                hasDeposit: false,
                depositOnly: false,
            };
        }

        function addNewEntry(invoice: Bff.IVariantInvoiceDetails, variant: Bff.IVariantLight) {
            const newEntry = createNewEntry(invoice, variant);

            setStoredForm((prev) => ({
                ...prev,
                entries: [newEntry, ...prev.entries],
            }));
        }

        function updateExistingEntry(index: number, invoice: Bff.IVariantInvoiceDetails, variant: Bff.IVariantLight) {
            const newEntry = createNewEntry(invoice, variant);

            setStoredForm((prev) => {
                const updatedEntries = [...prev.entries];

                if (!variant.colliLocked) {
                    // Since colli isn't locked, it is safe to increase the quantity by one.
                    newEntry.quantity = entries[index].quantity + 1;
                } else {
                    newEntry.quantity = entries[index].quantity + variant.colli;
                }

                updatedEntries[index] = newEntry;

                return { ...prev, entries: updatedEntries };
            });
        }

        async function getPreviousInvoices() {
            // We check if there are any invoices stored in the cache,
            // matching the debouncedQuickScanValue (among others cache keys).
            // If there is a cache miss, it will fetch the invoices from the backend.
            return (await refetchPreviousOrdersByIdentifier())?.data;
        }

        if (!identifier.length) {
            return;
        }

        async function search() {
            const data = await getPreviousInvoices();
            const { invoicesList = {}, variantLight } = data ?? {};
            const { invoices = [] } = invoicesList;

            if (
                // We do not want to show an error message when we search with a product id that doesn't match anything.
                // We can determine this by asserting that there are no invoices
                // and no variant light included in the response.
                invoices.length === 0 &&
                !variantLight
            ) {
                return;
            }

            if (invoices.length === 0) {
                // There were no invoices for submitted variant.
                setError(
                    'entries',
                    { message: translate('overviewDraft.noInvoiceForVariant', 'Ingen fakturaer for produkt.') },
                    {
                        shouldFocus: false,
                    },
                );

                setSearchTerm('');

                return;
            }

            if (!variantLight) {
                return;
            }

            const { invoice, uniqueId } = findSuitableInvoice(invoices, variantLight);

            if (!invoice) {
                // No invoices met criteria for being added to the draft.
                setError(
                    'entries',
                    {
                        message: translate(
                            'overviewDraft.allInvoicesAdded',
                            'Alle fakturaer for produkt er tilføjet til kladden. Fakturaer hvor colli er brudt vil ikke fremgå i formularen.',
                        ),
                    },
                    {
                        shouldFocus: false,
                    },
                );

                focusOnQuickScan();

                return;
            }

            if (isSpecialInvoice(invoice)) {
                // We set the variant id to trigger the credit modal.
                // The value is passed to the CreditModal component,
                // and used for retrieving the related invoices either from the cache
                // or the backend
                setVariantId(identifier);

                // Special invoices are handled individually,
                // and is not added to the draft.
                return;
            }

            const index = entries.findIndex((item) => item.uniqueId === uniqueId);

            if (!variantLight) {
                console.error('Response did not include a variant. Cannot update credit draft form.');
                return;
            }

            if (index === -1) {
                addNewEntry(invoice, variantLight);
            } else {
                updateExistingEntry(index, invoice, variantLight);
            }

            focusOnQuickScan();
        }

        if (!identifier.length) {
            return;
        }

        GA4CreateCreditQuickScan(identifier);

        search();
    };

    const focusOnQuickScan = () => {
        setSearchTerm('');
        quickScanRef.current?.focus();
    };

    const doSubmit = (data: InvoiceDraftForm) => {
        batchCreateCredit({
            requisition: data.requisition,
            items: data.entries,
        });

        GA4CreateCreditFinalize({
            requisition: data.requisition || '',
            entries: data.entries.map((entry) => ({
                invoiceId: entry.invoiceId,
                itemNo: entry.itemNo || '',
                quantity: entry?.quantity ? entry.quantity : 0,
                licensePlate: entry.licensePlate,
            })),
        });
    };

    const handleRemoveLine = useCallback(async (index: number) => {
        remove(index);

        setStoredForm((prev) => {
            const updated = [...prev.entries];
            updated.splice(index, 1);

            return {
                ...prev,
                entries: updated,
            };
        });

        setTimeout(focusOnQuickScan, 200);
    }, []);

    const handleChangeUniqueId: InvoiceDraftEntryChangeUniqueIdHandler = (
        index: number,
        invoiceId: number,
        { carId, licensePlate }: { carId?: string; licensePlate?: string },
    ) => {
        const currentEntries = getValues(`entries`);
        const currentEntry = currentEntries[index];

        const updatedEntry = {
            ...currentEntry,
            invoiceId,
            carId,
            licensePlate,
            quantity: 0,
            uniqueId: generateUniqueId(currentEntry.itemNo, invoiceId, currentEntry.depositOnly ? 'deposit' : 'total', carId),
        };

        update(index, updatedEntry);
        setStoredForm((prev) => {
            const updatedEntries = [...prev.entries];
            updatedEntries[index] = updatedEntry;
            return { ...prev, entries: updatedEntries };
        });
    };

    const handleQuantityChange = (index: number, quantity: number) => {
        const currentEntry = getValues(`entries.${index}`);

        const updatedEntry = {
            ...currentEntry,
            quantity,
        };

        update(index, updatedEntry);
        setStoredForm((prev) => {
            const updatedEntries = [...prev.entries];
            updatedEntries[index] = updatedEntry;
            return { ...prev, entries: updatedEntries };
        });
    };

    const handleQuickScanKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== 'Enter') {
            return;
        }

        e.preventDefault();
        doProductScan(searchTerm);
    };

    const handleQuickScanChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.trim().toUpperCase();

        setSearchTerm(value);
    };

    return (
        <>
            <InvoiceDraftCreditModal />
            <FormProvider {...form}>
                <form className={styles.component} onSubmit={handleSubmit(doSubmit)}>
                    <div className={styles.titleWrapper}>
                        <Heading tagName="h2" displayStyle="h2">
                            {translate('overviewDraft.title', 'Kreditering (Påbegyndt)')}
                        </Heading>

                        <Button type="submit" buttonStyle="primary" disabled={isFetchingPreviousOrdersByIdentifier}>
                            {translate('overviewDraft.approveButton', 'Godkend kreditering')}
                        </Button>
                    </div>

                    <div className={styles.formWrapper}>
                        <div className={styles.quickScanFieldWrapper}>
                            <Label className={styles.quickScanFieldLabel}>{translate('overviewDraft.quickScan', 'Quick Scan')}</Label>
                            <Input
                                ref={quickScanRef}
                                iconType="quick-scan"
                                value={searchTerm}
                                errorMessage={errors.entries?.message}
                                onChange={handleQuickScanChange}
                                onKeyDown={handleQuickScanKeyDown}
                            />
                            <Tooltip
                                content={
                                    <TooltipContentWrapper>
                                        <>{translate('overviewDraft.quickScanDisclaimer', 'Hovedgruppe skal inkluderes')}</>
                                    </TooltipContentWrapper>
                                }
                            />
                        </div>
                        <Input {...register('requisition')} label={translate('overviewDraft.requisition', 'Rekvision nr.')} />
                    </div>

                    {searchTerm.length > 0 && isFetchingPreviousOrdersByIdentifier && (
                        <div className={styles.productList}>
                            <Skeleton
                                style={{
                                    marginLeft: '15px',
                                    width: 'calc(100% - 30px)',
                                    height: '75px',
                                    marginBottom: '15px',
                                }}
                            ></Skeleton>
                        </div>
                    )}

                    {fields?.length > 0 ? (
                        <div className={styles.productList}>
                            {fields.map((field, index) => (
                                <InvoiceDraftLine
                                    key={field.id}
                                    onRemove={handleRemoveLine}
                                    index={index}
                                    options={entries?.[index]?.uniqueId ? options?.[entries[index].uniqueId] : undefined}
                                    onChangeUniqueId={handleChangeUniqueId}
                                    onQuantityChange={handleQuantityChange}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className={styles.textBlock}>
                            <TextComponent textStyle="bodySmall">
                                {translate(
                                    'overview.explanation.line1',
                                    'Start din krediteringsproces ved at scanne direkte in i “Quick Scan” feltet.',
                                )}
                            </TextComponent>
                            <TextComponent textStyle="bodySmall">
                                {translate(
                                    'overview.explanation.line2',
                                    'Du kan stadig tilføje produkter til din kreditering, ved at gå ind på produktsiden.',
                                )}
                            </TextComponent>
                        </div>
                    )}
                </form>
            </FormProvider>
        </>
    );
}
