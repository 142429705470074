import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useLayoutState } from '~/context/layout.context';
import { queryKeys } from '~/libs/queries/query-keys';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import useUser from '~/libs/use-user';
import { Bff } from '~/models/bff';
import { Products } from '~/models/products.d';
import { apiClient } from '~/services/api-client';
import { ICreateUrlParamsQuery, LocaleOptions, useEndpoints } from '~/services/service-endpoint';

export function useSparePartCategoryByVehicle(
    carModelTypeId: string | undefined,
    vehicleData: Bff.ISparePartCategoryListWidgetViewModel,
    categoryId?: number,
) {
    const { locale } = useRouter();
    const { isLoggedIn, user } = useUser();
    const { activeProfile } = useActiveUser();
    const { expertMode } = useLayoutState();
    const { products, createUrl } = useEndpoints();

    let endpoint = `${products.sparePartCategory}/cars/${carModelTypeId}/categories`;
    const query: ICreateUrlParamsQuery = {
        carModelTypeId: undefined,
        licensePlate: undefined,
        licensePlateCountry: undefined,
        vin: undefined,
        includeExpertProducts: undefined,
    };

    if (vehicleData) {
        const { carId, licensePlate, vin, pictogramId, pictogramGroupAlias, pictogramGroupType, catalogueType } = vehicleData;

        if (carId) {
            query.carModelTypeId = carId;
        }

        if (licensePlate) {
            const { number, country } = licensePlate;

            query.licensePlate = number;
            query.licensePlateCountry = country;
        }

        if (vin) {
            query.vin = vin;
        }

        if (pictogramId) {
            query.pictogramId = pictogramId;
        }

        if (pictogramGroupAlias) {
            query.pictogramGroupAlias = pictogramGroupAlias;
        }

        if (catalogueType) {
            // The categories should be found in the truck and trailer catalogue
            query.catalogType = catalogueType;
        }

        // 0 is a valid value, so check for not null instead
        if (pictogramGroupType !== null) {
            query.pictogramGroupType = pictogramGroupType;
        }
    }

    if (expertMode) {
        query.includeExpertProducts = expertMode;
    }

    if (categoryId) {
        endpoint += `/${categoryId}`;
    }

    const url = createUrl({
        localeOption: LocaleOptions.path,
        endpoint,
        query,
    });

    return useQuery<Products.ICategory | Products.ICategory[], Error>({
        queryKey: queryKeys.vehicle.sparePartCategoryByVehicle(locale, activeProfile?.id, url),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(url, { signal });
        },
        enabled: isLoggedIn && !!activeProfile,
        refetchOnWindowFocus: false,
    });
}
