import clsx from 'clsx';
import { Orders as OrderTypes } from '~/models/orders';
import Button from '~/shared/buttons/button/button.component';
import Checkbox from '~/shared/form-elements/checkbox/checkbox.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Link } from '~/shared/link';
import Price from '~/shared/price/price.component';
import ProductImageTitleBrand from '~/shared/product-image-title-brand/product-image-title-brand.component';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import styles from './styled.module.scss';

interface IItem extends OrderTypes.IInvoiceItem, OrderTypes.ICreditItem {}

type IItemProps = {
    item: IItem;
    openRequest: (itemId: string) => void;
    addToSelected: (itemId: string, add?: boolean) => void;
    isAddedForRequest: boolean;
    searchQuery: string;
};

export default function Item({ item, openRequest, addToSelected, isAddedForRequest, searchQuery }: IItemProps) {
    const translate = useTranslations();
    const itemIsReturned = item.possibleToCreditQuantity === 0 && item.quantity !== 0;
    return (
        <div
            className={clsx(styles.item, {
                [styles.selectedItem]: isAddedForRequest,
            })}
        >
            <div className={styles.checkboxCell}>
                <Checkbox
                    className={styles.checkbox}
                    checked={isAddedForRequest}
                    onChange={() => item.itemId && addToSelected(item.itemId, !isAddedForRequest)}
                    disabled={itemIsReturned}
                />
            </div>
            <div className={styles.imageTitleCell}>
                <ProductImageTitleBrand highlightText={searchQuery} image={item?.image} name={item?.title} brand={item?.brand} />
            </div>
            <div className={styles.quantityCell}>
                <ValueWithCaption caption={translate('product.quantity', 'antal')} captionClassName={styles.label}>
                    <Text textStyle="monoSmall">
                        {item?.quantity} {translate('product.piece', 'stk')}
                    </Text>
                </ValueWithCaption>
            </div>
            <div className={styles.itemIdCell}>
                <ValueWithCaption caption={translate('product.itemNo', 'Vare nr')} captionClassName={styles.label}>
                    <Link href={item?.url} className={styles.itemLink}>
                        <Text overflowEllipsis title={item?.itemId} nowrap textStyle="monoSmall" text={item?.itemId} highlightText={searchQuery} />
                        {item?.depositItemId && (
                            <Text overflowEllipsis title={item?.depositItemId} nowrap textStyle="monoSmall">
                                {item?.depositItemId}
                            </Text>
                        )}
                    </Link>
                </ValueWithCaption>
            </div>
            <div className={styles.priceCell}>
                <Price>{item?.price?.netPriceFormatted}</Price>
                {item?.hasDeposit && (
                    <Price size="small">
                        {translate('overview.deposit', 'Depositum')} {item?.price?.depositFormatted}
                    </Price>
                )}
                {!!item?.price?.fee && (
                    <Price size="small" color="lightGrey">
                        {translate('overview.fee', 'Afgifter')} {item?.price?.feeFormatted}
                    </Price>
                )}
            </div>

            <div className={styles.buttonsCell}>
                {itemIsReturned ? (
                    <Text secondary textAlign="center">
                        {translate('overview.credited', 'Krediteret')}
                    </Text>
                ) : (
                    <>
                        <Button
                            buttonSize="small"
                            buttonStyle="clean"
                            icon={<Svg name="return-alt" />}
                            onClick={() => openRequest(item.itemId as string)}
                        >
                            {translate('overview.return', 'Returnér')}
                        </Button>
                        {item?.rmaUrl && (
                            <Button
                                buttonSize="small"
                                buttonStyle="clean"
                                icon={<Svg name="receipt" />}
                                target="_blank"
                                disabled={!item?.rmaUrl}
                                {...(item?.rmaUrl ? { href: `/api/${item?.rmaUrl}` } : null)}
                            >
                                {translate('overview.complain', 'Reklamér')}
                            </Button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
