import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { addErrorToast, useLayoutDispatch } from '~/context/layout.context';
import useUser from '~/libs/use-user';
import changePassword from '~/services/user/changePassword';
import useTranslations from '~/shared/hooks/use-translations.hook';

export default function useChangePassword() {
    const { locale } = useRouter();
    const { user } = useUser();

    return useMutation({
        mutationFn: (body: Parameters<typeof changePassword>['0']['body']) =>
            changePassword({
                user,
                locale,
                body,
            }),
    });
}
