import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { EasingFramer, TimingFramer } from '~/constants/animation';
import { Products } from '~/models/products';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import RelatedProductsList from '~/features/RelatedProductsList';
import Text from '~/shared/text/text.component';
import styles from './show-related-products.module.scss';

interface IProps {
    relatedVariantIds?: number[] | undefined;
    relatedVariantsExtraInfo?: Products.IRelatedVariant[];
    loadingItems?: number;
    showRelatedProducts?: boolean;
    setShowRelatedProducts?: (value: React.SetStateAction<boolean>) => void;
}

export const ShowRelatedProducts = ({
    relatedVariantIds,
    loadingItems,
    showRelatedProducts,
    setShowRelatedProducts,
    relatedVariantsExtraInfo,
}: IProps) => {
    const translate = useTranslations();

    const variantProps = {
        initial: { opacity: 0, height: 0, overflow: 'hidden' },
        animate: { opacity: 1, height: 'auto', overflow: 'hidden' },
        exit: {
            opacity: 0,
            height: 0,
            overflow: 'hidden',
        },
        transition: {
            duration: TimingFramer.SLOW,
            ease: EasingFramer.BOUNCE_IN,
        },
    };

    return (
        <AnimatePresence exitBeforeEnter initial={false}>
            {showRelatedProducts && (
                <motion.div {...variantProps} className={styles.modalWrapper}>
                    <div className={styles.wrapper}>
                        <Text>{translate('product.rememberAccesories', 'Har du husket tilbehøret?')}</Text>
                        <div>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            <ButtonCircle iconName="close" buttonStyle="dark" onClick={() => setShowRelatedProducts(false)} />
                        </div>
                    </div>
                    <RelatedProductsList
                        relatedVariantIds={relatedVariantIds}
                        loadingItems={loadingItems}
                        relatedVariantsExtraInfo={relatedVariantsExtraInfo}
                    />
                </motion.div>
            )}
        </AnimatePresence>
    );
};
