import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CreditModalFormFields } from '~/page-elements/credit-modal/types';
import { getSelectedQuantity } from '~/page-elements/credit-modal/utils';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Radio from '~/shared/form-elements/radio/radio.component';

import styles from './create-entry-deposit-type-picker.module.scss';

export type CreditEntryDepositTypePickerProps = {
    depositOnly: boolean;
    disabled: boolean;
};

export function CreditEntryDepositTypePicker({ depositOnly, disabled }: CreditEntryDepositTypePickerProps) {
    const translate = useTranslations();
    const { setValue, control, getValues } = useFormContext<CreditModalFormFields>();

    const handleChangeDeposit = (depositOnly: boolean) => {
        const [entry] = getValues('entries');

        if (!entry) {
            return;
        }

        setValue('entries.0', {
            ...entry,
            depositOnly: depositOnly,
        });
    };

    return (
        <section className={styles.creditEntryDepositTypePicker}>
            <Controller
                name="entries.0.depositOnly"
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                    <>
                        <div className={styles.creditEntryDepositTypePickerRadio}>
                            <Radio
                                {...field}
                                checked={!value}
                                onChange={() => {
                                    handleChangeDeposit(false);
                                    onChange(false);
                                }}
                                disabled={disabled}
                            >
                                {translate('overview.unusedItemsInclDeposit', 'Ubrugte varer (inkl. depositum)')}
                            </Radio>
                        </div>
                        <div className={styles.creditEntryDepositTypePickerRadio}>
                            <Radio
                                {...field}
                                checked={value}
                                onChange={() => {
                                    handleChangeDeposit(true);
                                    onChange(true);
                                }}
                                disabled={disabled}
                            >
                                {translate('createCredit.returnOnlyDeposit', 'Returner kun depositum')}
                            </Radio>
                        </div>
                    </>
                )}
            />
        </section>
    );
}
