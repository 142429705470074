import { useCheckoutBasket, useIsUpdatingBasket } from '~/libs/queries/basket';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { useBasketStates } from '../../../use-basket-states';
import { BasketSubmit } from './basket-submit';

export function BasketSubmitContainer() {
    const translate = useTranslations();
    const { data: basket } = useCheckoutBasket();
    const { activeProfile } = useActiveUser();

    const { isLoading } = useBasketStates();

    const isUpdatingBasket = useIsUpdatingBasket();
    const purchaseDisallowed = activeProfile?.cantBuyProducts;
    const basketIsEmpty = !basket?.totalItemsCount;

    const thirdPartyHasPostCheckoutRedirect = Boolean(
        basket?.thirdPartyDetails?.clientDisplayName && basket?.thirdPartyDetails.hasPostCheckoutRedirect,
    );
    const label = thirdPartyHasPostCheckoutRedirect
        ? translate('basket.confirmPurchaseAndGoBack', 'Bekræft køb og gå tilbage til [client]').replace(
              '[client]',
              basket!.thirdPartyDetails!.clientDisplayName, // basket.thirdPartyDetails can not be undefined due to if statement
          )
        : translate('basket.confirmPurchase', 'Bekræft køb');

    return <BasketSubmit label={label} isDisabled={Boolean(purchaseDisallowed || basketIsEmpty || isUpdatingBasket)} isLoading={isLoading} />;
}
