import React, { ChangeEventHandler, FC, useCallback, useEffect, useMemo, useState } from 'react';
import Header from './header/header.component';
import Section from './section/section.component';
import { Orders as OrderTypes } from '~/models/orders.d';
import styles from './styled.module.scss';
import { useInvoiceDetails } from '~/libs/queries/users/hooks/use-invoice-details';

import OverviewErrorBox from '~/page-elements/overview/common/overview-error-box/overview-error-box.component';
import OverviewLoader from '~/page-elements/overview/common/overview-loader/overview-loader.component';
import Checkbox from '~/shared/form-elements/checkbox/checkbox.component';
import Button from '~/shared/buttons/button/button.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg';
import { Modal } from '~/features/primitives/modal';
import OEBadge from '~/shared/oe-badge/oe-badge.component';
import CreateCredit from '~/shared/create-credit/create-credit.component';
import Heading from '~/shared/heading/heading.component';
import Text from '~/shared/text/text.component';
import { SPA_URL } from '~/constants/api';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';

type IProps = {
    invoiceId: number | string;
    isOpen: boolean;
    invoice?: OrderTypes.IInvoiceViewModel;
    searchQuery?: string;
    rootTop?: boolean;
    backBtnUrl?: string;
};

export default function InvoiceDetails({ invoiceId, invoice, isOpen, searchQuery, rootTop, backBtnUrl }: IProps) {
    const translate = useTranslations();

    const [requestItems, setRequestItems] = useState<OrderTypes.IInvoiceItem[]>([]);
    const [selectedItems, setSelectedItems] = useState<OrderTypes.IInvoiceItem[]>([]);
    const [isCreditRequestOpen, setCreditRequestOpen] = useState(false);

    const { data: invoiceDetails, isError: isError, isLoading: isLoading } = useInvoiceDetails(Number(invoiceId), isOpen);
    const sections = isOpen ? invoiceDetails?.items : invoice?.items;

    const items = useMemo(() => {
        if (isOpen) {
            return invoiceDetails?.items?.flatMap(
                (section) =>
                    section?.itemDetails?.map((item) => ({
                        ...item,
                        carInfo: section?.carInfo,
                    })),
            );
        }

        return invoice?.items?.flatMap(
            (section) =>
                section?.itemDetails?.map((item) => ({
                    ...item,
                    invoiceId: invoice?.number,
                    carInfo: section?.carInfo,
                })),
        );
    }, [invoice?.items, invoice?.number, invoiceDetails?.items, isOpen]);

    const handleSelectAllCheck: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (e.target.checked) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            setSelectedItems([...items]);
        } else {
            setSelectedItems([]);
        }
    };

    const handleOpenSingleRequest = (itemNo: string) => {
        const selected = items?.find((item) => item?.itemId === itemNo);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        setRequestItems([selected]);
        setCreditRequestOpen(true);
    };

    const handleSelected = (itemNo: string, add = true) => {
        const newItems = add
            ? [...selectedItems, items?.find((item) => item?.itemId === itemNo)]
            : selectedItems.filter((item) => item.itemId !== itemNo);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        setSelectedItems(newItems);
    };

    const someItemsAlreadyCreated = items?.some((item) => item?.possibleToCreditQuantity === 0);

    const handleAfterSubmit = () => {
        setCreditRequestOpen(false);
        setRequestItems([]);
    };

    useEffect(() => {
        setSelectedItems([]);
    }, [isOpen]);

    const orderType = invoice?.orderType || invoiceDetails?.orderType;
    const pdfUrl = invoice?.pdfUrl || invoiceDetails?.pdfUrl;

    const handleOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setCreditRequestOpen(false);
        setRequestItems([]);
    }, []);

    if (isOpen && isError) return <OverviewErrorBox />;
    if (isOpen && isLoading) return <OverviewLoader />;

    return (
        <>
            <Modal
                position="right"
                open={isCreditRequestOpen}
                onOpenChange={handleOpenChange}
                title={
                    <span className={styles.modalTitle}>
                        <OEBadge orderType={invoiceDetails?.orderType as OrderTypes.OrderType} />
                        {translate('overview.createCredit', 'Opret kreditering')}
                    </span>
                }
            >
                <CreateCredit
                    isOECredit={orderType === OrderTypes.OrderType.OE}
                    onClose={handleAfterSubmit}
                    items={requestItems}
                    invoiceDate={invoice?.displayDate || (invoiceDetails?.displayDate as string)}
                    invoiceId={invoiceId}
                />
            </Modal>
            <div className={rootTop ? styles.rootTop : undefined}>
                <div className={styles.headerWrapper}>
                    {backBtnUrl && <ButtonCircle buttonSize="small" iconName="chevron-left" href={backBtnUrl} />}
                    <OEBadge orderType={orderType as OrderTypes.OrderType} />
                    <Heading tagName="h4" className={styles.heading}>
                        {translate('overview.invoiceNumber', 'Faktura nr.')}
                        <Text textStyle="bodyLarge" text={invoiceId?.toString()} highlightText={searchQuery} />
                    </Heading>
                    {pdfUrl && (
                        <Button
                            buttonSize="link"
                            href={`${SPA_URL}/api/${pdfUrl}`}
                            target="_blank"
                            download
                            icon={<Svg name="paper-stack" />}
                            buttonStyle="clean"
                        >
                            {translate('common.openPDF', 'Åben PDF')}
                        </Button>
                    )}
                </div>
                <div>
                    <div className={styles.details}>
                        <div className={styles.controls}>
                            <Checkbox
                                className={styles.checkbox}
                                checked={items?.length === selectedItems.length}
                                onChange={handleSelectAllCheck}
                                disabled={someItemsAlreadyCreated}
                            >
                                {translate('common.selectAll', 'Vælg alle')}
                            </Checkbox>
                            <Button
                                buttonSize="small"
                                disabled={selectedItems.length < 1}
                                buttonStyle="clean"
                                icon={<Svg name="return-alt" />}
                                onClick={() => {
                                    setRequestItems(selectedItems);
                                    setCreditRequestOpen(true);
                                }}
                            >
                                {translate('overview.returnSelected', 'Returnér valgte')} ({selectedItems.length})
                            </Button>
                        </div>
                        <Header
                            searchQuery={searchQuery}
                            requisition={invoice?.requisition || invoiceDetails?.requisition}
                            date={invoice?.displayDate || invoiceDetails?.displayDate}
                            numberOfProducts={invoice?.quantity || invoiceDetails?.numberOfProducts}
                            total={invoice?.total || invoiceDetails?.total}
                            caseId={invoice?.caseId}
                        />
                    </div>

                    {sections?.map((section, i) => (
                        <Section
                            searchQuery={searchQuery as string}
                            key={i}
                            section={section}
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            handleOpenSingleRequest={handleOpenSingleRequest}
                            handleSelected={handleSelected}
                        />
                    ))}
                </div>
            </div>
        </>
    );
}
