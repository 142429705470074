import { Session } from 'next-auth';
import { Products } from '~/models/products';
import { apiClient } from '~/services/api-client';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';

type Args = {
    locale: string | undefined;
    user: Session | null;
    body: Products.IVariantInfoTextRequest;
    signal?: AbortSignal;
};

export default function getProductsInfosVariant({ locale, user, signal, body }: Args): Promise<Products.IProductArticleWithFtzCode[]> | undefined {
    if (!body.ftzCodes?.length) return;

    const url = createUrl(
        {
            endpoint: endpoints.products.getVariantInfo,
            localeOption: LocaleOptions.path,
        },
        locale,
    );
    return apiClient.auth(user).postJSON(
        url,
        {
            ...body,
        },
        {
            signal,
        },
    );
}
